import React from 'react';
import './main.scss';
import Card from '../../components/card/card';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import PubSub from 'pubsub-js';
import Status from '../status/status';
import Apps from '../../components/apps/apps';
import Categories from 'components/categories/categories';
import appConfig from 'utils/get_azure_app_config';
import EmbeddedContents from '../../components/embeddedContents/embeddedContents';
import { Container } from '@mui/material';

/* Main Function */
type mainPageProps = {
    userProjectList: any[]
}

function Main(props: mainPageProps) {
    interface GroupDetails {
        wins: [];
        actionitems: [];
        milestones: [];
        decisions: [];
        documents: [];
        issues: [];
        risks: [];
        embeddedContent: [];
    }

    const [isClosed, setClosed] = React.useState(false);
    const [groupDetails, setGroupDetails] = React.useState({} as GroupDetails);
    const [projectDetails, setProjectDetails] = React.useState({} as GroupDetails);
    const [isEmbeddedContentEnabled, setIsEmbeddedContentEnabled] = React.useState(false);

    React.useEffect(() => {
        main();
        var token = PubSub.subscribe('updateSchedule', main);
        return () => { PubSub.unsubscribe(token); };
    }, [props.userProjectList]
    );

    function main() {
        if (localStorage.getItem('selectedTypeId')) {
            if (localStorage.getItem('selectedType') == 'project') {

                var getProjectDetailsRequestBody: any = {
                    "ProjectId":localStorage.getItem('selectedTypeId'),
                    "GroupId":"",
                    "ItemName":"embeddedContent",
                    "IsHeaderRequired":true
                }
                apiCalls.create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
                    .then((res: any) => {
                        var data: any = res[0];
                        appConfig.useFeatureFlagsByEmailOrGroups('pinnplat-ProjectEmbeddedContent', localStorage.getItem("UserEmail") + '').then((flag) => {
                            setIsEmbeddedContentEnabled(flag)
                            const isClosed = data.projectSummary.ProjectStatus == "Closed" ? true : false;
                            setClosed(isClosed);
                            setProjectDetails(data);
                        });
                    });
            }
            else if (localStorage.getItem('selectedType') == 'group') {
                var grpDetails = {} as GroupDetails;
                var getGroupDetailsRequestBody: any = {
                    "ProjectId": "",
                    "GroupId":localStorage.getItem('selectedTypeId'),
                    "ItemName":"embeddedContent",
                    "IsHeaderRequired":true
                }
                apiCalls.create(Config.api.endPoint + 'GetProjectItemsList' , getGroupDetailsRequestBody)
                    .then(async res => {
                        var group: any = await res;
                        var embeddedContent = Array();

                        appConfig.useFeatureFlagsByEmailOrGroups('pinnplat-ProjectEmbeddedContent', localStorage.getItem("UserEmail") + '').then((flag) => {
                            setIsEmbeddedContentEnabled(flag)
                            group.forEach((data: any) => {

                                if (data.embeddedContent && data.embeddedContent.length > 0) {
                                    data.embeddedContent.forEach((content: any) => {
                                        content['projectSiteName'] = data.projectSiteName;
                                        content['projectId'] = data.id;
                                    });
                                    embeddedContent.push(...data.embeddedContent);
                                }
                            });

                            grpDetails.embeddedContent = embeddedContent as [];
                            setGroupDetails(grpDetails);
                        });
                    }).catch((err) => {
                        console.log(err)
                    });
            }
        }
    }

    return (
        <div className="main">
        <div data-cy="main-row" className="row">
            <Container maxWidth={false}  sx={{ px: { xs: 0, sm: 2 } }}>
            <Categories />

            {localStorage.getItem('selectedType') == 'project' && isEmbeddedContentEnabled && (
                <EmbeddedContents details={projectDetails} />
            )}
            {localStorage.getItem('selectedType') == 'group' && isEmbeddedContentEnabled && (
                <EmbeddedContents details={groupDetails} />
            )}
            </Container>
        </div>
        </div>
    );
}
export default Main;