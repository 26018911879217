import { useRef, useState } from 'react';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  ButtonBase,
  Stack
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { Config } from '../../../../config/config';
import apiCalls from '../../../../utils/api_calls';
import { ZendeskAPI } from 'react-zendesk';
import Avatar from 'components/@extended/Avatar';
// assets
import { QuestionCircleOutlined, QuestionOutlined, MessageOutlined } from '@ant-design/icons';
import { SUPPORT_URL } from 'utils/utils';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none'
};

const Support = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  let [projects, setProjects] = React.useState([
    {
      ID: '',
      NAME: 'None',
      ACTIVE: true
    }
  ]);
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const title = projects.find((t: any) => t.ACTIVE)?.NAME;

  React.useEffect(() => {
    main();

    var token = PubSub.subscribe('setSupportEmails', main);
    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  function main() {
    // Call Get user projects API to fetch user projects Details
    apiCalls.getAll(Config.api.endPoint + 'Users').then((res: any) => {
      var data: any = res;
      var userProjects: any[] = [];
      if (data.projects != null && data.projects.length > 0) {
        data.projects.forEach((project: any) => {
          var prj = {
            ID: project.id,
            NAME: project.name,
            ACTIVE: data.currentProject === project.id ? true : false,
            TYPE: 'project'
          };
          userProjects.push(prj);
        });
        data.Groups.forEach((group: any) => {
          var grp = {
            ID: group.groupId,
            NAME: group.groupName,
            ACTIVE: data.currentProject === group.groupId ? true : false,
            TYPE: 'group'
          };
          userProjects.push(grp);
        });
        setProjects(userProjects);
      }
    });
  }

  function openZendesk() {
    var zenDeskSubject = 'Platform support request';
    zenDeskSubject = title != 'None' ? zenDeskSubject + ' - ' + title : zenDeskSubject;
    localStorage.setItem('zenDeskSubject', zenDeskSubject);
    var userAgent = navigator.userAgent;
    var url = window.location.href;
    var systemInfo = `Page: \r${url} \r \rBrowser Info: \r${userAgent}`;
    var zESettings = {
      webWidget: {
        contactForm: {
          subject: true,
          fields: [
            // System Info field
            { id: 'name', prefill: { '*': localStorage.getItem('UserName') } },
            { id: 'email', prefill: { '*': localStorage.getItem('UserEmail') } },
            { id: 'subject', prefill: { '*': localStorage.getItem('zenDeskSubject') } },
            { id: 360040574232, prefill: { '*': systemInfo } },
            { id: 'description', prefill: { '*': `Message: \r\rDateTime: \r${new Date()}` } }
          ]
        }
      }
    };
    ZendeskAPI('webWidget', 'updateSettings', zESettings);
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');
  }

  const startWalkmeMenu = () => {
    if (window && window?.WalkMePlayerAPI) {
      window.WalkMePlayerAPI.toggleMenu();
    }
    handleToggle();
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'grey.200' : 'grey.500' },
          '&:focus-visible': {
            outline: `3px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        title="Help"
        aria-label="open support"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <QuestionCircleOutlined style={{ fontSize: '22px' }} />
        </Stack>
      </ButtonBase>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard title="Support" elevation={0} border={false} content={false}>
                  <List
                    component="nav"
                    sx={{
                      marginBottom: 2,
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    <Divider />
                    <ListItemButton onClick={openZendesk}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'error.main',
                            bgcolor: 'error.lighter'
                          }}
                        >
                          <MessageOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<Typography variant="h6">Report an Issue / Give feedback</Typography>} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => window.open(SUPPORT_URL, '_blank')}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'error.main',
                            bgcolor: 'error.lighter'
                          }}
                        >
                          <QuestionOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<Typography variant="h6">Get Help / Release Notes</Typography>} />
                    </ListItemButton>
                    <>
                      <Divider />
                      <ListItemButton onClick={() => startWalkmeMenu()}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'error.main',
                              bgcolor: 'error.lighter'
                            }}
                          >
                            <QuestionOutlined />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<Typography variant="h6">Tips</Typography>} />
                      </ListItemButton>
                    </>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Support;
