import { Config } from '../config/config';

const useFeatureFlagsForProjects = (flagKey = '', projectName = '') => {
  var flag = false;
  return new Promise<boolean>((resovle, reject) => {
      if (!flagKey || !flagKey.toString().trim().length) {
        resovle(flag);
      } else {
        try {
              if(projectName){
                fetch(Config.api.endPoint + `FeatureFlagsForProjects?projectName=${projectName}&flagName=${flagKey}`, {
                  "method": "GET",
                  "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("UserAccessToken")
                  }
                })
                  .then(response => response.json())
                  .then(response => {
                    resovle(response.ResponseData == 'True' ? true : false);
                  })
                  .catch(err => {
                    resovle(false);
                  });
              }
              else{
                resovle(false);
              }
        }
        catch (error) {
          resovle(false);
        }
      }
  });
};

const useFeatureFlagsByEmailOrGroups = (flagKey = '', emailOrGroup = '') => {
  var flag = false;
  return new Promise<boolean>((resovle, reject) => {
      if (!flagKey || !flagKey.toString().trim().length) {
        resovle(flag);
      } else {
        try {
              if(emailOrGroup){
                fetch(Config.api.endPoint + `FeatureFlagsByEmailOrGroups?emailOrGroup=${emailOrGroup}&flagName=${flagKey}`, {
                  "method": "GET",
                  "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("UserAccessToken")
                  }
                })
                  .then(response => response.json())
                  .then(response => {
                    resovle(response.ResponseData == 'True' ? true : false);
                  })
                  .catch(err => {
                    resovle(false);
                  });
              }
              else{
                resovle(false);
              }
        }
        catch (error) {
          resovle(false);
        }
      }
  });
};

export default { useFeatureFlagsForProjects, useFeatureFlagsByEmailOrGroups };