import { HubConnection } from "@microsoft/signalr";
import { GridRowId, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid-pro";

export enum IssuesItemTypes {
    SET_LIST_RESPONSE = 'SET_LIST_RESPONSE',
    SET_ISSUES_LIST_ITEMS = 'SET_ISSUES_LIST_ITEMS',
    SET_SOCKET_CONNECTION = 'SET_SOCKET_CONNECTION',
    SET_ISSUE_ITEM_TOAST = 'SET_ISSUE_ITEM_TOAST',
    SET_ISSUE_ITEM_LOADING_STATUS = 'SET_ISSUE_ITEM_LOADING_STATUS',
    SET_ISSUE_ITEM_FORM_MODE = 'SET_ISSUE_ITEM_FORM_MODE',
    SET_SELECTED_GRID_ROW_IDS = 'SET_SELECTED_GRID_ROW_IDS',
    SET_SHOW_ADD_FORM = 'SET_SHOW_ADD_FORM',
    SET_REFRESH_API_COUNTER = 'SET_REFRESH_API_COUNTER',
    SET_SHOW_UPDATE_FORM = 'SET_SHOW_UPDATE_FORM',
    SET_SHOW_DELETE_FORM = 'SET_SHOW_DELETE_FORM',
    SET_UPDATE_ISSUE_ITEM = 'SET_UPDATE_ISSUE_ITEM',
    SET_DELETE_ISSUE_ITEM = 'SET_DELETE_ISSUE_ITEM',
    SET_SELECTED_USER = 'SET_SELECTED_USER',
    SET_TOAST_INFO = 'SET_TOAST_INFO',
    UPDATE_ISSUE_ITEM = 'UPDATE_ISSUE_ITEM',
    SET_FEATURE_FLAG = 'SET_FEATURE_FLAG',
    SET_PROJECT_CHANGE = 'SET_PROJECT_CHANGE',
}

export const enum FormMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    ADD = 'ADD',
}

export const enum ToastKind {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

export interface IssueItemDataType {
    _id: string;
    username: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    primaryPhone: string;
    role: string;
    groupId: string;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface IssueItemContextStateType {
    isLoading: boolean;
    connection: HubConnection | null;
    issues: IssueItemDataType[];
    selectedIds: GridRowId[];
    projectGroups: any[];
    showAdd: boolean;
    showUpdate: boolean;
    showDelete: boolean;
    showToast: boolean;
    toastMsg: string;
    toastKind: ToastKind;
    formMode: FormMode;
    projectSummary: any;
    projectDetails: any;
    refreshApiCounter: number;
    actionitem: any;
    selectedUser: any,
    deleteActionItem: any;
    isFeatureEnabled: boolean;
    projectChanged: number;
}

export type IssueItemActionType = {
    type: IssuesItemTypes;
    payload: any;
};

export type IssueItemContextType = {
    state: IssueItemContextStateType;
    dispatch: (params: IssueItemActionType) => void;
};

export interface EditToolbarProps {
    setWins: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

export interface Item {
    Id: string;
    Name: string;
    EmailAddress: string;
    Type: string
}

export interface ToastInfo {
    toastKind: ToastKind,
    toastMsg: String,
    showToast: Boolean,
}