import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './categories.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import BackDrop from 'components/BackDrop'; 

function Categories() {
  const [applications, setApplications] = React.useState([] as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();
  const { user } = useAuth0();

  React.useEffect(() => {
    getApplications();
  }, []);

  function getApplications() {
    setIsLoading(true);
    apiCalls
      .getAll(Config.api.endPoint + 'GetComponents')
      .then((res) => {
        var data: any = res;
        let restrictedData = data.map((d: any)=>{
          let childRestrictedData = d['children'].filter((child: any)=>{
            if(!child?.showInDashboard){
               return false;
            }
            if(child?.isRestricted){
              return localStorage.getItem('isPinnacleEmployee')==="true";
            }
            return true;
          });
          d['children'] = childRestrictedData;
          return d;
        })
        setApplications(restrictedData);
        setIsLoading(false);
      })
      .catch((_e) => {
        setIsLoading(false);
      });
  }

  const redirectToPage = (url: any, openInNewTab: boolean, category: string) => {
    if (url) {
      if (openInNewTab) {
        apiCalls.create(
          Config.api.endPoint + 'ActivityLog',
          JSON.parse(
            JSON.stringify({
              email: user?.email,
              source: 'Platform',
              route: category,
              destination: url
            })
          )
        );
      }
      window.open(url, openInNewTab ? '_blank' : '_self');
    }
  };

  return (
    <>
      <Container maxWidth={false} className=" p-0 category-container">
        <BackDrop isLoading={isLoading} invisible={true} thickness={8} />
        {applications?.map((x: any, index1: any) => {
          return (
            <Container maxWidth={false} className="category-item" key={`${x.name}-${index1}`} disableGutters>
              <div hidden={!x.showInDashboard}>
                <div className="categoryHeading">
                  <img
                    alt="edit"
                    src={require(`../../assets/images/` + x.displayName + `ComponentGroup.png`).default}
                    style={{ width: '30px', height: 'auto', borderRadius: 8 }}
                  />{' '}
                  {x.name}
                </div>
                <div className="row mb-3">
                  {x.children.map((ap: any, index2: any) => {
                    return (
                      <div className="categoryCard col-lg-3" key={`${ap.name}-${index2}`} hidden={!ap.showInDashboard}>
                        <Card
                          sx={{
                            maxWidth: '100%',
                            borderRadius: 5,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A200,
                            boxShadow: 'none'
                          }}
                          key={ap.id}
                        >
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <strong>
                                {ap.name} {ap.trademark ? <>&trade;</> : ''}
                              </strong>
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '3',
                                WebkitBoxOrient: 'vertical',
                                minHeight: 60
                              }}
                            >
                              {ap.info}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              className="openButton"
                              onClick={() => redirectToPage(ap.url, ap.openInNewTab, x.name)}
                              size="small"
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              fullWidth
                              style={{ marginTop: -15, marginBottom: 5, borderRadius: 20, marginLeft: 10, marginRight: 10, color: 'black' }}
                            >
                              <strong>Open</strong>
                            </Button>
                          </CardActions>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Container>
          );
        })}
      </Container>
    </>
  );
}
export default Categories;
