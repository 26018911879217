import React from 'react';

import './projectDashboard.scss';

import Card from '../../components/card/card';
import DashboardMilestones from '../../components/dashboardMilestones/dashboardMilestones';
import DashboardIssues from '../../components/dashboardIssues/dashboardIssues';
import DashboardRisks from '../../components/dashboardRisks/dashboardRisks';
import DashboardDocuments from '../../components/dashboardDocuments/dashboardDocuments';
import DashboardDecisions from '../../components/dashboardDecisions/dashboardDecisions';
import DashboardWins from '../../components/dashboardWins/dashboardWins';
import DashboardActionItems from '../../components/dashboardActionItems/dashboardActionItems';
import ProjectStatus from './ProjectStatus';

import { RootState, useSelector } from 'store';

function ProjectDashboard() {
  //hiding the ProjectStatus component for now
  const showProjectStatus = false;

  const [currSelectedProject, setCurrSelectedProject] = React.useState<any>({});
  const [selectedType, setSelectedType] = React.useState('');
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);

  React.useEffect(() => {
    main();
  }, [projectChangeCounter]);

  function main() {
    setSelectedType('' + localStorage.getItem('selectedType'));
    
    if (localStorage.getItem('selectedTypeId')) {
      if (localStorage.getItem('selectedType') === 'project') {
        var updatedProj = {
          id: localStorage.getItem('selectedTypeId'),
          isGroupSelected: false
        };

        setCurrSelectedProject(updatedProj);
      } 
      else if (localStorage.getItem('selectedType') == 'group') {
        let updatedProj = {
          id: localStorage.getItem('selectedTypeId'),
          isGroupSelected: true
        };

        setCurrSelectedProject(updatedProj);
      }
    }
  }

  return (
    <Card id="dashboard-card" name={''}>
      { showProjectStatus ? <ProjectStatus projectId={currSelectedProject.id} selectedType={selectedType} /> : null }
      <div data-cy="dashboard-card-row2" className="row mb-3">
        <div data-cy="dashboard-card-col1" className="col-sm-8">
          <div className="row">
            <div className="col-sm-6" hidden={selectedType == 'group' || !localStorage.getItem('selectedTypeId')}>
              <DashboardWins details={currSelectedProject}></DashboardWins>
              <DashboardMilestones details={currSelectedProject}></DashboardMilestones>
            </div>
            <div className="col-sm-6" hidden={selectedType == 'project'}>
              <DashboardWins details={currSelectedProject}></DashboardWins>
              <DashboardMilestones details={currSelectedProject}></DashboardMilestones>
            </div>
            <div className="col-sm-6">
              <DashboardActionItems details={currSelectedProject}></DashboardActionItems>
              <DashboardDecisions details={currSelectedProject}></DashboardDecisions>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <DashboardDocuments details={currSelectedProject}></DashboardDocuments>
            </div>
          </div>
        </div>
        <div data-cy="dashboard-card-col2" className="col-sm-4">
          <DashboardIssues details={currSelectedProject}></DashboardIssues>
          <DashboardRisks details={currSelectedProject}></DashboardRisks>
        </div>
      </div>
    </Card>
  );
}

export default ProjectDashboard;
