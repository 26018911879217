import { GridColumns } from "@mui/x-data-grid-pro";
import moment from "moment";

export const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 5,
      editable: true,
      sortable: true,
      valueParser(value, params?) {
        return value.length > 255 ? value.slice(0, 255) : value;
      },
      preProcessEditCellProps(params) {
        const invalid = params.props.value ? false : true;
        if (invalid) {
          params.props.error = invalid;
          params.props.className = 'error';
          return { ...params.props };
        } else {
          params.props.error = invalid;
          params.props.className = '';
          return { ...params.props };
        }
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 8,
      editable: true,
      sortable: true,
      valueParser(value, params?) {
        return value.length > 255 ? value.slice(0, 255) : value;
      },
      preProcessEditCellProps(params) {
        const invalid = params.props.value ? false : true;
        if (invalid) {
          params.props.error = invalid;
          params.props.className = 'error';
          return { ...params.props };
        } else {
          params.props.error = invalid;
          params.props.className = '';
          return { ...params.props };
        }
      }
    },
  ];
