import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { Navbar, Nav } from 'react-bootstrap';
import PubSub from 'pubsub-js';
import apiCalls from 'utils/api_calls';
import { Config } from 'config/config';
import { useMediaQuery } from '@mui/material';
import { useDispatch } from 'store';
import { setCurrentProjectName, setProjectChangeCounter, setToastInfo } from 'store/reducers/common/CommonReducer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastKind } from 'types/CommonReducerType';
import { fetchCurrentUsers } from 'store/reducers/common/actions';

function ProjectDropdown() {
  let [projects, setProjects] = React.useState([
    {
      ID: null,
      NAME: 'None',
      ACTIVE: true,
      URL_SLUG: 'project',
      TYPE: '',
      SITE_ID: ''
    }
  ]);
  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [active, setActive] = useState<any | null>(null);
  const [selectedItem, setSelectedItem] = React.useState(projects.find((t: any) => t.ACTIVE));
  const [currentRouetPath, setCurrentRoutePath] = React.useState('/');

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  React.useEffect(() => {
    main();
    let token = PubSub.subscribe('setHeaders', main);
    let token1 = PubSub.subscribe('updateProjDropdown', main);
    let token2 = PubSub.subscribe('updateProjDropdown', main);
    return () => {
      PubSub.unsubscribe(token);
      PubSub.unsubscribe(token1);
      PubSub.unsubscribe(token2);
    };
  }, []);

  const changeRoutes = () => {
    let slugUrl = selectedItem?.ID ? selectedItem?.URL_SLUG : localStorage.getItem('selectedProjUrl');
    const routeParts = location.pathname.split('/');
    const endPath = routeParts[routeParts.length - 1];
    const currentRoute = `/${slugUrl ?? 'project'}/${endPath}`;
    setCurrentRoutePath(currentRoute);
    if (params.hasOwnProperty('projName')) {
      navigate(`${currentRoute}`, { replace: true });
    }
  };

  React.useEffect(() => {
    changeRoutes();
    selectedItem?.ID && updateActiveProject(selectedItem);
  }, [selectedItem]);

  React.useEffect(() => {
    const locaTionPathName = location.pathname;
    if (locaTionPathName !== currentRouetPath) {
      changeRoutes();
    }
  }, [location]);

  const convertToSlug = (...args: (string | number)[]): string => {
    const value = args.join(' ');
    return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-'); // separator
  };

  function main() {
    // Call Get user projects API to fetch user projects Details
    apiCalls
      .getAll(Config.api.endPoint + 'Users')
      .then((res) => {
        let data: any = res;
        let userProjects: any[] = [];
        if ((data.projects != null && data.projects.length > 0) || (data.Groups != null && data.Groups.length > 0)) {
          let currentProjectId = localStorage.getItem('selectedTypeId');
          currentProjectId = data.currentProject || currentProjectId || data.projects[0]['id'];
          data.projects?.forEach((project: any) => {
            let prj = {
              ID: project.id,
              NAME: project.name,
              URL_SLUG: convertToSlug(project.name),
              ACTIVE: currentProjectId === project.id ? true : false,
              TYPE: 'project',
              SITE_ID: project.projectSiteId
            };
            userProjects.push(prj);
          });
          data.Groups?.forEach((group: any) => {
            let grp = {
              ID: group.groupId,
              NAME: group.groupName,
              URL_SLUG: convertToSlug(group.groupName),
              ACTIVE: currentProjectId === group.groupId ? true : false,
              TYPE: 'group',
              SITE_ID: ''
            };
            userProjects.push(grp);
          });
          setProjects(userProjects);
          let userProjs = userProjects.map(({ ID, NAME }) => ({ ID, NAME }));
          localStorage.setItem('userProjects', JSON.stringify(userProjs));

          userProjects.sort(function (a: any, b: any) {
            if (a.NAME < b.NAME) {
              return -1;
            }
            if (a.NAME > b.NAME) {
              return 1;
            }
            return 0;
          });
          let actveProject = userProjects.find((t: any) => t.ACTIVE);
          setSelectedItem(actveProject);
        } else {
          dispatch(
            setToastInfo({
              showToast: true,
              toastKind: ToastKind.ERROR,
              toastMsg: 'You do not have any project assigned to you'
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          setToastInfo({
            showToast: true,
            toastKind: ToastKind.ERROR,
            toastMsg: 'Error loading projects'
          })
        );
      });
  }

  const handleChange = (e: string) => {
    projects.forEach((option: any) => {
      if (option.ID !== e) {
        option.ACTIVE = false;
      } else {
        option.ACTIVE = true;
      }
    });
    let selected = projects.filter((x: any) => x.ID === e)[0];
    setSelectedItem(selected);
  };

  async function updateActiveProject(project: any) {
    apiCalls.update(Config.api.endPoint + 'Users/CurrentProject?projectId=' + project.ID, {});
    await PubSub.publishSync('setRoutes');
    await PubSub.publishSync('setBannerText');
    await PubSub.publishSync('setSupportEmails');
    await PubSub.publishSync('updateSchedule');
    await PubSub.publishSync('updateStatus');
    await PubSub.publishSync('setDocuments');
    await PubSub.publishSync('UpdateSideBarUsingFeatureFlag');

    localStorage.setItem('selectedType', project.TYPE);
    localStorage.setItem('selectedTypeId', project.ID);
    localStorage.setItem('selectedProjName', project.NAME);
    localStorage.setItem('selectedProjSiteId', project.SITE_ID);
    localStorage.setItem('selectedProjUrl', project.URL_SLUG);

    dispatch(setProjectChangeCounter());
    dispatch(setCurrentProjectName(project.NAME));
    dispatch(fetchCurrentUsers({
      projectId: project.ID
    }));
  }

  return (
    <>
      {!matchesMd && (
        <Navbar data-cy="header-navbar" style={{ height: '50px', width: '25%' }} expand="sm" variant="dark">
          <Navbar.Collapse data-cy="navbar-collapse" id="basic-navbar-nav">
            <Nav data-cy="navbar-nav" activeKey={active} onSelect={(selectedKey) => setActive(selectedKey)}>
              <select
                data-cy={selectedItem?.ID}
                value={selectedItem?.ID || ''}
                title={selectedItem?.NAME}
                onChange={(e) => handleChange(e.target.value)}
                className="selectStyle form-group form-select form-select-sm float-start"
                aria-label=".form-select-md"
                disabled={selectedItem?.NAME === 'None' ? true : false}
              >
                {projects.map((x: any) => (
                  <option data-cy={x.ID} value={x.ID} key={x.ID} style={{ backgroundColor: 'white' }}>
                    {x.NAME}
                  </option>
                ))}
              </select>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}

      {matchesMd && (
        <Navbar data-cy="header-navbar" style={{ height: '50px', marginLeft: '10px', width: '75%' }} variant="dark">
          <Navbar.Collapse data-cy="navbar-collapse" id="basic-navbar-nav">
            <Nav data-cy="navbar-nav" activeKey={active} onSelect={(selectedKey) => setActive(selectedKey)}>
              <select
                data-cy={selectedItem?.ID}
                value={selectedItem?.ID || ''}
                title={selectedItem?.NAME}
                onChange={(e) => handleChange(e.target.value)}
                className="selectStyle form-group form-select form-select-sm float-start"
                aria-label=".form-select-md"
                disabled={selectedItem?.NAME === 'None' ? true : false}
              >
                {projects.map((x: any) => (
                  <option data-cy={x.ID} value={x.ID} key={x.ID} style={{ backgroundColor: 'white' }}>
                    {x.NAME}
                  </option>
                ))}
              </select>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
}

export default ProjectDropdown;
