import * as React from 'react';
import './dashboardDecisions.scss';
import "hammerjs";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT } from 'utils/utils';
import { Button } from '@mui/material';


type decisionsProps = {
  details: any
}

function DashboardDecisions(props: decisionsProps) {
  const [projectDecisions, setProjectDecisions] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.details.id != null && props.details.id != "") {
      getProjectDecisions();
    }
  }, [props.details]
  );

  function getProjectDecisions() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'decisions',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {

      apiCalls.create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let groupDecisions = res.length > 0 ? res : [];

          let projDecisions: any = [];

          groupDecisions.forEach((group: any) => {
            projDecisions.push(...group.decisions);
          });

          projDecisions = projDecisions.slice(0, 3);

          setProjectDecisions(projDecisions);

          setIsLoading(false);

        }).catch(err => {
          setProjectDecisions([]);
          setIsLoading(false);
        });
    }
    else {
      apiCalls.create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projDecisions: any = res.length > 0 ? res[0].decisions : [];

          projDecisions = projDecisions.slice(0, 3);
          setProjectDecisions(projDecisions);
          setIsLoading(false);
        }).catch(err => {
          setProjectDecisions([]);
          setIsLoading(false);
        });
    }
  }

  return (
    <div data-cy={'dashboard-decisions-card'} className="card dashboard-decisions-card" >
      <div data-cy="card-title" className="regionDecisionsHeader">
        <div style={{alignItems:'center'}} className="row">
          <span className="decisions-main-header col-md-8">Approved Decisions</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/decisions')} color="primary" >
              View All
            </Button>
          </span>
        </div>
      </div>

      <div className={`dashboard-decisions-div ${projectDecisions.length === 0 && "dashboard-decisions-empty"}`}>
        <BackDrop isLoading={isLoading} />
        {projectDecisions.length > 0 && (
          <>
            <div data-cy={'decisions-div'} className="decisions-div" style={{ fontSize: 12 }}>
              {projectDecisions?.map((x: any, index: number) =>
                <div key={"Decisions" + index}>
                  <div className="row">
                    <div className="col-sm-1">
                      <FontAwesomeIcon size='2x' style={{ color: "#f2f2f2", marginTop: 7 }} data-cy={x.id + '-circle-icon'} icon={faCircle} />
                    </div>
                    <div className="col-sm-11 clipped">
                      <span className="decisions-sub-header">{x.Title}</span><br />
                      <span className="decisions-main-header">{moment(x.Decision_x0020_Date).format("MMMM DD, yyyy")}</span><br />
                    </div>
                  </div>
                  <hr className="line-separator" hidden={(index + 1) === projectDecisions?.length} />
                </div>
              )}
            </div>
          </>
        )}
        {projectDecisions.length === 0 && (
          <span>No decisions available</span>
        )}
      </div>

      <div data-cy="viewall" hidden>
        <input hidden={projectDecisions.length === 0} type="button" className="btn btn-sm btn-view-all-decisions" value="View all"></input>
      </div>
    </div>
  );
}
export default DashboardDecisions;