import { GridColumns } from "@mui/x-data-grid-premium";
import * as yup from 'yup';

export const groupColumnIds = ['ProjectId', 'ProjectName'];

export const columns: GridColumns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 5,
        sortable: true,
        groupable: false,
        editable: true,
        preProcessEditCellProps(params) {
            const invalid = params.props.value ? false : true;
            if(invalid){
                params.props.error = invalid; 
                params.props.className = 'error';
                return { ...params.props };
            }
            else{
                params.props.error = invalid; 
                params.props.className = '';
                return { ...params.props };
            }
        }
    },
    {
        field: 'emailAddress',
        headerName: 'Email Address',
        flex: 5,
        sortable: true,
        groupable: false,
    },
    {
        field: 'roles',
        headerName: 'Type',
        flex: 5,
        sortable: true,
        groupable: false
    }
]

export const validationSchema = yup.object({
    name: yup.string().required("Name field is required"),
    email: yup.string().email().required("Email field is required"),
  });

  