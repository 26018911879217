import { useMemo } from 'react';
import React, { useState } from 'react';
// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { Config } from "../../../../config/config";
// project import
import useConfig from 'hooks/useConfig';
import Search from './Search';
import Message from './Message';
import Profile from './Profile';
import Localization from './Localization';
import Notification from './Notification';
import MobileSection from './MobileSection';
import MegaMenuSection from './MegaMenuSection';
import ProjcetDropdown from './ProjectDropdown';
import Administration from './Administration';
import Support from './Support';
import { useAuth0 } from "@auth0/auth0-react";
import apiCalls from 'utils/api_calls';


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();
  let [isPinEmployee, SetIsPinEmployee] = React.useState(false);
  let [settingsFlagName, setSettingsFlagName] = React.useState("pinnplat-administration");
  let [isSettingsEnabled, setIsSettingsEnabled] = React.useState(false);
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);
  const megaMenu = useMemo(() => <MegaMenuSection />, []);
  let [userName, setUserName] = React.useState("");
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  React.useEffect(() => {
    main();
    
    var token = PubSub.subscribe('setHeaders', main);
    return () => { PubSub.unsubscribe(token); }
    
  }, []
  );

  function main() {
    var loggedInUser : any = user ? user : {};

    var pinnUsersDomain = Config.PinnacleUsersDomain;
    if (pinnUsersDomain.some((x: any) => loggedInUser.email.toUpperCase().includes(x.toUpperCase())))
    {
      SetIsPinEmployee(true);
      localStorage.setItem("isPinnacleEmployee", "true");
    } else {
      SetIsPinEmployee(false);
      localStorage.setItem("isPinnacleEmployee", "false");
    }

    var loggedInUserEmail : any = localStorage.getItem("UserEmail") != null ? localStorage.getItem("UserEmail") : "";
    var emailDomain = loggedInUserEmail?.split('@')[1];

    apiCalls.getAll(Config.api.endPoint + 'FeatureFlagsForProjectGroups?groupName=' + emailDomain + '&flagName=' + settingsFlagName)
      .then((res: any) => {
        var data : any = res;
        if (data.ResponseCode == 200 && data.ResponseData == 'True') {
          setIsSettingsEnabled(true);
        } else {
          setIsSettingsEnabled(false);
        } 
      });

  }

  function toCamelCase(str: any) {
    return str.split(' ').map(function (word: any, index: any) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
    });
  }

  return (
    <>
    
      {!matchesXs && <ProjcetDropdown />}
      {!matchesXs && <Search />}
      {/* {!matchesXs && megaMenu} */}
      {/* {!matchesXs && localization} */}
      {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Notification /> */}
      {/* <Message /> */}
      { isSettingsEnabled && !matchesXs && <Administration /> }
      {!matchesXs && <Support /> }
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
