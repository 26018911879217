import { AppBar, Button, DialogActions, Grid, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

interface ConfirmModalProps {
  toggleDeleteForm: boolean;
  type?: string;
  customHeaderText?: string;
  handleConfirmation: (e: any) => void;
  handleCloseModal: (e: boolean) => void;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal: React.FC<ConfirmModalProps> = ({ toggleDeleteForm, type, handleConfirmation, ...rest }) => {
  const [showDelete, setShowDelete] = React.useState(false);
  React.useEffect(() => {
    setShowDelete(toggleDeleteForm);
  }, [toggleDeleteForm]);
  const handleDeleteActionItemClose = () => {
    setShowDelete(false)
    rest.handleCloseModal(false);
  };
  return (
    <Dialog open={showDelete} onClose={handleDeleteActionItemClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDeleteActionItemClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {rest?.customHeaderText? rest?.customHeaderText : `${type} Item Information`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={4} style={{ padding: 50 }}>
        <Grid item xs={12}>
          Are you sure you want to delete?
        </Grid>
      </Grid>
      <DialogActions>
        <Button variant="contained" onClick={handleDeleteActionItemClose}>
          Cancel
        </Button>
        <Button color="error" onClick={handleConfirmation} variant="contained" startIcon={<DeleteIcon />}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
