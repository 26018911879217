import React from 'react';
import { 
  IssuesItemTypes, 
  IssueItemContextStateType,
  FormMode, 
  IssueItemContextType, 
  IssueItemActionType,
  ToastKind
 } from '../issueItemTypes';


export const initialState: IssueItemContextStateType = {
  isLoading: true,
  issues: [],
  connection: null,
  projectDetails: {},
  selectedIds: [],
  projectGroups: [],
  projectChanged: 0,
  showAdd: false,
  showUpdate: false,
  showDelete: false,
  showToast: false,
  toastKind: ToastKind.SUCCESS,
  toastMsg: '',
  projectSummary: {},
  formMode: FormMode.VIEW,
  refreshApiCounter: 0,
  actionitem: {},
  deleteActionItem: {},
  selectedUser: {},
  isFeatureEnabled: false,
};

const defaultAction: IssueItemActionType = {
  type: IssuesItemTypes.SET_ISSUES_LIST_ITEMS,
  payload: '',
};

export function IssuesItemsReducers(
  state: IssueItemContextStateType = initialState,
  action: IssueItemActionType = defaultAction
): IssueItemContextStateType {
  switch (action.type) {
    case IssuesItemTypes.SET_ISSUE_ITEM_TOAST: {
      return {
        ...state,
        toastMsg: action.payload.toastMsg,
        toastKind: action.payload.toastKind,
        isLoading: false,
      };
    }
    case IssuesItemTypes.SET_LIST_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        issues: action.payload.payloadItems.map((m: any) =>{
          return {
            ...m,
          }
        }),
        projectSummary: action.payload.projectSummary,
        projectDetails: action.payload.projectDetails,
        projectGroups: action.payload.projectGroups,
      };
    }
    case IssuesItemTypes.SET_ISSUES_LIST_ITEMS: {
      return {
        ...state,
        isLoading: false,
        issues: action.payload.map( (m: any) =>{
          return {
            ...m,
          }
        })
      };
    }
    case IssuesItemTypes.SET_ISSUE_ITEM_LOADING_STATUS: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case IssuesItemTypes.SET_SOCKET_CONNECTION: {
      return {
        ...state,
        connection: action.payload,
      };
    }
    case IssuesItemTypes.SET_SELECTED_GRID_ROW_IDS: {
      return {
        ...state,
        selectedIds: action.payload
      }
    }
    case IssuesItemTypes.SET_SHOW_ADD_FORM: {
      return {
        ...state,
        showAdd: action.payload
      }
    }
    case IssuesItemTypes.SET_SHOW_UPDATE_FORM: {
      return {
        ...state,
        showUpdate: action.payload
      }
    }
    case IssuesItemTypes.SET_SHOW_DELETE_FORM: {
      return {
        ...state,
        showDelete: action.payload
      }
    }
    case IssuesItemTypes.SET_REFRESH_API_COUNTER: {
      return {
        ...state,
        refreshApiCounter: state.refreshApiCounter+1
      }
    }
    case IssuesItemTypes.SET_UPDATE_ISSUE_ITEM: {
      return {
        ...state,
        actionitem: action.payload
      }
    }
    case IssuesItemTypes.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.payload
      }
    }
    case IssuesItemTypes.SET_DELETE_ISSUE_ITEM: {
      return {
        ...state,
        deleteActionItem: action.payload
      }
    }
    case IssuesItemTypes.SET_FEATURE_FLAG: {
      return {
        ...state,
        isFeatureEnabled: action.payload
      }
    }
    case IssuesItemTypes.SET_PROJECT_CHANGE: {
      return {
        ...state,
        projectChanged: state.projectChanged+1
      }
    }
    case IssuesItemTypes.SET_TOAST_INFO: {
      return {
        ...state,
        toastKind: action.payload.toastKind,
        toastMsg: action.payload.toastMsg,
        showToast: action.payload.showToast,
      }
    }
    default: {
      return state;
    }
  }
}

