import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  ButtonBase,
  Stack
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { useNavigate } from 'react-router-dom';

// assets
import { SettingOutlined, GroupOutlined } from '@ant-design/icons';
import { RootState, useSelector } from 'store';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
  };

  const Administration = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const isProjectGroupEnabled = useSelector((state: RootState) => state.common['isProjectGroupEnabled']);
    const anchorRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  
    return (
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'grey.200' : 'grey.500' },
          '&:focus-visible': {
            outline: `3px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        title="Administration"
        aria-label="open support"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <SettingOutlined style={{ fontSize: '22px' }}/>
        </Stack>
      </ButtonBase>
        <Popper
          placement={matchesXs ? 'bottom' : 'bottom-end'}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [matchesXs ? -5 : 0, 9]
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: '100%',
                  minWidth: 285,
                  maxWidth: 420,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 285
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    title="Administration"
                    elevation={0}
                    border={false}
                    content={false}
                  >
                    <List
                      component="nav"
                      sx={{
                        marginBottom: 2,
                        p: 0,
                        '& .MuiListItemButton-root': {
                          py: 0.5,
                          '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                          '& .MuiAvatar-root': avatarSX,
                          '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                        }
                      }}
                    >

                      {isProjectGroupEnabled && <ListItemButton onClick={() => navigate('/projectgroups')}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'error.main',
                              bgcolor: 'error.lighter'
                            }}
                          >
                            <GroupOutlined />
                          </Avatar>

                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              Project groups
                            </Typography>
                          }
                        />
                        
                      </ListItemButton>}
                    </List>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      </Box>
    );
  };

  export default Administration;