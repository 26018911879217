import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ZendeskAPI } from "react-zendesk";
import { Auth0Provider } from "@auth0/auth0-react";

// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/flaticon/css/flaticon.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// load mock apis
// import '_api';
import { Config } from 'config/config';

// project import
import App from './App';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(Config.MUI.licenseKey);

const { gtag, install } = require("ga-gtag");

install(Config.GoogleAnalytics.measurementId);
gtag('js', new Date());

var s = document.createElement('script');
s.id = "ze-snippet";
var head = document.getElementsByTagName("head")[0];
head.appendChild(s);
s.type = 'text/javascript';
s.src = "https://static.zdassets.com/ekr/snippet.js?key="+ Config.ZenDesk.key;

s.onload = function() {
  ZendeskAPI('webWidget', 'hide');
  ZendeskAPI('webWidget:on', 'close', function () {
    ZendeskAPI('webWidget', 'hide');
  });
}
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ConfigProvider>
        <BrowserRouter>
          <Auth0Provider cacheLocation="localstorage" {...Config.auth0}>
            <App />
          </Auth0Provider>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
