import './MUIGrid.scss';
import { uuid } from 'uuidv4';
import { DataGridPremium, GridCellEditStartParams, GridCellParams, gridClasses, MuiEvent } from '@mui/x-data-grid-premium';
import { useSelector, RootState } from 'store';

interface MUIDataGridProps {
    rows: any,
    rowIdColum?: any,
    columns: any,
    checkboxSelection?: any,
    rowModesModel: any,
    onRowEditStart?: any,
    onRowEditStop?: any,
    processRowUpdate?: any,
    components: any,
    componentsProps: any,
    onSelectionModelChange?: any,
    onRowModesModelChange: any,
    isCellEditable?: any;
    onCellClick?: (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => void;
    type?: string;
    style?: any;
    disableSelectionOnClick?: boolean;
    experimentalFeatures?: any;
    loading?: boolean;
    onCellEditStart?: (params: GridCellEditStartParams,event: MuiEvent<React.SyntheticEvent>) => void;
}

const MUIDataGrid: React.FC<MUIDataGridProps> = (props) => {

    const isClosed = useSelector((state: RootState) => state.common['isClosed']);

    return (<div data-cy={`${props.type}-card-row`} className="row">
        <div data-cy={`${props.type}-card-col`} className="col-sm-12" style={{ height: '73vh', width: "100%" }}>
            <DataGridPremium
                sx={{
                    '& .error': {
                        border: '1px solid red !important',
                        height: 59 //grid row height - 1
                    },
                    [`& .${gridClasses.cell}`]: {
                        py: 2,
                    }
                }}
                style={{
                    backgroundColor: 'white'
                }}
                isCellEditable={props?.isCellEditable? props.isCellEditable: () => !isClosed}
                checkboxSelection={props?.checkboxSelection || !isClosed}
                getRowId={(row) => row[props.rowIdColum] ? row[props.rowIdColum] : uuid()}
                editMode="cell"
                getRowHeight={() => 'auto'}
                experimentalFeatures={{ newEditingApi: true }}
                rowHeight={60}
                {...props}
            />
        </div>
    </div>);
}

const getApplyFilterFnDate = (value: string) => {
    console.log(value)
    return (params: GridCellParams): boolean => {
        if (params.value instanceof Date) {
            return params.value.getFullYear() === Number(value) || (params.value.getMonth() + 1) === Number(value) || params.value.getDate() === Number(value);
        }
        return false;
    };
};

export default { MUIDataGrid, getApplyFilterFnDate };
