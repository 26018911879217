import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { processFormData } from 'store/reducers/common/actions';
import apiCalls from 'utils/api_calls';
import { IssuesItemTypes, ToastKind } from '../issueItemTypes';

export const setSelectedGridRowIds = (dispatch: React.Dispatch<any>, IDS: GridSelectionModel) => {
  dispatch({
    type: IssuesItemTypes.SET_SELECTED_GRID_ROW_IDS,
    payload: IDS
  });
};

export const setToggleAddForm = (dispatch: React.Dispatch<any>, showForm: boolean) => {
  dispatch({
    type: IssuesItemTypes.SET_SHOW_ADD_FORM,
    payload: showForm
  });
};

export const setToggleUpdateForm = (dispatch: React.Dispatch<any>, showForm: boolean) => {
  dispatch({
    type: IssuesItemTypes.SET_SHOW_UPDATE_FORM,
    payload: showForm
  });
};

export const setToggleDeleteForm = (dispatch: React.Dispatch<any>, showDeleteModal: boolean) => {
  dispatch({
    type: IssuesItemTypes.SET_SHOW_DELETE_FORM,
    payload: showDeleteModal
  });
};

export const setActionItemForUpdate = (dispatch: React.Dispatch<any>, item: any) => {
  dispatch({
    type: IssuesItemTypes.SET_UPDATE_ISSUE_ITEM,
    payload: item
  });
};

export const setIssuesItemForDelete = (dispatch: React.Dispatch<any>, item: any) => {
  dispatch({
    type: IssuesItemTypes.SET_DELETE_ISSUE_ITEM,
    payload: item
  });
};

export const setApiRefreshCounter = (dispatch: React.Dispatch<any>) => {
  dispatch({
    type: IssuesItemTypes.SET_REFRESH_API_COUNTER
  });
};

export const setProjectChangeCounter = (dispatch: React.Dispatch<any>) => {
  dispatch({
    type: IssuesItemTypes.SET_PROJECT_CHANGE
  });
};

export const setTableLoader = (dispatch: React.Dispatch<any>, isLoading: boolean) => {
  dispatch({
    type: IssuesItemTypes.SET_ISSUE_ITEM_LOADING_STATUS,
    payload: isLoading
  });
};

export const setSelectedUser = (dispatch: React.Dispatch<any>, user: any) => {
  dispatch({
    type: IssuesItemTypes.SET_SELECTED_USER,
    payload: user
  });
};

export const setActionItems = (dispatch: React.Dispatch<any>, items: any[]) => {
  dispatch({ type: IssuesItemTypes.SET_ISSUES_LIST_ITEMS, payload: items });
};

export const setToastInfo = (dispatch: React.Dispatch<any>, toastKind = ToastKind.INFO, toastMsg = '', showToast = true) => {
  dispatch({
    type: IssuesItemTypes.SET_TOAST_INFO,
    payload: {
      toastKind,
      toastMsg,
      showToast
    }
  });
};

export const handleListResponse = (dispatch: React.Dispatch<any>, response: any) => {
  dispatch({
    type: IssuesItemTypes.SET_LIST_RESPONSE,
    payload: response
  });
};


export const deleteIssueAction = (dispatch: React.Dispatch<any>, IDS: any) => {
  let ProjectGuid = '';
  let deleteBody = IDS.map((ID: String) => {
    let splitId = ID.split('--');
    ProjectGuid = splitId[1];
    return {
      id: splitId[0],
      siteId: splitId[2],
      projectId: splitId[1]
    };
  });
  dispatch(
    processFormData({
      payLoad: deleteBody,
      endpoint: 'Issues',
      hubname: 'Issues',
      ProjectGuid,
      clientId: '-deleteissue-0',
      successEvent: 'deleteIssueUserSuccessMessage',
      errorEvent: 'IssuesUserErrorMessage',
      apiMethod: apiCalls.deleteAll
    })
  );
};
