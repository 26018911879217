import { useReducer } from 'react';
import Card from 'components/card/card';
import ActionitemTable from './actionItemTable/actionItemTable';
import { ActionItemsReducers, initialState, ActionItemsContext } from './context/reducer';
import ActionItemForm from './actionItemForm/actionItemForm';
import './actionitems.scss';
import { RootState, useSelector } from 'store';
import React from 'react';
import { useNavigate } from 'react-router-dom';


/* Top ActionItems Function */
function ActionItems() {
  const [state, dispatch] = useReducer(ActionItemsReducers, initialState);
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const navigate = useNavigate();
  
  // returns the actionitems html
  return (
    <ActionItemsContext.Provider value={{ state, dispatch }}>
      <Card id="actionitems-card" name={'Action Items'}>
        <div data-cy="action-card-row" className="row">
          <div data-cy="action-card-col" className="col-sm-12" style={{ height: '73vh', width: '100%' }}>
            <ActionitemTable />
            <ActionItemForm />
          </div>
        </div>
      </Card>
    </ActionItemsContext.Provider>
  );
}
export default ActionItems;
