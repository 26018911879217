import React from 'react';
import './decisions.scss';
import apiCalls from 'utils/api_calls';
import Card from '../../components/card/card';
import { useNavigate } from 'react-router-dom';
import MUIDataGridFunctions from '../../components/MUIGrid/MUIGrid';
import { GridRowModesModel, GridRowParams, MuiEvent, GridEventListener, GridRowModel } from '@mui/x-data-grid-pro';
import { JsonSchema } from '@jsonforms/core';
import { columns, decisionsFormSchema, decisionsFormUischema } from './constants';
import TableToolbar from 'components/TableToolbar';
import FormBuilderComponent from 'components/formBuilder/formBuilder';
import ConfirmModal from 'components/ConfirmModal';
import { useDispatch, useSelector } from 'store';
import { RootState } from 'types/CommonReducerType';
import { fetchListdata, processFormData } from 'store/reducers/common/actions';
import { getValidItemList, groupColumnIds } from 'utils/utils';

/* Top Decisions Function */
function Decisions() {
  const [decisionsChecked, setDecisionsChecked] = React.useState([] as any);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const navigate = useNavigate();
  const [formSchema, setFormSchema] = React.useState<JsonSchema>(decisionsFormSchema);
  const [formUiSchema, setFormUiSchema] = React.useState<any>(decisionsFormUischema);
  const [showAdd, setShowAdd] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState(columns);

  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);
  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const isClosed = useSelector((state: RootState) => state.common['isClosed']);
  const decisions = getValidItemList(
    useSelector((state: RootState) => state.common['listItems']),
    columns
  );
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let requredFields = formSchema['required']?.filter((item) => item !== 'project');
    let element = formUiSchema['elements']?.find((elm: any) => elm.scope === '#/properties/project');
    let projectFldIndex = formUiSchema['elements']?.findIndex((elm: any) => elm.scope === '#/properties/project');
    if (element) {
      element['rule']['effect'] = isprojectGroup ? 'SHOW' : 'HIDE';
      formUiSchema[projectFldIndex] = element;
      setFormUiSchema(formUiSchema);
    }

    if (isprojectGroup) {
      requredFields?.push('project');
    }
    formSchema['required'] = requredFields;
    setFormSchema(formSchema);
  }, [projectChangeCounter]);

  // Exclude project group columns
  React.useEffect(() => {
    let isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let modifiedColumns = columns.filter((column) => {
      if (!isprojectGroup) {
        return !groupColumnIds.includes(column.field);
      }
      return true;
    });
    setGridColumns(modifiedColumns);
  }, [projectChangeCounter]);

  const handleAddWInShow = () => {
    setShowAdd(true);
  };

  const handleAddDecisionClose = () => setShowAdd(false);
  const onDeleteClick = () => {
    if (decisionsChecked.length === 1) {
      confirmDelete();
    } else {
      setShowDelete(true);
    }
  };

  function EditToolbar() {
    return (
      <TableToolbar
        isHidden={isClosed}
        onAddClick={() => handleAddWInShow()}
        onDeleteClick={onDeleteClick}
        isDeleteDisabled={decisionsChecked.length === 0}
        isExportDisabled={decisions.length === 0}
        toolbarName="Decisions"
      />
    );
  }

  React.useEffect(() => {
    main();
  }, [projectChangeCounter]);

  const modiFyitemCallback = (item: any) => {
    let html = item.Description;
    let div = document.createElement('div');
    div.innerHTML = html;
    let text = div.textContent || div.innerText || '';
    item['Description'] = text;
    return { ...item };
  };

  function main() {
    dispatch(
      fetchListdata({
        ItemName: 'decisions',
        type: 'decisions',
        customId: 'decisionId',
        modiFyitemCallback
      })
    );
  }

  const handleSubmitAdd = async (formData: any) => {
    handleAddDecisionClose();
    const projectId = localStorage.getItem('selectedType') === 'group' ? formData.project : projectDetails.ProjectGuid;
    const payLoad = {
      title: formData.title,
      decision_x0020_Made_x0020_By: '',
      approver: '',
      approval: formData.approvalStatus,
      impact: formData.impact,
      decision_x0020_Date: formData.decisionDate,
      description: formData.description,
      projectId
    };
    await dispatch(
      processFormData({
        payLoad,
        endpoint: 'Decision',
        hubname: 'Decisions',
        ProjectGuid: projectId,
        clientId: '-createdecision-0',
        successEvent: 'CreatesDecisionsUserSuccessMessage',
        errorEvent: 'DecisionsUserErrorMessage',
        apiMethod: apiCalls.create
      })
    )
  };

  const handleSubmitUpdate = async (formData: any) => {
    let payLoad = {
      id: formData.id,
      title: formData['Title'],
      decision_x0020_Made_x0020_By: formData['Decision_x0020_Made_x0020_By'],
      approver: formData['Approver'],
      approval: formData['Approval'],
      impact: formData['Impact'],
      decision_x0020_Date: formData['Decision_x0020_Date'],
      description: formData['Description'],
      projectId: formData['ProjectGuid']
    };

    dispatch(
      processFormData({
        payLoad,
        endpoint: 'Decision',
        hubname: 'Decisions',
        ProjectGuid: formData['ProjectGuid'],
        clientId: '-updatedecision-0',
        successEvent: 'UpdateDecisionsUserSuccessMessage',
        errorEvent: 'DecisionsUserErrorMessage',
        apiMethod: apiCalls.update
      })
    )
  };

  const handleRowEditStart = (_params: GridRowParams, event: MuiEvent) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow.id !== 0) {
      handleSubmitUpdate(updatedRow);
    }

    return updatedRow;
  };

  const confirmDelete = async () => {
    let ProjectGuid = '';
    let deleteBody = decisionsChecked.map((ID: String) => {
      let splitId = ID.split('--');
      ProjectGuid = splitId[1];
      return {
        id: splitId[0],
        siteId: splitId[2],
        projectId: splitId[1]
      };
    });
    setShowDelete(false);

    dispatch(
      processFormData({
        payLoad: deleteBody,
        endpoint: 'Decision',
        hubname: 'Decisions',
        ProjectGuid,
        clientId: '-deletedecision-0',
        successEvent: 'DeleteDecisionsUserSuccessMessage',
        errorEvent: 'DecisionsUserErrorMessage',
        apiMethod: apiCalls.deleteAll
      })
    )
  };

  // returns the decisions html
  return (
    <Card id="decisions-card" name={'Decisions'}>
      <MUIDataGridFunctions.MUIDataGrid
        rows={decisions}
        rowIdColum={'decisionId'}
        disableSelectionOnClick
        columns={gridColumns}
        loading={isLoading}
        onSelectionModelChange={(itm: any) => setDecisionsChecked(itm)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel: any) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        componentsProps={{
          toolbar: {}
        }}
        components={{
          Toolbar: EditToolbar
        }}
      />
      <FormBuilderComponent
        formTitle={'Add Decision'}
        schemaObj={formSchema}
        initialData={{}}
        uiSchema={formUiSchema}
        openDialog={showAdd}
        onClose={handleAddDecisionClose}
        onHandleSubmit={handleSubmitAdd}
      />
      <ConfirmModal
        handleConfirmation={confirmDelete}
        toggleDeleteForm={showDelete}
        type={'Decisions'}
        handleCloseModal={() => setShowDelete(false)}
      />
    </Card>
  );
}
export default Decisions;
