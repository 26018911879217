import React from 'react';
import './wins.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import PubSub from 'pubsub-js';
import Card from '../../components/card/card';
import {
  GridRowsProp,
  GridRowModesModel,
  GridColumns,
  GridRowParams,
  GridToolbarExport,
  MuiEvent,
  GridToolbarContainer,
  GridEventListener,
  GridRowModel,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import MUIDataGridFunctions from '../../components/MUIGrid/MUIGrid';
import { useParams, useNavigate } from 'react-router-dom';
import ConfirmModal from 'components/ConfirmModal';
import { columns, winsFormSchema, winsFormUischema } from './constants';
import { getValidItemList, groupColumnIds } from 'utils/utils';
import { JsonSchema } from '@jsonforms/core';
import FormBuilderComponent from 'components/formBuilder/formBuilder';
import { RootState, useDispatch, useSelector } from 'store';
import { processFormData, fetchListdata } from 'store/reducers/common/actions';
import TableToolbar from 'components/TableToolbar';

/* Top Wins Function */
function Wins() {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [showAdd, setShowAdd] = React.useState(false);
  const [showDeleteSelected, setShowDeleteSelected] = React.useState(false);
  const [winsChecked, setWinsChecked] = React.useState([] as any);
  const navigate = useNavigate();
  const [newlySelectedProj, setNewlySelectedProj] = React.useState('');
  const [gridColumns, setGridColumns] = React.useState(columns);

  const [formSchema, setFormSchema] = React.useState<JsonSchema>(winsFormSchema);
  const [formUiSchema, setFormUiSchema] = React.useState<any>(winsFormUischema);

  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);
  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const isClosed = useSelector((state: RootState) => state.common['isClosed']);
  const wins = getValidItemList(useSelector((state: RootState) => state.common['listItems']), columns);
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const dispatch = useDispatch();

  // Exclude project group columns
  React.useEffect(() => {
    let isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let modifiedColumns = columns.filter((column) => {
      if (!isprojectGroup) {
        return !groupColumnIds.includes(column.field);
      }
      return true;
    });
    setGridColumns(modifiedColumns);
  }, [projectChangeCounter]);

  React.useEffect(() => {
    const isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let requredFields = formSchema['required']?.filter((item) => item !== 'project');
    let element = formUiSchema['elements']?.find((elm: any) => elm.scope === '#/properties/project');
    let projectFldIndex = formUiSchema['elements']?.findIndex((elm: any) => elm.scope === '#/properties/project');
    if (element) {
      element['rule']['effect'] = isprojectGroup ? 'SHOW' : 'HIDE';
      formUiSchema[projectFldIndex] = element;
      setFormUiSchema(formUiSchema);
    }

    if (isprojectGroup) {
      requredFields?.push('project');
    }
    formSchema['required'] = requredFields;
    setFormSchema(formSchema);
  }, [projectChangeCounter]);

  const handleAddWInShow = () => {
    setShowAdd(true);
  };

  function handleDeleteSelectedWinsShow() {
    winsChecked.length > 1 ? setShowDeleteSelected(true) : handleSubmitMultiSelectDelete();
  }

  const handleAddWinClose = () => setShowAdd(false);
  const handleDeleteSelectedWinsClose = () => setShowDeleteSelected(false);

  function EditToolbar() {
    return (
      <TableToolbar
        isHidden={isClosed}
        onAddClick={handleAddWInShow}
        onDeleteClick={handleDeleteSelectedWinsShow}
        isDeleteDisabled={winsChecked.length === 0}
        isExportDisabled={wins.length === 0}
        toolbarName="Wins"
      />
    );
  }

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (newRow.id !== 0) {
      handleSaveUpdate(newRow);
    }

    return updatedRow;
  };

  React.useEffect(() => {
    main();
  }, [projectChangeCounter]);

  const modiFyitemCallback = (item: any) => {
    let html = item.Description;
    let div = document.createElement('div');
    div.innerHTML = html;
    let text = div.textContent || div.innerText || '';
    item['Description'] = text;
    return {...item};
  };

  async function main() {
    dispatch(
      fetchListdata({
        ItemName: 'wins',
        type: 'wins',
        customId: 'winsId',
        modiFyitemCallback
      })
    )
  }

  const handleSubmitAdd = async (formData: any) => {
    handleAddWinClose();
    const projectId = localStorage.getItem('selectedType') === 'group' ? formData.project : projectDetails.ProjectGuid;
    let payLoad = {
      id: '0',
      title: formData.title,
      date: formData.date,
      value: 'Other',
      description: formData.description,
      siteId: localStorage.getItem('selectedType') === 'group' ? null : projectDetails.projectSiteId,
      projectId
    };
    await dispatch(
      processFormData({
        payLoad,
        endpoint: 'Wins',
        hubname: 'siteWinsManagement',
        ProjectGuid: projectId,
        clientId: '-addwins-0',
        successEvent: 'createWinUserSuccessMessage',
        errorEvent: 'createWinUserErrorMessage',
        apiMethod: apiCalls.create
      })
    ).unwrap();
  };

  const handleSaveUpdate = async (formData: any) => {
    const projectId = formData['ProjectGuid'];
    let payLoad = {
      id: formData.id,
      title: formData.Title,
      date: formData.Date,
      value: 'Other',
      description: formData.Description,
      siteId: formData['ProjectSiteId'],
      projectId
    };
    await dispatch(
      processFormData({
        payLoad,
        endpoint: 'Wins',
        hubname: 'siteWinsManagement',
        ProjectGuid: projectId,
        clientId: '-updatewins-' + formData.id,
        successEvent: 'updateWinUserSuccessMessage',
        errorEvent: 'updateWinUserErrorMessage',
        apiMethod: apiCalls.update
      })
    ).unwrap();
  };

  const handleSubmitMultiSelectDelete = async () => {
    handleDeleteSelectedWinsClose();
    let ProjectGuid = '';
    let windid = '';
    let deleteBody = winsChecked.map((ID: String) => {
      let splitId = ID.split('--');
      ProjectGuid = splitId[1];
      windid = splitId[0];
      return {
        id: splitId[0],
        siteId: splitId[2],
        projectId: splitId[1]
      };
    });
    await dispatch(
      processFormData({
        payLoad: deleteBody,
        endpoint: 'Wins',
        hubname: 'siteWinsManagement',
        ProjectGuid: ProjectGuid,
        clientId: '-deletewins-' + windid,
        successEvent: 'deleteWinUserSuccessMessage',
        errorEvent: 'deleteWinUserErrorMessage',
        apiMethod: apiCalls.deleteAll
      })
    ).unwrap();
  };

  // returns the wins html
  return (
    <Card id="wins-card" name={'Wins'}>
      <MUIDataGridFunctions.MUIDataGrid
        rows={wins}
        rowIdColum={'winsId'}
        loading={isLoading}
        columns={gridColumns}
        disableSelectionOnClick={true}
        onSelectionModelChange={(itm: any) => setWinsChecked(itm)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel: any) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        componentsProps={{}}
        components={{
          Toolbar: EditToolbar
        }}
      />

      <FormBuilderComponent
        formTitle={'Add Wins'}
        schemaObj={formSchema}
        initialData={{}}
        uiSchema={formUiSchema}
        openDialog={showAdd}
        onClose={handleAddWinClose}
        onHandleSubmit={handleSubmitAdd}
      />

      <ConfirmModal
        handleConfirmation={handleSubmitMultiSelectDelete}
        toggleDeleteForm={showDeleteSelected}
        type={'Wins '}
        handleCloseModal={handleDeleteSelectedWinsClose}
      />
    </Card>
  );
}
export default Wins;
