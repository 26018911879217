import * as React from 'react';
import './dashboardWins.scss';
import 'hammerjs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT } from 'utils/utils';
import { Button } from '@mui/material';

type winsProps = {
  details: any;
};

function DashboardWins(props: winsProps) {
  const [projectWins, setProjectWins] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.details.id != null && props.details.id != '') {
      getProjectWins();
    }
  }, [props.details]);

  function getProjectWins() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'wins',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {

      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let groupWins = res.length > 0 ? res : [];
          let projWins: any = [];

          groupWins.forEach((group: any) => {
            projWins.push(...group.wins);
          });

          projWins = projWins.slice(0, 3);
          setProjectWins(projWins);
          setIsLoading(false);
        })
        .catch((err) => {
          setProjectWins([]);
          setIsLoading(false);
        });
    } else {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projWins: any = res.length > 0 ? res[0].wins : [];
          setIsLoading(false);
          projWins = projWins.slice(0, 3);
          setProjectWins(projWins);
        })
        .catch((err) => {
          setIsLoading(false);
          setProjectWins([]);
        });
    }
  }

  return (
    <div
      data-cy={'dashboard-wins-card'}
      className="card dashboard-wins-card"
    >
      <div data-cy="card-title" className="regionWinsHeader">
        <div style={{ alignItems: 'center' }} className="row">
          <span className="actions-main-header col-md-8">Wins</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/wins')} color="primary" >
              View All
            </Button>
          </span>
        </div>
      </div>

      <div className={`dashboard-wins-div ${projectWins.length === 0 && "dashboard-wins-empty"}`}>
        <BackDrop isLoading={isLoading} />
          {projectWins.length > 0 && (
            <div data-cy={'wins-div'} className="wins-div" style={{ fontSize: 12 }}>
              {projectWins?.map((x: any, index: number) => (
                <div key={'Wins' + index}>
                  <div className="row">
                    <div className="col-sm-1">
                      <FontAwesomeIcon
                        size="2x"
                        style={{ color: '#f2f2f2', marginTop: 7 }}
                        data-cy={x.id + '-circle-icon'}
                        icon={faCircle}
                      />
                    </div>
                    <div className="col-sm-11 clipped">
                      <span className="wins-sub-header">{x.Title}</span>
                      <br />
                      <span className="wins-main-header">{moment(x.Date).format('MMMM DD, yyyy')}</span>
                      <br />
                    </div>
                  </div>
                  <hr className="line-separator" hidden={index + 1 === projectWins?.length} />
                </div>
              ))}
            </div>
          )}
          {projectWins.length === 0 && <span>No wins available</span>}
      </div>

      <div data-cy="viewall" hidden>
        <input hidden={projectWins.length === 0} type="button" className="btn btn-sm btn-view-all-wins" value="View all"></input>
      </div>
    </div>
  );
}
export default DashboardWins;
