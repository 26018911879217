import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Config } from 'config/config';
import { fetchListDataArgsType, fetchResponseType, ProcessFormDataArgsType, fetchCurrentUsersArgsType } from 'types/CommonReducerType';
import apiCalls from 'utils/api_calls';

export const processFormData = createAsyncThunk('common/processFormData', async (props: ProcessFormDataArgsType, api) => {
  const { payLoad, endpoint, hubname, ProjectGuid, clientId, successEvent, errorEvent, apiMethod } = props;

  let signalRConnection: HubConnection | null = null;
  let clientIdString = ProjectGuid?  ProjectGuid+clientId : clientId;
  if (hubname && clientIdString) {
    signalRConnection = new HubConnectionBuilder()
      .withUrl(Config.api.endPoint, {
        headers: {
          'x-ms-hubname': hubname,
          'x-ms-client-principal-id': clientIdString
        },
        accessTokenFactory: () => Config.signalR.accessKey
      })
      .withAutomaticReconnect()
      .build();
  }

  const ApiCall = new Promise<string>((resolve, reject) => {
    
    if (signalRConnection && successEvent && errorEvent) {
      signalRConnection
        .start()
        .then(() => {
          apiMethod(Config.api.endPoint + endpoint, payLoad).catch((err) => {
            console.log('Error during api call', err);
            reject(err);
            signalRConnection?.stop();
          })
          if (signalRConnection) {
            signalRConnection.on(successEvent, (message: string) => {
              resolve(message);
              signalRConnection?.stop();
            });
            signalRConnection.on(errorEvent, (message: string) => {
              reject(message);
              signalRConnection?.stop();
            });
          }
        })
        .catch((err) => {
          console.log('Error during Signal R call', err);
          reject(err);
        });
    }else{
      apiMethod(Config.api.endPoint + endpoint, payLoad).catch((err) => {
        console.log('Error during api call', err);
        reject(err);
      }).then((res: any) => {
        if(res?.ResponseMessage){
          resolve(res.ResponseMessage);
        }else if(successEvent){
          resolve(successEvent);
        }
      });
    }
  });
  try {
    const response = await ApiCall;
    return response;
  } catch (error: any) {
    let message = error?.message ? error.message : 'Error occurred';
    return api.rejectWithValue(message);
  }
});

export const fetchListdata = createAsyncThunk('common/fetchListdata', async (props: fetchListDataArgsType, { rejectWithValue }) => {
  const { ItemName, type, customId, modiFyitemCallback, idenTityColumn } = props;

  let getProjectDetailsRequestBody: any = {
    ItemName,
    IsHeaderRequired: true
  };
  if (localStorage.getItem('selectedType') === 'project') {
    getProjectDetailsRequestBody['ProjectId'] = localStorage.getItem('selectedTypeId');
  } else {
    getProjectDetailsRequestBody['GroupId'] = localStorage.getItem('selectedTypeId');
  }
  try {
    let response = await apiCalls.create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody);
    return processListResponse(response, type, customId, modiFyitemCallback, idenTityColumn);
  } catch (error) {
    rejectWithValue(error);
  }
});

const processListResponse = (
  response: any,
  type: string,
  customId: string | undefined,
  modiFyitemCallback: fetchListDataArgsType['modiFyitemCallback'],
  idenTityColumn: string | undefined
) => {
  let payloadItems: any = [],
    projectGroups: any = [];
  let projectDetails = response[0];
  let idColumn = idenTityColumn || 'id';
  response.forEach((d: any) => {
    let data = { ...d };
    let project = {
      id: data.ProjectGuid,
      projectId: data.projectId,
      name: data.ProjectName,
      projectSiteId: data.projectSiteId,
      projectSiteName: data.projectSiteName,
      ProjectGuid: data.ProjectGuid
    };

    let itemsData = data[type] ? [...data[type]] : [];
    itemsData.forEach((item: any) => {
      let e = modiFyitemCallback ? modiFyitemCallback({ ...item }) : { ...item };
      e['ProjectId'] = data.projectId;
      e['ProjectName'] = data.ProjectName;
      e['ProjectGuid'] = data.ProjectGuid;
      e['ProjectSiteId'] = data.projectSiteId;
      if (customId) e[customId] = `${e[idColumn]}--${data.ProjectGuid}--${data.projectSiteId}`;
      payloadItems.push(e);
    });

    projectGroups.push(project);
  });
  let projectSummary = response[0]['projectSummary'];
  const isClosed = projectSummary.ProjectStatus === 'Closed' ? true : false;
  return {
    projectSummary,
    projectDetails,
    projectGroups,
    payloadItems,
    isClosed
  };
};

export const fetchCurrentUsers = createAsyncThunk(
  'common/fetchCurrentUsers',
  async (props: fetchCurrentUsersArgsType, { rejectWithValue }) => {
    const { projectId } = props;

    let queryProject = `projectId=${projectId}`;

    if (localStorage.getItem('selectedType') !== 'project') {
      queryProject = `groupId=${projectId}`;
    }

    let SEARCH_URI = Config.api.endPoint + 'InternalUsersForListing';

    try {
      let response = await apiCalls.getById(`${SEARCH_URI}`, `?${queryProject}`).then((items: any) => {
        items.responseData.forEach((item: any) => {
          item['Type'] = 'Internal';
        });

        var users = [] as any;

        users = users = users.concat(items.responseData).map((e: any) => {
          e['text'] = e['EmailAddress'];
          return e;
        });

        return users;
      });

      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchFeatureFlagForProjectGroup = createAsyncThunk(
  'common/fetchFeatureFlagForProjectGroup',
  async () => {
    const projectGroupFlagName = "pinnplat-projectgroups";
    var loggedInUserEmail: any = localStorage.getItem("UserEmail") != null ? localStorage.getItem("UserEmail") : "";
    var emailDomain = loggedInUserEmail?.split('@')[1];
    var data: any = await apiCalls.getAll(Config.api.endPoint + 'FeatureFlagsForProjectGroups?groupName=' + emailDomain + '&flagName=' + projectGroupFlagName)
    if (data.ResponseCode === 200 && data.ResponseData === 'True') {
      return true;
    } else {
      return false;
    }
  }
);

export const fetchFeatureFlagForFile = createAsyncThunk(
  'common/fetchFeatureFlagForFile',
  async () => {
    const flagName = "pinnplat-files";
    var loggedInUserEmail: any = localStorage.getItem("UserEmail") != null ? localStorage.getItem("UserEmail") : "";
    var emailDomain = loggedInUserEmail?.split('@')[1];
    var data: any = await apiCalls.getAll(Config.api.endPoint + 'FeatureFlagsForProjectGroups?groupName=' + emailDomain + '&flagName=' + flagName)
    if (data.ResponseCode === 200 && data.ResponseData === 'True') {
      return true;
    } else {
      return false;
    }
  }
);
