// project import
import NavItems from './other';
// import other from './other';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

// const menuItems: { items: NavItemType[] } = {
//   items: [other]
// };

// export default menuItems;

export const menuItems = () => {
  return {
    items: [NavItems()]
  };
}
export default menuItems;

