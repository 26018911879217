import apiFunc from 'utils/api_calls'
import { FileProps } from './file'
const { getAll , getById, create, update} = apiFunc;
export const enum ToastKind {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

export interface CommonReducerStateType {
    isLoading: boolean;
    projectGroups: any[];
    showToast: boolean;
    toastMsg: string;
    toastKind: ToastKind;
    projectSummary: any;
    projectDetails: any;
    isClosed: boolean;
    listItems: any[];
    projectChangeCounter: number;
    currentProjectName: string;
    currentUserList: any[];
    isProjectGroupEnabled: boolean;
    isNewFileUiEnabled: boolean;
}

export interface ToastInfo {
    toastKind: ToastKind,
    toastMsg: String,
    showToast: Boolean,
}

export interface RootState {
    common: CommonReducerStateType;
    file: FileProps;
}

export interface ProcessFormDataArgsType {
    payLoad: any;
    endpoint: string;
    hubname?: string;
    ProjectGuid?: string;
    clientId?: string;
    successEvent?: string;
    errorEvent?: string;
    apiMethod: typeof getAll | typeof getById | typeof create | typeof update;
}

export interface fetchListDataArgsType {
    ItemName: string;
    type: string;
    customId?: string;
    modiFyitemCallback?: (item: any)=>any;
    idenTityColumn?: string;
}

export interface fetchResponseType {
    projectSummary: any,
    projectDetails: any,
    projectGroups: any,
    isClosed: boolean
}

export interface fetchCurrentUsersArgsType {
    projectId: string
}

export interface GetProjectItemsListType {
    ProjectId: string
    GroupId: string
    ItemName: string
    IsHeaderRequired: boolean
    Limit?: number
    Offset?: number
    OrderBy?: string
    OrderByDirection?: "DESC" | "ASC"
}