import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

export const CustomSelectRendererControl = (props: ControlProps) => {
  return (
    <Grid
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      className={'jsonform-custom-container'}
      item
      xs={12}
      hidden={!props.visible}
    >
      <FormControl required={props.required} error={props.errors.length > 0} fullWidth>
        <InputLabel id={`${props.label}-label`}>{props.label}</InputLabel>
        <Select
          labelId={`${props.label}-label`}
          id={props.path}
          name={props.path}
          label={props.label}
          onChange={(e) => {
            props.handleChange(props.path, e.target.value);
          }}
          value={props.data || ''}
        >
          {props.schema &&
            props.schema?.enum &&
            props.schema?.enum.map((option: any, i: number) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}

          {props.schema &&
            props.schema?.oneOf &&
            props.schema?.oneOf.map((option: any, i: number) => (
              <MenuItem key={i} value={option['const']}>
                {option['title']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export const CustomSelectRendererControlTester: RankedTester = rankWith(
  1000,
  or(schemaMatches((schema) => schema['format'] === 'dropdown'))
);
export default withJsonFormsControlProps(CustomSelectRendererControl);
