import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { setTabTitle } from 'utils/tabTitle';

import apiCalls from 'utils/api_calls';
import { Config } from 'config/config';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { user } = useAuth0();
  const { drawerOpen, openItem } = menu;

  const navigate = useNavigate();

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const addActivityLog = (url?: string, external?: boolean) => {
    if (url && external && external === true) {
      apiCalls.create(
        Config.api.endPoint + 'ActivityLog',
        JSON.parse(
          JSON.stringify({
            email: user?.email,
            source: 'Platform',
            destination: url
          })
        )
      );
      window.open(url, '_blank');
    } else if (url) {
      const slugUrl = localStorage.getItem('selectedProjUrl') ?? 'project';
      const modifiedUrl = url.replace('project', slugUrl);
      navigate(`${modifiedUrl}`, { replace: true });
    }
  };

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname === '/') {
      setTabTitle('');
    }

    if (pathname === item.url) {
      let path = pathname.replace(/\//g, '');
      setTabTitle(path);
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = 'white';
  const iconSelectedColor = 'primary.main';

  return (
    <ListItemButton
      onClick={(e) => addActivityLog(item.url, item.external)}
      disabled={item.disabled}
      selected={isSelected}
      style={{ backgroundColor: '#191919' }}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          '&:hover': {
            // bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
            bgcolor: 'grey.500'
          },
          '&.Mui-selected': {
            // bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
            bgcolor: 'grey.500',
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              // bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
              bgcolor: 'grey.500'
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        })
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                // bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
                bgcolor: 'grey.500'
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                // bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                bgcolor: 'grey.500',
                '&:hover': {
                  // bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                  bgcolor: 'grey.500'
                }
              })
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
