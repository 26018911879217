import React from 'react';
import Card from '../../components/card/card';
import IssuesTable from './issuesTable/issuesTable';
import IssuesItemForm from './issuesForm/issuesForm';
import './issues.scss';
import { IssueProvider } from './context/issuesContext';

const Issues: React.FC = () => {
  // returns the actionitems html
  return (
    <IssueProvider>
      <Card id="issues-card" name={'Issues'}>
        <div data-cy="issues-card-row" className="row">
          <div data-cy="issues-card-col" className="col-sm-12" style={{ height: '73vh', width: '100%' }}>
            <IssuesTable />
            <IssuesItemForm />
          </div>
        </div>
      </Card>
    </IssueProvider>
  );
};

export default Issues;
