// types
import { createSlice } from '@reduxjs/toolkit';
import { FileProps } from 'types/file';

// initial state
const initialState: FileProps = {
  isGroup: false,
};

// ==============================|| SLICE - FILE ||============================== //

const file = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setIsGroup(state, action) {
      state.isGroup = action.payload;
    }
  }
});

export default file.reducer;

export const { setIsGroup } = file.actions;
