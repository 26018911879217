// project import
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import ComponentSnackbar from 'components/@extended/Snackbar';
import apiCalls from 'utils/api_calls';
import Loader from 'components/Loader';
import './kendo-theme-variables.css';
import Alert from 'components/Alert';
import 'app.scss';
import { Snackbar } from '@mui/material';
import { setToastInfo } from 'store/reducers/common/CommonReducer';
import { Config } from 'config/config';
import HeaderSnippet from 'HeaderSnippet';
import { useDispatch, useSelector } from 'store';
import { fetchFeatureFlagForFile, fetchFeatureFlagForProjectGroup } from 'store/reducers/common/actions';

const App: React.FC<{}> = () => {
  const { isLoading, error, loginWithRedirect, user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [accessToken, setAccessToken] = React.useState('');
  const dispatch = useDispatch();
  const location = useLocation();

  const showToast = useSelector((state: any) => state.common['showToast']);
  const toastKind = useSelector((state: any) => state.common['toastKind']);
  const toastMsg = useSelector((state: any) => state.common['toastMsg']);
  const closeAlert = () => {
    dispatch(
      setToastInfo({
        showToast: false,
        toastKind: 'error',
        toastMsg: ''
      })
    );
  };

  useEffect(()=>{
    dispatch(fetchFeatureFlagForProjectGroup());
    dispatch(fetchFeatureFlagForFile());
  },[]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await apiCalls.create(
          Config.api.endPoint + 'ActivityLog',
          JSON.parse(
            JSON.stringify({
              email: user?.email,
              source: 'Platform',
              route: location.pathname
            })
          )
        );
      }
    })();
  }, [location]);

  useEffect(() => {
    (async function login() {
      if (!isLoading && !isAuthenticated) {
        await loginWithRedirect({
          appState: {
            returnTo: '/'
          }
        });
      }
    })();

    

    const getAccessToken = async () => {
      // const domain = Config.auth0.domain;

      try {
        const authorizationParams = Config.auth0.authorizationParams;
        // Get user access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams
        });
        localStorage.setItem('UserAccessToken', accessToken);
        localStorage.setItem('UserName', user?.name!);
        localStorage.setItem('UserEmail', user?.email!);
        setAccessToken(accessToken);
      } catch (err: any) {
        console.log('Error while getting access token: ' + err.message);
      }
    };

    if (isAuthenticated) {
      getAccessToken();

      var id = window
        .setTimeout(() => {
          logout();
        }, Number(Config.maxIdleTimeToLogout.seconds) * 1000)
        .valueOf();
      while (id--) {
        window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [isLoading, getAccessTokenSilently, accessToken]);

  if (error) {
    console.log(error);
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ThemeCustomization>
      <HeaderSnippet/>
      <Locales>
        {isAuthenticated && accessToken && (
          <ScrollTop>
            {/* <AuthProvider> */}
            <>
              <Routes />
              <ComponentSnackbar />
              <Snackbar
                open={showToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                onClose={closeAlert}
              >
                <Alert onClose={closeAlert} severity={toastKind} sx={{ width: '100%' }}>
                  {toastMsg}
                </Alert>
              </Snackbar>
            </>
            {/* </AuthProvider> */}
          </ScrollTop>
        )}
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
