import { Config } from 'config/config';
import { Helmet } from 'react-helmet';
import favicon from './assets/images/pinnacle-icon-sm.png';


const HeaderSnippet: React.FC = () => {
  return (
    <Helmet>
      <meta
        name="description"
        content="The Pinnacle Platform makes the complexity of reliability management simple. Access reliability training, tools, analytics, and all project management resources in a single location."
      />
      <title>My Pinnacle Platform</title>
      <link rel="icon" type="image/png" href={favicon}  />
      <script type="text/javascript">
        {`(function() {
      var walkme = document.createElement('script'); 
      walkme.type = 'text/javascript'; 
      walkme.async = true; 
      walkme.src = '${Config.walkMeSrc}'; 
      var s = document.getElementsByTagName('script')[0]; 
      s.parentNode.insertBefore(walkme, s); 
      window._walkmeConfig = {smartLoad:true}; 
      })();`}
      </script>
    </Helmet>
  );
};

export default HeaderSnippet;
