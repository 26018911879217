import React from 'react';
import {
  deleteIssueAction,
  setToggleAddForm,
  setToggleDeleteForm
} from '../context/actions';
import apiCalls from 'utils/api_calls';
import { JsonSchema } from '@jsonforms/core';
import { issuesFormSchema, issuesFormUischema } from '../constants';
import ConfirmModal from 'components/ConfirmModal';
import useIssue from '../context/issuesContext';
import FormBuilderComponent from 'components/formBuilder/formBuilder';
import { RootState, useDispatch, useSelector } from 'store';
import { processFormData } from 'store/reducers/common/actions';

const IssuesItemForm: React.FC = () => {
  const [formSchema, setFormSchema] = React.useState<JsonSchema>(issuesFormSchema);
  const [formUiSchema, setFormUiSchema] = React.useState<any>(issuesFormUischema);

  const { state, dispatch } = useIssue();

  const reduxdispatch = useDispatch();
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);

  const { showAdd, showDelete } = state;

  const handleDeleteActionItemClose = () => {
    setToggleDeleteForm(dispatch, false);
  };

  const confirmdeleteAction = ()=>{
    handleDeleteActionItemClose();
    deleteIssueAction(reduxdispatch, state.selectedIds)
  }

  const handleSubmit = async (formData: any) => {
    setToggleAddForm(dispatch, false);
    const projectId = localStorage.getItem('selectedType') === 'group' ? formData.project : projectDetails.ProjectGuid;
    const { selectedUser, title, priority, status, dueDate } = formData;

    const payLoad = {
      id: '0',
      title,
      discussion: null,
      resolution: null,
      groupId: null,
      priority,
      status,
      dueDate,
      assignedToLookupId: selectedUser?.Id? (selectedUser?.Id).toString() : null,
      assignedTo: selectedUser?.Name || null,
      projectId
    };
    await reduxdispatch(
      processFormData({
        payLoad,
        endpoint: 'Issues',
        hubname: 'Issues',
        ProjectGuid: projectId,
        clientId: '-createissue-0',
        successEvent: 'CreatesIssuesUserSuccessMessage',
        errorEvent: 'IssuesUserErrorMessage',
        apiMethod: apiCalls.create
      })
    ).unwrap();
  };

  React.useEffect(() => {
    const isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let requredFields = formSchema['required']?.filter((item) => item !== 'project');
    let element = formUiSchema['elements']?.find((elm: any) => elm.scope === '#/properties/project');
    let projectFldIndex = formUiSchema['elements']?.findIndex((elm: any) => elm.scope === '#/properties/project');
    if (element) {
      element['rule']['effect'] = isprojectGroup ? 'SHOW' : 'HIDE';
      formUiSchema[projectFldIndex] = element;
      setFormUiSchema(formUiSchema);
    }

    if (isprojectGroup) {
      requredFields?.push('project');
    }
    formSchema['required'] = requredFields;
    setFormSchema(formSchema);
  }, [projectChangeCounter]);

  return (
    <>
      <FormBuilderComponent
        formTitle={'Add Issues'}
        schemaObj={formSchema}
        initialData={{}}
        uiSchema={formUiSchema}
        openDialog={showAdd}
        onClose={() => setToggleAddForm(dispatch, false)}
        onHandleSubmit={handleSubmit}
      />
      <ConfirmModal
        handleConfirmation={confirmdeleteAction}
        toggleDeleteForm={showDelete}
        type={'Issues'}
        handleCloseModal={handleDeleteActionItemClose}
      />
    </>
  );
};

export default IssuesItemForm;
