import { GridColumns, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import moment from 'moment';
import { JsonSchema } from '@jsonforms/core';
import EditUserDropdown from 'components/TableCellEditUserDropdown'
import EditPercentageCell from './actionItemTable/EditPercentageCell';
import dayjs from 'dayjs';

export const columns: GridColumns = [
  {
    field: 'Completed',
    headerName: 'Completed?',
    flex: 2,
    sortable: true,
    groupable: false
  },
  {
    field: 'Title',
    headerName: 'Name',
    width: 350,
    flex: 5,
    sortable: true,
    groupable: false,
    editable: true,
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'user',
    headerName: 'Assigned To',
    flex: 4,
    editable: true,
    sortable: true,
    valueFormatter: (params) => {
       return params?.value['AssignedTo']||''
    },
    preProcessEditCellProps(params) {
      const invalid = params.props.value !=="" ? false : true;
      params.props.error = invalid;
      params.props.className = invalid ? 'error' :'';
      return { ...params.props };
    },
    sortComparator: (v1, v2) => {
      let assignedToA = v1?.['AssignedTo'] || '';
      let assignedToB = v2?.['AssignedTo'] || '';
      return assignedToA.localeCompare(assignedToB)
    },
    renderEditCell: (params: GridRenderEditCellParams) => (
      <EditUserDropdown {...params} />
    )
  },
  {
    field: 'Due_x0020_Date',
    headerName: 'Due Date',
    type: 'date',
    editable: true,
    flex: 2,
    sortable: true,
    groupable: false,
    valueGetter: (params) => {
      const Due_x0020_Date = dayjs(params.value);
      return Due_x0020_Date.isValid()? Due_x0020_Date.toDate(): new Date();
    },
    valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'percentCompleted',
    headerName: '% Complete',
    width: 50,
    flex: 2,
    sortable: true,
    editable: true,
    groupable: false,
    type: 'number',
    renderEditCell: (params: GridRenderEditCellParams) => (
      <EditPercentageCell {...params}/>
    ),
    preProcessEditCellProps(params) {
      const invalid = isNaN(params.props.value) ? true : false;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'ProjectId',
    headerName: 'Project ID',
    flex: 2,
    sortable: true,
    groupable: false
  },
  {
    field: 'ProjectName',
    headerName: 'Project Name',
    flex: 2,
    sortable: true,
    groupable: false
  }
];

export const actionFormSchema: JsonSchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      format: 'input',
      maxLength: 100
    },
    percentComplete: {
      type: 'number',
      minimum: 0,
      maximum: 100,
      default: 0,
      format: 'rangeSlider',
    },
    selectedUser: {
      type: 'object',
      format: 'asyncSelect'
    },
    dueDate: {
      type: 'string',
      format: 'date'
    },
    project: {
      type: 'string',
      format: 'projectDropdown'
    }
  },
  required: ['title', 'percentComplete','selectedUser', 'dueDate']
};

export const actionFormUischema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/title',
      label: 'Name',
      options: {
        placeholder: 'Name'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/percentComplete',
      label: 'Percent complete',
      options: {
        symbol: '%'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/selectedUser',
      label: 'Assigned to',
      options: {
        placeholder: 'Email'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/dueDate',
      label: 'Due Date'
    },
    {
      type: 'Control',
      scope: '#/properties/project',
      label: 'Project:',
      rule: {
        effect: 'HIDE',
        condition: {}
      }
    }
  ]
};