import { Config } from "../config/config";
const { gtag } = require("ga-gtag");

export const setTabTitle = (path) => {    
    document.title = 'My Pinnacle Platform';    
    switch (path) {
        case 'users':
            document.title = "Users - " + document.title;
            break;
        case 'documents':
            document.title = "Documents - " + document.title;
            break;
        case 'projectdashboard':
            document.title = "Project Dashboard - " + document.title;
            break;
        case 'actionitems':
            document.title = "Action Items - " + document.title;
            break;
        case 'issues':
            document.title = "Issues - " + document.title;
            break;
        case 'risks':
            document.title = "Risks - " + document.title;
            break;
        case 'milestones':
            document.title = "Milestones - " + document.title;
            break;
        case 'decisions':
            document.title = "Decisions - " + document.title;
            break;
        case 'wins':
            document.title = "Wins - " + document.title;
            break;
        case 'projectgroups':
            document.title = "Project Groups - " + document.title;
            break;
        case 'not-found':
            document.title = "Not Found - " + document.title;
            break;
        default:
            break;
    }
    
    var options = {
        'page_title': document.title,
        'page_location': window.location.href,
        'page_path': window.location.pathname
    };
    gtag('config', Config.GoogleAnalytics.measurementId, options);
};