import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, TextField } from '@mui/material';

export const CustomInputRendererControl = (props: ControlProps) => {
  const type = typeof props.schema?.type === 'string' ? props.schema?.type : 'text';
  const onchange = (e: any) =>{
    let value = type==='number'? Number(e.target.value): e.target.value;
    props.handleChange(props.path, value)
  }
  return (
    <Grid
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      className={'jsonform-custom-container'}
      item
      xs={12}
      hidden={!props.visible}
    >
      <TextField
        fullWidth
        id={props.path}
        label={props.label}
        variant="outlined"
        name={props.path}
        type={type}
        placeholder={props.uischema?.options && props.uischema.options['placeholder']}
        InputProps={props.uischema?.options && props.uischema?.options['inputProps']}
        onChange={(e) => onchange(e)}
        error={props.errors.length > 0}
        value={props.data||''}
        required={props.required}
      />
    </Grid>
  );
}

export const CustomInputRendererControlTester: RankedTester = rankWith(1000, or(schemaMatches((schema) => schema['format'] === 'input')));
export default withJsonFormsControlProps(CustomInputRendererControl);
