import { GridColumns, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import EditUserDropdown from 'components/TableCellEditUserDropdown';
import { ImpactOptions as PriorityOptions, StatusOptions } from 'utils/utils'

export const columns: GridColumns = [
  {
    field: 'Title',
    headerName: 'Title',
    flex: 4,
    editable: true,
    sortable: true,
    valueParser(value, params?) {
      return value.length > 255 ? value.slice(0, 255) : value;
    },
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'user',
    headerName: 'Assigned To',
    flex: 3,
    editable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      return params?.value['AssignedTo'] || '';
    },
    sortComparator: (v1, v2) => {
      let assignedToA = v1?.['AssignedTo'] || '';
      let assignedToB = v2?.['AssignedTo'] || '';
      return assignedToA.localeCompare(assignedToB)
    },
    renderEditCell: (params: GridRenderEditCellParams) => <EditUserDropdown {...params} />
  },
  {
    field: 'Status',
    headerName: 'Status',
    flex: 2,
    editable: true,
    sortable: true,
    type: 'singleSelect',
    valueOptions: StatusOptions,
    sortComparator: (v1, v2) => {
      let value1 = v1.replace(/\(.+?\)/, "").trim();
      value1 = value1.charAt(0).toUpperCase() + value1.slice(1).toLowerCase();

      let value2= v2.replace(/\(.+?\)/, "").trim();
      value2 = value2.charAt(0).toUpperCase() + value2.slice(1).toLowerCase();
      return value1.localeCompare(value2)
    },
    renderCell: (params: GridRenderCellParams<any>) => {
      let value = params.value?.replace(/\(.+?\)/, "").trim();
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      return value;
    },
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'DueDate',
    headerName: 'Due Date',
    flex: 2,
    type: 'date',
    editable: true,
    sortable: true,
    valueGetter: (params) => {
      const dateVal = dayjs(params.value);
      return dateVal.isValid()? dateVal.toDate(): new Date();
    },
    valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'Risk_x0020_Impact',
    headerName: 'Risk Impact',
    flex: 2,
    editable: true,
    sortable: true,
    type: 'singleSelect',
    valueOptions: PriorityOptions,
    sortComparator: (v1, v2) => {
      let value1 = v1.replace(/\(.+?\)/, "").trim();
      value1 = value1.charAt(0).toUpperCase() + value1.slice(1).toLowerCase();

      let value2= v2.replace(/\(.+?\)/, "").trim();
      value2 = value2.charAt(0).toUpperCase() + value2.slice(1).toLowerCase();
      return value1.localeCompare(value2)
    },
    renderCell: (params: GridRenderCellParams<any>) => {
      let value = params.value?.replace(/\(.+?\)/, "").trim();
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      return value;
    },
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'Risk_x0020_Probability',
    headerName: 'Risk Probability',
    flex: 2,
    editable: true,
    sortable: true,
    type: 'singleSelect',
    valueOptions: PriorityOptions,
    sortComparator: (v1, v2) => {
      let value1 = v1.replace(/\(.+?\)/, "").trim();
      value1 = value1.charAt(0).toUpperCase() + value1.slice(1).toLowerCase();

      let value2= v2.replace(/\(.+?\)/, "").trim();
      value2 = value2.charAt(0).toUpperCase() + value2.slice(1).toLowerCase();
      return value1.localeCompare(value2)
    },
    renderCell: (params: GridRenderCellParams<any>) => {
      let value = params.value?.replace(/\(.+?\)/, "").trim();
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      return value;
    },
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'ProjectId',
    headerName: 'Project ID',
    flex: 1,
    editable: false,
    sortable: true
  },
  {
    field: 'ProjectName',
    headerName: 'Project Name',
    flex: 3,
    editable: false,
    sortable: true
  }
];

export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      format: 'input'
    },
    selectedUser: {
      type: 'object',
      format: 'asyncSelect'
    },
    dueDate: {
      type: 'string',
      format: 'date'
    },
    status: {
      type: 'string',
      oneOf: StatusOptions.map(opt=>{
        return {
          "const": opt.value,
          "title": opt.label
        }
      }),
      format: 'dropdown'
    },
    riskImpact: {
      type: 'string',
      oneOf: PriorityOptions.map(opt=>{
        return {
          "const": opt.value,
          "title": opt.label
        }
      }),
      format: 'dropdown'
    },
    riskProbability: {
      type: 'string',
      oneOf: PriorityOptions.map(opt=>{
        return {
          "const": opt.value,
          "title": opt.label
        }
      }),
      format: 'dropdown'
    },
    project: {
      type: 'string',
      format: 'projectDropdown'
    }
  },
  required: ['title', 'dueDate', 'status', 'riskImpact', 'riskProbability']
};

export const uischema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/title',
      label: 'Title',
      options: {
        placeholder: 'Title'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/selectedUser',
      label: 'Assigned to',
      options: {
        placeholder: 'Email'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/dueDate'
    },
    {
      type: 'Control',
      scope: '#/properties/status'
    },
    {
      type: 'Control',
      scope: '#/properties/riskImpact'
    },
    {
      type: 'Control',
      scope: '#/properties/riskProbability'
    },
    {
      type: 'Control',
      scope: '#/properties/project',
      label: 'Project:',
      rule: {
        effect: 'HIDE',
        condition: {}
      }
    }
  ]
};
