export const Config = {
    api: {
        endPoint: "https://dev-myplatformapi.pinnacletech.com/api/"
    },
    auth0: {
        domain: "dev-signin.pinnacleauth.com",
        clientId: "wnnNd5L5UsFSqUr3pCSq7pYMmuIaJvmL",
        authorizationParams: {
            redirect_uri: 'https://dev-myplatform.pinnacletech.com/callback',
            audience: "https://pinnacleplatform.dev",
            scope: "email profile"
        }
    },
    FileConfig:{
        restrictions:{
            maxTotalFileSize: "1073741824",
            maxNumberOfFiles: "30",
        },
        fileManagerSelectionType: "both"
    },
    walkMeSrc: "https://cdn.walkme.com/users/6ac8332db83a4ea4b2d93b7b4009ae06/development/walkme_6ac8332db83a4ea4b2d93b7b4009ae06_https.js",
    techSupport: {
        emailAddress: "support@pinnacletech.com"
    },
    projectQuestions: {
        toEmailAddress: "Platform@pinnaclereliability.com"
    },
    maxIdleTimeToLogout: {
        seconds: "43200"
    },
    signalR: {
        accessKey: "pzQXHZbsen0w3BkI3ftAJY7vZabAMw5IVd0Qtpad5YY="
    },
    GoogleAnalytics: {
        measurementId: "G-QWHJNS5K1B"
    },
    ZenDesk: {
        key: "10271455-5f8b-4aaa-8468-f96ae59dae41"
    },
    PinnacleUsersDomain : ["pinnacletech.com", "pinnaclereliability.com"],
    MUI: {
        licenseKey: "1b74c4c760060652cf0473609c64fe0eTz01MzUwMCxFPTE2OTg5MzExODA0MTksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    }
};
