import * as React from 'react';
import './dashboardMilestones.scss';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { StepperNav } from 'vertical-stepper-nav';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Color } from '@progress/kendo-drawing';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT, USDollar } from 'utils/utils';
import { Button } from '@mui/material';

type milestoneProps = {
  details: any;
};

function DashboardMileStones(props: milestoneProps) {
  const [value, setValue] = React.useState<number>(1);
  const [orientation, setOrientation] = React.useState<any>('vertical');
  const [projectMilestones, setProjectMilestones] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();

  const handleChange = (e: StepperChangeEvent) => {
    setValue(e.value);
  };

  const handleOrientationChange = (e: DropDownListChangeEvent) => {
    setOrientation(e.target.value);
  };

  React.useEffect(() => {
    if (props.details.id != null && props.details.id != '') {
      getProjectMilestones();
    }
  }, [props.details]);

  const showValueField = (customVal: number) => {
    if (isNaN(customVal)) customVal = 0;
    return USDollar.format(customVal);
  }

  function getProjectMilestones() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'milestones',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {

      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let groupMilestones = res.length > 0 ? res : [];
          let projMilestones: any = [];
          let milestones: any = [];

          groupMilestones.forEach((group: any) => {
            milestones.push(...group.milestones);
          });

          milestones.forEach((milestone: any) => {
            let milestoneColor = getMilestoneColor(milestone.finishdate, milestone.percentageCompleted);
            let temp = {
              stepContent: () => (
                <div style={{ marginTop: '-10px' }}>
                  <span className="milestones-main-header" style={{ fontSize: 12 }}>
                    {moment(milestone.finishDate).format('MMMM DD, YYYY')}
                  </span>
                  <br />
                  <span className="milestones-sub-header" style={{ fontSize: 12 }}>
                    {milestone.title.length > 35 ? milestone.title.substring(0, 35) + '...' : milestone.title}
                  </span>
                  <br />
                  <strong className="milestones-sub-header" style={{ fontWeight: 400 }} >
                    {showValueField(milestone?.customValue)}
                  </strong>
                </div>
              ),
              stepStatusCircleSize: 24,
              stepStateColor: milestoneColor
            };
            projMilestones.push(temp);
          });

          setProjectMilestones(projMilestones);
          setIsLoading(false);
        })
        .catch((err) => {
          setProjectMilestones([]);
          setIsLoading(false);
        });
    } else {

      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projMilestones: any = [];
          let milestones: any = res.length > 0 ? res[0].milestones : [];

          milestones.forEach((milestone: any) => {
            let milestoneColor = getMilestoneColor(milestone.finishdate, milestone.percentageCompleted);
            let temp = {
              stepContent: () => (
                <div style={{ marginTop: '-10px' }}>
                  <span className="milestones-main-header" style={{ fontSize: 12 }}>
                    {moment(milestone.finishDate).format('MMMM DD, YYYY')}
                  </span>
                  <br />
                  <span className="milestones-sub-header" style={{ fontSize: 12 }}>
                    {milestone.title.length > 35 ? milestone.title.substring(0, 35) + '...' : milestone.title}
                  </span>
                  <br />
                  <strong className="milestones-sub-header" style={{ fontWeight: 400 }} >
                    {showValueField(milestone?.customValue)}
                  </strong>
                </div>
              ),
              stepStatusCircleSize: 24,
              stepStateColor: milestoneColor
            };
            projMilestones.push(temp);
          });

          setProjectMilestones(projMilestones);
          setIsLoading(false);
        })
        .catch((err) => {
          setProjectMilestones([]);
          setIsLoading(false);
        });
    }
  }

  function getMilestoneColor(milestoneDate: any, percentageCompleted: string) {
    if (milestoneDate != null && milestoneDate != '' && percentageCompleted != null && percentageCompleted != '') {
      let dayDiff = moment().diff(milestoneDate, 'days');
      if (dayDiff <= 0) {
        return 'black';
      } else {
        if (Number(percentageCompleted) == 100) return 'green';
        else return 'red';
      }
    } else return 'grey';
  }

  return (
    <div data-cy={'dashboard-milestones-card'} className="card module dashboard-milestones-card">
      <div data-cy="card-title" className="regionMilestonesHeader">
        <div style={{ alignItems: 'center' }} className="row">
          <span className="actions-main-header col-md-8">Key Milestones</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/milestones')} color="primary" >
              View All
            </Button>
          </span>
        </div>
      </div>
      <div className="dashboard-milestones-div" style={{ justifyContent:  projectMilestones.length===0?'center':'left' }} >
        <BackDrop isLoading={isLoading} />
        {projectMilestones.length > 0 &&
          (<div className="dashboard-milestones-items"><StepperNav steps={projectMilestones} /></div>)}
        {projectMilestones.length === 0 && <span>No key milestones available</span>}
      </div>
    </div>
  );
}
export default DashboardMileStones;
