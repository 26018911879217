import { To } from 'history';
import React, { useState, useEffect, createContext } from 'react';

interface IApplicationContext {
  title?: string;
  homeUrl?: To;
}

const defaultState = {
  title: "Pinnacle Rules",
  homeUrl: "./"
};

const ApplicationContext = React.createContext<IApplicationContext>(defaultState);

export default ApplicationContext;