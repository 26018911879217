import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useBackdropStyles = makeStyles({
  backdrop: {
    position: 'absolute',
    color: '#fff',
    zIndex: 99999
  }
});

interface BackDropProps {
  isLoading: boolean;
  thickness?: number;
  invisible?: boolean;
}

const BackDrop: React.FC<BackDropProps> = ({ isLoading, thickness, invisible }) => {
  const backDropClasses = useBackdropStyles();
  let isInvisible = invisible || false;
  return (
    <Backdrop
      sx={{
        '&.MuiBackdrop-root': {
          backgroundColor: isInvisible ? 'transparent' : 'rgb(0 0 0 / 10%);'
        }
      }}
      className={backDropClasses.backdrop}
      open={isLoading}
    >
      <CircularProgress thickness={thickness || 4} />
    </Backdrop>
  );
};

export default BackDrop;
