import { GridColumns, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { materialRenderers } from '@jsonforms/material-renderers';
import { JsonSchema } from '@jsonforms/core';

import CustomInputRendererControl, { CustomInputRendererControlTester } from 'components/formBuilder/CustomRenderers/CustomInputRenderer';
import CustomTextareaRendererControl, { CustomTextareaRendererControlTester } from 'components/formBuilder/CustomRenderers/CustomTextareaRenderer';
import CustomSelectRendererControl, { CustomSelectRendererControlTester } from 'components/formBuilder/CustomRenderers/CustomSelectRenderer';
import CustomInputDateRendererControl, {
  CustomInputDateRendererControlTester
} from 'components/formBuilder/CustomRenderers/CustomInputDateRenderer';
import { ImpactOptions } from 'utils/utils'
import dayjs from 'dayjs';

const StatusOptions = ['Requested','Not Yet Requested', 'Approved', 'Rejected']

export const columns: GridColumns = [
  {
      field: 'Title',
      headerName: 'Title',
      flex: 4,
      editable: true,
      sortable: true,
      preProcessEditCellProps(params) {
          const invalid = params.props.value ? false : true;
          if(invalid){
              params.props.error = invalid; 
              params.props.className = 'error';
              return { ...params.props };
          }
          else{
              params.props.error = invalid; 
              params.props.className = '';
              return { ...params.props };
          }
      }
  },
  {
      field: 'Decision_x0020_Date',
      headerName: 'Decision Date',
      flex: 2,
      type: 'date',
      editable: true,
      sortable: true,
      valueGetter: (params) => {
        const dateVal = dayjs(params.value);
        return dateVal.isValid()? dateVal.toDate(): new Date();
      },
      valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
      preProcessEditCellProps(params) {
          const invalid = params.props.value ? false : true;
          if(invalid){
              params.props.error = invalid; 
              params.props.className = 'error';
              return { ...params.props };
          }
          else{
              params.props.error = invalid; 
              params.props.className = '';
              return { ...params.props };
          }
      }
  },
  {
      field: 'Approval',
      headerName: 'Approval Status',
      flex: 2,
      editable: true,
      sortable: true,
      type: "singleSelect",
      valueOptions: StatusOptions,
      valueParser(value, params?) {
          return value.length > 255 ? value.slice(0,255) : value;
      },
      preProcessEditCellProps(params) {
          const invalid = params.props.value ? false : true;
          if(invalid){
              params.props.error = invalid; 
              params.props.className = 'error';
              return { ...params.props };
          }
          else{
              params.props.error = invalid; 
              params.props.className = '';
              return { ...params.props };
          }
      }
  },
  {
      field: 'Description',
      headerName: 'Description',
      flex: 5,
      editable: true,
      sortable: true,
      valueParser(value, params?) {
          return value.length > 255 ? value.slice(0,255) : value;
      },
      preProcessEditCellProps(params) {
          const invalid = params.props.value ? false : true;
          if(invalid){
              params.props.error = invalid; 
              params.props.className = 'error';
              return { ...params.props };
          }
          else{
              params.props.error = invalid; 
              params.props.className = '';
              return { ...params.props };
          }
      }
  },
  {
      field: 'Impact',
      headerName: 'Impact',
      flex: 2,
      editable: true,
      sortable: true,
      type: "singleSelect",
      valueOptions: ImpactOptions,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params.value.replace(/\(.+?\)/, "");
      },
      preProcessEditCellProps(params) {
          const invalid = params.props.value ? false : true;
          if(invalid){
              params.props.error = invalid; 
              params.props.className = 'error';
              return { ...params.props };
          }
          else{
              params.props.error = invalid; 
              params.props.className = '';
              return { ...params.props };
          }
      }
  },
  {
      field: 'ProjectId',
      headerName: 'Project ID',
      flex: 1,
      editable: false,
      sortable: true
  },
  {
      field: 'ProjectName',
      headerName: 'Project Name',
      flex: 3,
      editable: false,
      sortable: true
  }
];

export const decisionsFormSchema: JsonSchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      format: 'input',
      maxLength: 100
    },
    decisionDate: {
      type: 'string',
      format: 'date'
    },
    approvalStatus: {
      type: 'string',
      enum: StatusOptions,
      format: 'dropdown'
    },
    impact: {
      type: 'string',
      oneOf: ImpactOptions.map((opt)=>{
        return {
          "const": opt.value,
          "title": opt.label
        }
      }),
      format: 'dropdown'
    },
    project: {
      type: 'string',
      format: 'projectDropdown',
    },
    description: {
      type: 'string',
      minLength: 1,
      format: 'textarea',
      maxLength: 255
    },
  },
  required: ['title', 'approvalStatus', 'impact', 'decisionDate', 'description']
};

export const decisionsFormUischema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/title',
      label: 'Title',
      options: {
        placeholder: 'Title'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/decisionDate',
      label: 'Decision Date'
    },
    {
      type: 'Control',
      scope: '#/properties/approvalStatus',
      label: 'Approval Status'
    },
    {
      type: 'Control',
      scope: '#/properties/impact',
      label: 'Impact'
    },
    {
      type: 'Control',
      scope: '#/properties/project',
      label: 'Project:',
      rule: {
        effect: 'HIDE',
        "condition": {}
      }
    },
    {
      type: 'Control',
      scope: '#/properties/description',
      label: 'Description',
      options: {
        placeholder: 'Description'
      }
    },
  ],
};

export const decisionFormCustomrenderers = [
  ...materialRenderers,
  // register custom renderers
  { renderer: CustomInputRendererControl, tester: CustomInputRendererControlTester },
  { renderer: CustomTextareaRendererControl, tester: CustomTextareaRendererControlTester },
  { renderer: CustomSelectRendererControl, tester: CustomSelectRendererControlTester },
  { renderer: CustomInputDateRendererControl, tester: CustomInputDateRendererControlTester },
];
