import * as React from 'react';
import './dashboardActionItems.scss';
import 'hammerjs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT } from 'utils/utils';
import { Button } from '@mui/material';

type actionitemsProps = {
  details: any;
};

function DashboardActionItems(props: actionitemsProps) {
  const [projectActionItems, setProjectActionItems] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.details.id != null && props.details.id != '') {
      getProjectActionItems();
    }
  }, [props.details]);

  function getProjectActionItems() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'actionitems',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let groupActionItems = res.length > 0 ? res : [];
          let projActionItems: any = [];
          groupActionItems.forEach((group: any) => {
            projActionItems.push(...group.actionitems);
          });
          projActionItems = projActionItems.slice(0, 3);
          setProjectActionItems(projActionItems);
          setIsLoading(false);
        })
        .catch((err) => {
          setProjectActionItems([]);
          setIsLoading(false);
        });
    } else {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projActionItems: any = res.length > 0 ? res[0].actionitems : [];
          projActionItems = projActionItems.slice(0, 3);
          setProjectActionItems(projActionItems);
          setIsLoading(false);
        })
        .catch((err) => {
          setProjectActionItems([]);
          setIsLoading(false);
        });
    }
  }

  return (
    <div data-cy={'dashboard-actionitems-card'} className="card dashboard-actionitems-card">
      <div data-cy="card-title" className="regionActionsHeader">
        <div style={{ alignItems: 'center' }} className="row">
          <span className="actions-main-header col-md-8">Action Items</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/actionitems')} color="primary">
              View All
            </Button>
          </span>
        </div>
      </div>

      <div className="dashboard-actionitems-div">
        <BackDrop isLoading={isLoading} />
        <>
          {projectActionItems.length > 0 && (
            <div data-cy={'actionitems-div'} className="actionitems-div">
              {projectActionItems?.map((x: any, index: number) => (
                <div key={'ActionItems' + index} className='action-item'>
                  <div className="row" >
                    <div className="col-sm-1">
                      <FontAwesomeIcon
                        size="2x"
                        style={{ color: '#f2f2f2', marginTop: 7 }}
                        data-cy={x.id + '-circle-icon'}
                        icon={faCircle}
                      />
                    </div>
                    <div className="col-sm-11 clipped">
                      <span className="actions-main-header">{x.Title}</span>
                      <br />
                      <span className="actions-sub-header">
                        {x.AssignedTo_User_Name != null && x.AssignedTo_User_Name != '' ? x.AssignedTo_User_Name + ' : ' : ''}{' '}
                        {x.Due_x0020_Date ? moment(x.Due_x0020_Date).format('MMMM DD, yyyy') : ''}
                      </span>
                      <br />
                    </div>
                  </div>
                  <hr className="line-separator" hidden={index + 1 === projectActionItems?.length} />
                </div>
              ))}
            </div>
          )}
          {projectActionItems.length === 0 && <span>No action items available</span>}
        </>
      </div>

      <div data-cy="viewall" hidden>
        <input
          hidden={projectActionItems.length == 0}
          type="button"
          className="btn btn-sm btn-view-all-actionitems"
          value="View all"
        ></input>
      </div>
    </div>
  );
}
export default DashboardActionItems;
