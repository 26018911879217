import React from 'react';
import './banner.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import PubSub from 'pubsub-js';
/* Top Banner Function */
function Banner() {
  const [bannerText, setBannerText] = React.useState('');
 
  React.useEffect(() => {
    main();
    var token = PubSub.subscribe('setBannerText', main);
    return () => { PubSub.unsubscribe(token); };
  }, [bannerText]
  );
  function main() {
    // Call Get Banner Text API to display the Banner text.
    apiCalls.getAll(Config.api.endPoint + 'Banner')
      .then(res => {
        var data: any = res;
        var bannerVal = data.BannerText;
        bannerVal = bannerVal.endsWith(', ?') ? bannerVal.replace(', ?', '?') : bannerVal;
        setBannerText(bannerVal);
      });
  }
  // returns the banner html
  return (
    <div data-cy="banner-media" className="media dashboard-background">
      <div data-cy="banner-caption" className="caption">
        <h2 data-cy="banner-caption-text">{bannerText}</h2>
      </div>
    </div>
  );
}
export default Banner;