export const ImpactOptions = ['High', 'Medium', 'Low'].map((val, i) => ({ value: `(${++i}) ${val}`, label: val }));
export const StatusOptions = ['Active', 'Postponed', 'Closed'].map((val, i) => ({ value: `(${++i}) ${val}`, label: val }));
export const groupColumnIds = ['ProjectId', 'ProjectName'];
export const getValidItemList = (listItem: any, tableColumns: any) => {
  if (listItem.length === 0) return listItem;
  const fields = tableColumns.map((column: { field: string }) => column.field);
  const listKeys = Object.keys(listItem[0]);
  let checkIfvalid = fields.every((v: string) => listKeys.includes(v));
  return checkIfvalid ? listItem : [];
};
export const SUPPORT_URL = 'https://support.pinnacletech.com/hc/en-us/categories/4425705217165-Pinnacle-Platform'
export const DASHBOARD_ITEM_LIMIT = 10;
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});