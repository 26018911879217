import React from 'react';
import {
  deleteActionItem,
  setToggleAddForm,
  setToggleDeleteForm,
} from '../context/actions';
import ActionItemsContext from '../context/reducer';
import apiCalls from '../../../utils/api_calls';
import { actionFormSchema, actionFormUischema } from '../constants';
import { useDispatch, useSelector } from 'store';
import { RootState } from 'types/CommonReducerType';
import FormBuilderComponent from 'components/formBuilder/formBuilder';
import { JsonSchema } from '@jsonforms/core';
import { processFormData } from 'store/reducers/common/actions';
import ConfirmModal from 'components/ConfirmModal';


const ActionItemForm = (): JSX.Element => {
  const { state, dispatch } = React.useContext<any>(ActionItemsContext);

  const [formSchema, setFormSchema] = React.useState<JsonSchema>(actionFormSchema);
  const [formUiSchema, setFormUiSchema] = React.useState<any>(actionFormUischema);
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);

  const {  showAdd, showDelete } = state;

  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);
  const reduxDispatch = useDispatch();

  React.useEffect(() => {
    const isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let requredFields = formSchema['required']?.filter((item) => item !== 'project');
    let element = formUiSchema['elements']?.find((elm: any) => elm.scope === '#/properties/project');
    let projectFldIndex = formUiSchema['elements']?.findIndex((elm: any) => elm.scope === '#/properties/project');
    if (element) {
      element['rule']['effect'] = isprojectGroup ? 'SHOW' : 'HIDE';
      formUiSchema[projectFldIndex] = element;
      setFormUiSchema(formUiSchema);
    }

    if (isprojectGroup) {
      requredFields?.push('project');
    }
    formSchema['required'] = requredFields;
    setFormSchema(formSchema);
  }, [projectChangeCounter]);

  const handleSubmitAdd = async (formDataObj: any) => {
    setToggleAddForm(dispatch, false)
    const selectedUser = formDataObj['selectedUser'];
    const projectId = localStorage.getItem('selectedType') === 'group' ? formDataObj.project : projectDetails.ProjectGuid
    const payLoad = {
        id: '0',
        title: formDataObj.title,
        dueDate: formDataObj.dueDate,
        type: selectedUser?.Type,
        siteId: localStorage.getItem('selectedType') === 'group' ? null : projectDetails.projectSiteId,
        assignedUserEmail: selectedUser?.EmailAddress || '',
        assignedUserId: selectedUser?.Id ? String(selectedUser?.Id) : '',
        assignedUserName: selectedUser?.Name || '',
        percentComplete: (Number(formDataObj.percentComplete) / 100).toString(),
        projectId
      }

      await reduxDispatch(
        processFormData({
          payLoad,
          endpoint: 'ActionItems',
          hubname: 'actionItemsManagement',
          ProjectGuid: projectId,
          clientId: '-addactions-0',
          successEvent: 'createActionItemsUserSuccessMessage',
          errorEvent: 'createActionItemsUserErrorMessage',
          apiMethod: apiCalls.create
        })
      )
  };

  const handleDeleteActionItemClose = () => {
    setToggleDeleteForm(dispatch, false);
  };
  const handleSubmitDelete = () => {
    handleDeleteActionItemClose();
    deleteActionItem(reduxDispatch, state.selectedIds);
  };

  return (
    <>
      <FormBuilderComponent
        formTitle={'Add Action Item'}
        schemaObj={formSchema}
        initialData={{
          percentComplete: 0
        }}
        uiSchema={formUiSchema}
        openDialog={showAdd}
        onClose={()=>setToggleAddForm(dispatch, false)}
        onHandleSubmit={handleSubmitAdd}
      />
       <ConfirmModal
        handleConfirmation={handleSubmitDelete}
        toggleDeleteForm={showDelete}
        type={'Action'}
        handleCloseModal={handleDeleteActionItemClose}
      />
    </>
  );
};

export default ActionItemForm;
