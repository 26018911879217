import React, { useEffect } from 'react';
import './projectGroups.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import Card from '../../components/card/card';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import MUICard from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { textTransform } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Modal, Row, Col, Toast, Overlay } from "react-bootstrap";

import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  AppBar, Toolbar, Stack, InputLabel, TextField
} from '@mui/material';
import { Form } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import PubSub from 'pubsub-js';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
      children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/// Projects select box.
  function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a: readonly any[], b: readonly any[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  
  function union(a: readonly string[], b: readonly string[]) {
    return [...a, ...not(b, a)];
  }



  /// collapse table
  function createData(
    groupId: string,
    name: string,
    projectCount: number,
    owner: string,
    createdOn: string,
    availableProjects: [{
      ID : string,
      NAME : string
    }],
    selectedProjects: [{
      ID : string,
      NAME : string
    }],
    allProjects: [{
      ID : string,
      NAME : string
    }]
  ) {
    return {
      groupId,
      name,
      projectCount,
      owner,
      createdOn,
      availableProjects,
      selectedProjects,
      allProjects
    };
  }


function ProjectGroups() {

    const [toastShowMessage, setToastShowMessage] = React.useState('');
    let [projects, setProjects] = React.useState([{
      ID: '',
      NAME: ''
    }]);
    const [userId, setUserId] = React.useState('');
    const [validated, setValidated] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorMessage, setNameErrorMessage] = React.useState("");
    const [projectsError, setProjectsError] = React.useState(false);
    const [showAdd, setShowAdd] = React.useState(false);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState<string[]>([]);
    const [toastShowSuccess, setToastShowSuccess] = React.useState(false);
    const [toastShowErr, setToastShowErr] = React.useState(false);
    const [toastShowSuccessMessage, setToastShowSuccessMessage] = React.useState('');
    const [toastShowErrMessage, setToastShowErrMessage] = React.useState('');
    const [rows, setRows] = React.useState<any[]>([]);
    const [selectedProjectsGroups, setSelectedProjectsGroups] = React.useState<any[]>([]);
    const [isPgChecked, setIsPgChecked] = React.useState<any []>([]);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [showUpdatePG, setShowUpdatePG] = React.useState(false);
    const [updatedPGName, setUpdatedPGName] = React.useState('');
    const [updatedPGId, setUpdatedPGId] = React.useState('');
    const [currGroupId, setCurrGroupId] = React.useState('');

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    React.useEffect(() => {
      main();
      }, []
    );

    const getUserProjectGroups = () => {
      apiCalls.getAll(Config.api.endPoint + 'Groups')
      .then(res => {
        var data: any = res;
        if(data.ResponseCode == 200) {
          var userGroups: any[] = [];
          userGroups = data.ResponseData;
          userGroups.sort(function (a: any, b: any) {
            if (a.ProjectGroupName < b.ProjectGroupName) { return -1; }
            if (a.ProjectGroupName > b.ProjectGroupName) { return 1; }
            return 0;
          });
          var groupDetails: any [] = [];
          userGroups.forEach(group => {
            var userProjects = projects;
            var groupProjectsIds = group.Projects.map(function (x: any) {return (x.ProjectId)});
            var availableProjects: any = userProjects.filter(x => !groupProjectsIds.includes(x.ID));
            var groupProjects = group.Projects.map(function (x: any) {return ({
              ID: x.ProjectId,
              NAME: x.ProjectName
            })});
            var allProjects : any = [...availableProjects, ...groupProjects];
            
            groupDetails.push(createData(group.ProjectGroupId, group.ProjectGroupName, group.Projects.length, group.CreatedBy, group.CreatedOn, availableProjects, groupProjects, allProjects));
          });
          setRows(groupDetails);
        }
      });
    }
    
    const main = () => {
      
      apiCalls.getAll(Config.api.endPoint + 'Users')
      .then(res => {
        var data: any = res;
        var userProjects: any[] = [];
        setUserId(data.id);
        if (data.projects != null && data.projects.length > 0) {
          data.projects.forEach((project: any) => {
            var prj = {
              ID: project.id,
              NAME: project.name
            }
            userProjects.push(prj);
          });

          userProjects.sort(function (a: any, b: any) {
            if (a.NAME < b.NAME) { return -1; }
            if (a.NAME > b.NAME) { return 1; }
            return 0;
          });

          setProjects(userProjects);

          apiCalls.getAll(Config.api.endPoint + 'Groups')
          .then(res => {
            var data: any = res;
            if(data.ResponseCode == 200) {
              var userGroups: any[] = [];
              userGroups = data.ResponseData;
              userGroups.sort(function (a: any, b: any) {
                if (a.ProjectGroupName < b.ProjectGroupName) { return -1; }
                if (a.ProjectGroupName > b.ProjectGroupName) { return 1; }
                return 0;
              });
              var groupDetails: any [] = [];
              userGroups.forEach(group => {
                // var userProjects = projects;
                var groupProjectsIds = group.Projects.map(function (x: any) {return (x.ProjectId)});
                var availableProjects: any = userProjects.filter(x => !groupProjectsIds.includes(x.ID));
                var groupProjects: any = group.Projects.map(function (x: any) {return ({
                  ID: x.ProjectId,
                  NAME: x.ProjectName
                })});
                var allProjects : any = [...availableProjects, ...groupProjects];
                groupDetails.push(createData(group.ProjectGroupId, group.ProjectGroupName, group.Projects.length, group.CreatedBy, group.CreatedOn, availableProjects, groupProjects, allProjects));
              });
              setRows(groupDetails);
            }
      });
        }
      });

    }

    function getStyles(name: string, personName: readonly string[], theme: Theme) {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }

    const addProjectGroup = () =>{
        setValidated(false);
        setShowAdd(true);
        setNameError(false);
        setProjectsError(false);
        setNameErrorMessage("");
        setPersonName([]);
    }

    const deleteProjectGroups = () => {
      if(isPgChecked.length > 0) {
        setOpenDelete(true);
      }
    }

    const handleDeleteProjectGroups = () => {
      setOpenDelete(false);
      if(isPgChecked.length > 0) {
        apiCalls.deleteAll(Config.api.endPoint + 'Groups',
            JSON.parse(JSON.stringify({groupId : isPgChecked.join(',')})))
            .then(async res => {
                setIsPgChecked([]);
                setSelectedProjectsGroups([]);
                getUserProjectGroups();
                await PubSub.publishSync('setHeaders');
                setToastShowSuccessMessage('Succesfully deleted project group(s).')
                setToastShowSuccess(true);
            })
            .catch(err => {
                setSelectedProjectsGroups([]);
                console.log("Error", err)
                setToastShowErrMessage('Error occured while deleting the project group(s).')
                setToastShowErr(true);
            });
      }
    }

    const handleAddPGClose = () => setShowAdd(false);

    const handleCloseDelete = () => setOpenDelete(false);

    const handleUpdatePGClose = () => setShowUpdatePG(false);

    const handleUpdatePGSubmit = () => {
      var validateNameError = false;
      const reg = new RegExp("^[a-zA-Z0-9 ]*$");
      if(reg.test(updatedPGName)){
        setNameError(false);
        setNameErrorMessage("");
        validateNameError = false;
      } else {
        setNameError(true);
        setNameErrorMessage("Invalid characters entered.");
        validateNameError = true;
      }
      if(validateNameError == false) {
        apiCalls.getAll(Config.api.endPoint + 'GetProjectAndGroupsNames',)
            .then(async (getNamesRes: any) => {
              var existingName: any = getNamesRes.ResponseData;
              existingName = existingName.filter((x:any) => x.Id.toLowerCase() != updatedPGId.toString().toLowerCase());
              if(existingName.length > 0 && existingName.filter((x:any) => x.Name.toLowerCase() == updatedPGName.toString().toLowerCase()).length > 0)
              {
                setNameError(true);
                if(existingName.filter((x:any) => x.Name.toLowerCase() == updatedPGName.toString().toLowerCase())[0].Type == "Group")
                  setNameErrorMessage("Project Group name already exists.");
                else
                  setNameErrorMessage("Project name already exists.");
              } else {
                setNameError(false);
                setNameErrorMessage("");
            apiCalls.update(Config.api.endPoint + 'Groups',
              JSON.parse(JSON.stringify({
                groupId: updatedPGId,
                groupName: updatedPGName
              })))
            .then(async (res) => {
                setShowUpdatePG(false);
                getUserProjectGroups();
                await PubSub.publishSync('setHeaders');
                setToastShowSuccessMessage('Succesfully updated project group.')
                setToastShowSuccess(true);
            })
            .catch(err => {
                console.log("Error", err)
                setShowUpdatePG(false);
                setToastShowErrMessage('Error occured while updating project group.')
                setToastShowErr(true);
            });
          }
        })
        .catch(err => {
          console.log("Error", err)
          setShowUpdatePG(false);
          setToastShowErrMessage('Error occured while updating the project group.')
          setToastShowErr(true);
      });
      }
    }

    const handlePGNameChange = ((event : any) => {
      setUpdatedPGName(event.target.value);
    });

    const handleSubmitAdd = (event: any) => {
      var validateNameError = false;
      var validateProjError = false;
      setNameErrorMessage("");

      const reg = new RegExp("^[a-zA-Z0-9 ]*$");
      
      setNameError(false);
      setProjectsError(false);
      const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
            event.stopPropagation();

            const formData = new FormData(event.target);
            const formDataObj = Object.fromEntries(formData.entries());

            if (!formDataObj.title) {
                setNameError(true);
                validateNameError = true;
            } else {
                setNameError(false);
                validateNameError = false;
            }

            if (!formDataObj.selectedProjects) {
              setProjectsError(true);
              validateProjError = true;
            } else {
              setProjectsError(false);
              validateProjError = false;
            }
            
        }
        setValidated(true);
        if (form.checkValidity()) {
          const formData = new FormData(event.target);
          const formDataObj = Object.fromEntries(formData.entries());

          var projSelected = formDataObj.selectedProjects ? formDataObj.selectedProjects.toString().split(',') : [];
          var projGroupName = formDataObj.title.toString().trim();
          if(projSelected.length > 0) {
            setProjectsError(false);
            validateProjError = false;
          } else {
            setProjectsError(true);
            validateProjError = true;
          }

          if(reg.test(projGroupName)){
            setNameError(false);
            setNameErrorMessage("");
            validateNameError = false;
          } else {
            setNameError(true);
            setNameErrorMessage("Invalid characters entered.");
            validateNameError = true;
          }

          if(validateNameError || validateProjError) {
            console.log("Missing required fields.");
          }
          else {
            //API call to create group.
            apiCalls.getAll(Config.api.endPoint + 'GetProjectAndGroupsNames',)
            .then(async (getNamesRes: any) => {
              var existingName: any = getNamesRes.ResponseData;
              if(existingName.length > 0 && existingName.filter((x:any) => x.Name.toLowerCase() == formDataObj.title.toString().toLowerCase()).length > 0)
              {
                if(existingName.filter((x:any) => x.Name.toLowerCase() == formDataObj.title.toString().toLowerCase())[0].Type == "Group")
                  setNameErrorMessage("Project Group name already exists.");
                else
                  setNameErrorMessage("Project name already exists.");

                setNameError(true);
                validateNameError = true;
              } else {

                setNameError(false);
                setNameErrorMessage("");
                validateNameError = false;

                apiCalls.create(Config.api.endPoint + 'Groups',
                JSON.parse(JSON.stringify({
                  projectId: formDataObj.selectedProjects.toString(),
                  groupName: formDataObj.title.toString()
                })))
                .then(async res => {
                    setShowAdd(false);
                    getUserProjectGroups();
                    await PubSub.publishSync('setHeaders');
                    setToastShowSuccessMessage('Succesfully created project group.')
                    setToastShowSuccess(true);
                })
                .catch(err => {
                    console.log("Error", err)
                    setShowAdd(false);
                    setToastShowErrMessage('Error occured while creating project group.')
                    setToastShowErr(true);
                });
              }
                
            })
            .catch(err => {
                console.log("Error", err)
                setShowAdd(false);
                setToastShowErrMessage('Error occured while creating the project group.')
                setToastShowErr(true);
            });

            
          }
        }
        
        event.preventDefault();
    }

    function CustomRow(props: { row: ReturnType<typeof createData> }) {

      const { row } = props;
      const [open, setOpen] = React.useState<any>(currGroupId != '' && row.groupId == currGroupId ? true : false);
      const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
      const [allProjects, setAllProjects] = React.useState<any []>(row.allProjects);
      const [checked, setChecked] = React.useState<readonly any[]>([]);
      const [left, setLeft] = React.useState<any[]>(row.availableProjects.map(x => x.NAME));
      const [right, setRight] = React.useState<any[]>(row.selectedProjects.map(x => x.NAME));
      const leftChecked = intersection(checked, left);
      const rightChecked = intersection(checked, right);

      const handleToggle = (value: any) => () => {
          const currentIndex = checked.indexOf(value);
          const newChecked = [...checked];
      
          if (currentIndex === -1) {
            newChecked.push(value);
          } else {
            newChecked.splice(currentIndex, 1);
          }
      
          setChecked(newChecked);
        };
      
        const numberOfChecked = (items: readonly any[]) =>
          intersection(checked, items).length;
      
        const handleToggleAll = (items: readonly any[]) => () => {
          if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
          } else {
            setChecked(union(checked, items));
          }
        };
      
        const handleCheckedRight = () => {
          var selectedProj : any[] = right.concat(leftChecked);
          setRight(right.concat(leftChecked));
          setLeft(not(left, leftChecked));
          setChecked(not(checked, leftChecked));
  
          var selectedProjectIds = allProjects.filter(x => selectedProj.includes(x.NAME)).map(m => m.ID);
          var reqBody = JSON.parse(JSON.stringify({
            "groupId": row.groupId,
            "groupName": row.name,
            "projectId": selectedProjectIds.join(',')
          }));
          apiCalls.update(Config.api.endPoint + 'UpdateGroupsInProjects', reqBody)
          .then(res => {
            setCurrGroupId(row.groupId);
            getUserProjectGroups();
          }).catch(err => {
            setToastShowErrMessage('Failed to update project group.');
            setToastShowErr(true);
          });
        };
      
        const handleCheckedLeft = () => {
          var selectedProj : any[] = not(right, rightChecked);
          if(selectedProj.length > 0){
            setLeft(left.concat(rightChecked));
            setRight(not(right, rightChecked));
            setChecked(not(checked, rightChecked));
    
            var selectedProjectIds = allProjects.filter(x => selectedProj.includes(x.NAME)).map(m => m.ID);
            var reqBody = JSON.parse(JSON.stringify({
              "groupId": row.groupId,
              "groupName": row.name,
              "projectId": selectedProjectIds.join(',')
            }));
            apiCalls.update(Config.api.endPoint + 'UpdateGroupsInProjects', reqBody)
            .then(res => {
              setCurrGroupId(row.groupId);
              getUserProjectGroups();
            }).catch(err => {
              setToastShowErrMessage('Failed to update project group.');
              setToastShowErr(true);
            });
          } else {
            setToastShowErrMessage('Minimum one project should be part of project group.')
            setToastShowErr(true);
          }
        };

        const handleUpdateProjectGroupName = (groupId : any, groupName : any) => {
          setNameError(false);
          setNameErrorMessage("");
          setUpdatedPGId(groupId);
          setUpdatedPGName(groupName);
          setShowUpdatePG(true);
        }

        const getSelectedProjectGroups = (isChecked: boolean, groupId: string) => {
          
          if (isChecked) {
            var newList = [...isPgChecked];
            newList.push(groupId);
            setIsPgChecked(newList);

            setSelectedProjectsGroups([
              ...selectedProjectsGroups,
              groupId
            ]);
          } else {
            // remove from list
            var newList = isPgChecked.filter(x => x != groupId);
            setIsPgChecked(newList);
            setSelectedProjectsGroups(
              selectedProjectsGroups.filter((pg) => pg !== groupId),
            );
          }
        }
  
        const getGroupProjects = (groupId: string) => {
          setOpen(!open);
        }
      
        const customList = (title: React.ReactNode, items: readonly any[]) => (
          <MUICard>
            <CardHeader
              sx={{ px: 2, py: 1 }}
              avatar={
                <Checkbox
                  onClick={handleToggleAll(items)}
                  checked={numberOfChecked(items) === items.length && items.length !== 0}
                  indeterminate={
                    numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                  }
                  disabled={items.length === 0}
                  inputProps={{
                    'aria-label': 'all items selected',
                  }}
                />
              }
              title={title}
              subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
              sx={{
                width: "100%",
                height: 250,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
              dense
              component="div"
              role="list"
            >
              {items.map((item: any) => {
                const labelId = `transfer-list-all-item-${item}-label`;
      
                return (
                  <ListItem
                    key={item}
                    role="listitem"
                    button
                    onClick={handleToggle(item)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(item) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={item} />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </MUICard>
        );
  
      return (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <Checkbox {...label} onChange={event => getSelectedProjectGroups(event.target.checked, row.groupId)}
              checked= {isPgChecked.findIndex(item => item === row.groupId) >= 0}/>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => getGroupProjects(row.groupId)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="center">{row.projectCount}</TableCell>
            <TableCell align="center">{row.owner}</TableCell>
            {/* <TableCell align="center">{row.createdOn}</TableCell> */}
            <TableCell align="center">
              <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                  onClick={() => handleUpdateProjectGroupName(row.groupId, row.name)}
                  title = "Edit"
              >
                  <FontAwesomeIcon data-cy='documents-download-icon' icon={faEdit} />
              </button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
  
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4} textAlign="center"><span>Available Projects</span></Grid>
                    <Grid item xs={2}><span></span></Grid>
                    <Grid item xs={4} textAlign="center"><span>Selected Projects</span></Grid>
                  </Grid>
                  
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>{customList('Select All', left)}</Grid>
                    <Grid item xs={2}>
                        <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>{customList('Select All', right)}</Grid>
                  </Grid>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }

    return (
        <Card id="projgroup-card" name={'Project Groups'}>

          <Snackbar open={toastShowSuccess}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              autoHideDuration={5000} onClose={() => setToastShowSuccess(false)}>
              <Alert onClose={() => setToastShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
                  {toastShowSuccessMessage}
              </Alert>
          </Snackbar>

          <Snackbar open={toastShowErr}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              autoHideDuration={10000} onClose={() => setToastShowErr(false)}>
              <Alert onClose={() => setToastShowErr(false)} severity="error" sx={{ width: '100%' }}>
                  {toastShowErrMessage}
              </Alert>
          </Snackbar>

          <div data-cy="projgroup-card-row" className="row">
                <div data-cy="projgroup-card-col" className="col-sm-12"  style={{width: "100%" }}>
                  <Button variant="text" onClick={addProjectGroup}>+ Add</Button>
                  <Button variant="text" onClick={deleteProjectGroups} disabled={selectedProjectsGroups.length == 0 || rows.length == 0}>- Delete</Button>
                  <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                          <TableHead>
                          <TableRow>
                              <TableCell />
                              <TableCell style={{textTransform: "none", fontWeight: 600}} align="center">Group Name</TableCell>
                              <TableCell style={{textTransform: "none", fontWeight: 600}} align="center">Associated Projects</TableCell>
                              <TableCell style={{textTransform: "none", fontWeight: 600}} align="center">Created By</TableCell>
                              <TableCell />
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          {rows.map((row) => (
                              <CustomRow key={row.name} row={row} />
                          ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
                </div>
            </div>
            
            <Dialog
                // fullScreen
                open={showAdd}
                onClose={handleAddPGClose}
                TransitionComponent={Transition}
                fullWidth={true}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmitAdd}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleAddPGClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Add Project Group
                            </Typography>
                            <Button autoFocus color="inherit" type="submit">
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={5} style={{ padding: 50 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="title"
                                label="Title"
                                variant="outlined"
                                name="title"
                                error={nameError}
                                helperText={nameErrorMessage}
                                type="text"
                                placeholder="Title"
                                autoFocus
                                required
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-chip-label">Projects *</InputLabel>
                            <Select
                              id="selectedProjects"
                              label="selectedProjects"
                              name="selectedProjects"
                              multiple
                              value={personName}
                              onChange={handleChange}
                              error={projectsError}
                              input={<OutlinedInput id="select-multiple-chip" label="Projects" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={projects.filter(x => x.ID == value)[0].NAME} label={projects.filter(x => x.ID == value)[0].NAME} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {projects.map((proj) => (
                                <MenuItem
                                  key={proj.ID}
                                  value={proj.ID}
                                  style={getStyles(proj.NAME, personName, theme)}
                                >
                                  <ListItemText primary={proj.NAME} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                    </Grid>
                </Form>
            </Dialog>

            <Dialog
              open={openDelete}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDelete}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Delete Project Groups"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete selected project groups?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color='error' autoFocus onClick={handleDeleteProjectGroups}>
                        Delete
                    </Button>
                    <Button variant="outlined" color='info' autoFocus onClick={handleCloseDelete}>
                        Close
                    </Button>
              </DialogActions>
            </Dialog>

            <Modal show={showUpdatePG} onHide={handleUpdatePGClose} style={{ fontSize: 11 }}>
              <Form noValidate validated={validated}>
                  <Modal.Header closeButton>
                      <Modal.Title style={{ fontSize: 15 }}>Update Project Group</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form.Group as={Row} className="mb-3" controlId="file.name">
                          <Col sm={12}>
                              <TextField
                                fullWidth
                                id="GroupName"
                                label="Group Name"
                                variant="outlined"
                                name="GroupName"
                                error={nameError}
                                value={updatedPGName}
                                onChange={handlePGNameChange}
                                helperText={nameErrorMessage}
                                type="text"
                                placeholder="Group Name"
                                autoFocus
                                required
                                inputProps={{ maxLength: 100 }}
                              />
                          </Col>
                      </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                      <Button name="save" onClick={handleUpdatePGSubmit} disabled={updatedPGName == null || updatedPGName == ""} style={{ color: "white", backgroundColor: "#70c8b6", fontSize: 12 }}>
                          Save
                      </Button>
                      <Button style={{ fontSize: 12 }} onClick={handleUpdatePGClose}>
                          Cancel
                      </Button>
                  </Modal.Footer>
              </Form>
            </Modal>
        </Card>


    );
}
export default ProjectGroups;