import * as React from 'react';
import './dashboardIssues.scss';
import { Chart, ChartTooltip, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels } from '@progress/kendo-react-charts';
import 'hammerjs';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT } from 'utils/utils';
import { Button } from '@mui/material';

type milestoneProps = {
  details: any;
};
const defaultIssueChartData = [
  {
    category: 'High',
    value: 0,
    color: '#DC4554'
  },
  {
    category: 'Medium',
    value: 0,
    color: '#8EC051'
  },
  {
    category: 'Low',
    value: 0,
    color: '#6B9040'
  }
];

function DashboardIssues(props: milestoneProps) {
  const [allProjectIssues, setAllProjectIssues] = React.useState<any>([]);
  const [issues, setIssues] = React.useState<any>([]);
  const navigate = useNavigate();
  const [chartData, setChartData] = React.useState<any>(defaultIssueChartData);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (props.details.id != null && props.details.id !== '') {
      getProjectIssues();
    }
  }, [props.details]);

  function getProjectIssues() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'issues',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          setIsLoading(false);

          let groupIssues = res.length > 0 ? res : [];

          let projIssues: any = [];

          groupIssues.forEach((group: any) => {
            projIssues.push(...group.issues);
          });
          setAllProjectIssues(projIssues);

          projIssues = projIssues.filter((e: any) => e.Status.toUpperCase().includes('ACTIVE'));

          projIssues = projIssues.sort((a: any, b: any) => {
            if (a.Priority === b.Priority) {
              return a.Title > b.Title ? 1 : -1;
            }
            return a.Priority > b.Priority ? 1 : -1;
          });

          let highIssues = projIssues.filter((e: any) => e.Priority.toUpperCase().includes('HIGH'));
          let mediumIssues = projIssues.filter((e: any) => e.Priority.toUpperCase().includes('MEDIUM'));
          let lowIssues = projIssues.filter((e: any) => e.Priority.toUpperCase().includes('LOW'));
          let issueList: any = [];

          projIssues = projIssues.slice(0, 4).map((issue: any) => {
            return {
              IssueMode: getIssueMode(issue.Priority),
              IssueTitle: issue.Title.length > 40 ? issue.Title.substring(0, 40) + '...' : issue.Title,
              IssueDueDate: issue.DueDate !== '' && issue.DueDate != null ? moment(issue.DueDate).format('MMMM DD, YYYY') : ''
            };
          });
          setIssues(projIssues);

          issueList.push({
            category: 'High (' + highIssues.length + ')',
            value: highIssues.length,
            color: '#ed1e35'
          });

          issueList.push({
            category: 'Medium (' + mediumIssues.length + ')',
            value: mediumIssues.length,
            color: '#fff333'
          });

          issueList.push({
            category: 'Low (' + lowIssues.length + ')',
            value: lowIssues.length,
            color: '#6fbf44'
          });

          setChartData(issueList);
        })
        .catch((err) => {
          setIssues([]);
          setAllProjectIssues([]);
          setChartData(defaultIssueChartData);
          setIsLoading(false);
        });
    } else {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projIssues: any = res.length > 0 ? res[0].issues : [];
          setAllProjectIssues(projIssues);
          setIsLoading(false);

          projIssues = projIssues.filter((e: any) => e.Status.toUpperCase().includes('ACTIVE'));

          projIssues = projIssues.sort((a: any, b: any) => {
            if (a.Priority === b.Priority) {
              return a.Title > b.Title ? 1 : -1;
            }
            return a.Priority > b.Priority ? 1 : -1;
          });
          let highIssues = projIssues.filter((e: any) => e.Priority.toUpperCase().includes('HIGH'));
          let mediumIssues = projIssues.filter((e: any) => e.Priority.toUpperCase().includes('MEDIUM'));
          let lowIssues = projIssues.filter((e: any) => e.Priority.toUpperCase().includes('LOW'));
          let issueList: any = [];

          projIssues = projIssues.slice(0, 4).map((issue: any) => {
            return {
              IssueMode: getIssueMode(issue.Priority),
              IssueTitle: issue.Title.length > 40 ? issue.Title.substring(0, 40) + '...' : issue.Title,
              IssueDueDate: issue.DueDate !== '' && issue.DueDate != null ? moment(issue.DueDate).format('MMMM DD, YYYY') : ''
            };
          });
          setIssues(projIssues);

          issueList.push({
            category: 'High (' + highIssues.length + ')',
            value: highIssues.length,
            color: '#ed1e35'
          });

          issueList.push({
            category: 'Medium (' + mediumIssues.length + ')',
            value: mediumIssues.length,
            color: '#fff333'
          });

          issueList.push({
            category: 'Low (' + lowIssues.length + ')',
            value: lowIssues.length,
            color: '#6fbf44'
          });

          setChartData(issueList);
        })
        .catch((err) => {
          setIsLoading(false);
          setIssues([]);
          setAllProjectIssues([]);
          setChartData(defaultIssueChartData);
        });
    }
  }

  function getIssueMode(Priority: string) {
    if (Priority.toUpperCase().includes('LOW')) {
      return 'Low_Impact';
    } else if (Priority.toUpperCase().includes('MEDIUM')) {
      return 'Medium_Impact';
    } else if (Priority.toUpperCase().includes('HIGH')) {
      return 'High_Impact';
    }
  }

  const renderTooltip = (context: any) => {
    const { category, series, value } = context.point || context;
    return <div>{category}</div>;
  };

  return (
    <div data-cy={'dashboard-issues-card'} className="card module dashboard-issues-card">
      <div data-cy="card-title" className="regionIssuesHeader">
        <div style={{ alignItems: 'center' }} className="row">
          <span className="actions-main-header col-md-8">Project Issues</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/issues')} color="primary" >
              View All
            </Button>
          </span>
        </div>
      </div>

      <div className="dashboard-issues-div" >
        <BackDrop isLoading={isLoading} />
        {!isLoading && allProjectIssues.length === 0 && <span style={{ marginLeft: '30px', marginTop: '20px' }}>No Issues available</span>}
        {!isLoading && allProjectIssues.length !== 0 && issues.length === 0 && (
          <span style={{ marginLeft: '30px', marginTop: '20px' }}>No issues are active</span>
        )}
        <div>
          {!isLoading && issues.length > 0 && (
            <div className="row">
              <div data-cy="main-card-3-col-12" className="col-md-12">
                <Chart data-cy="issues-chart" style={{ height: '175px' }}>
                  <ChartTooltip render={renderTooltip} />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="donut"
                      data={chartData}
                      // autoFit={true}
                      size={30}
                      holeSize={40}
                    >
                      <ChartSeriesLabels color="#fff" background="none" />
                    </ChartSeriesItem>
                  </ChartSeries>
                  <ChartLegend visible={false} />
                </Chart>
              </div>

              <div className="dashboard-issuesrecords-div">
                {issues.length > 0 &&
                  issues.map((issue: any, index: number) => {
                    return (
                      <div key={'Risk' + index} className="row">
                        <div className="col-2">
                          <span className={issue.IssueMode + ' ' + 'indicator issues-main-header'}></span>
                        </div>
                        <div className="col-9">
                          <span className="issues-sub-header" style={{ fontSize: '12px' }}>
                            {issue.IssueTitle}
                          </span>
                          <p className="issues-sub-header" style={{ fontSize: '12px' }}>
                            {issue.IssueDueDate}
                          </p>
                          <hr className="line-separator" hidden={index + 1 === issues?.length} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div data-cy="viewall" hidden>
        <input hidden={issues.length === 0} type="button" className="btn btn-sm btn-view-all-issues" value="View all"></input>
      </div>
    </div>
  );
}
export default DashboardIssues;
