import AsyncSelectRendererControl from './AsyncSelectRenderer';
import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box, Grid, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'store';
import { RootState } from 'types/CommonReducerType';

function extendedUserSelectControl(Component: any) {
  return function WihApiCall(props: ControlProps) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = React.useState<any>([]);
    const currentUserList = useSelector((state: RootState) => state.common['currentUserList']);
    
    React.useEffect(() => {
      handleSearch("");
    }, []);
  
    const handleSearch = (query: string) => {
      setIsLoading(true);
      let users = currentUserList.filter((user) => {
        return user?.EmailAddress.toLowerCase().startsWith(query.toLowerCase());
      });
      setOptions(users);
      setIsLoading(false);
    };

    return (
      <Component
        {...props}
        isLoading={isLoading}
        options={options}
        handleSearch={handleSearch}
        renderOption={(props: any, option: any) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <PersonIcon/>
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography variant="body2" color="text.secondary">
                    {option['Name']}
                  </Typography>
                  <Box>{option.text}</Box>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
}

export default withJsonFormsControlProps(extendedUserSelectControl(AsyncSelectRendererControl));
