import {
  GridEventListener,
  GridRowModel,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  MuiEvent
} from '@mui/x-data-grid-pro';
import MUIDataGridFunctions from 'components/MUIGrid/MUIGrid';
import React from 'react';
import { columns } from '../constants';
import { getValidItemList, groupColumnIds } from 'utils/utils';
import TableToolbar from 'components/TableToolbar';
import {
  setSelectedGridRowIds,
  setToggleAddForm,
  setToggleDeleteForm,
  deleteIssueAction
} from '../context/actions';
import useIssue from '../context/issuesContext';
import apiCalls from 'utils/api_calls';
import { RootState, useDispatch, useSelector } from 'store';
import { fetchListdata, processFormData } from 'store/reducers/common/actions';

const IssuesTable: React.FC = () => {
  const { state, dispatch } = useIssue();
  const reduxDispatch = useDispatch()
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [gridColumns, setGridColumns] = React.useState(columns);

  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const issues = getValidItemList(useSelector((state: RootState) => state.common['listItems']), columns);

  // Exclude project group columns
  React.useEffect(() => {
    let isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let modifiedColumns = columns.filter((column) => {
      if (!isprojectGroup) {
        return !groupColumnIds.includes(column.field);
      }
      return true;
    });
    setGridColumns(modifiedColumns);
  }, [projectChangeCounter]);


  function EditToolbar() {
    return (
      <TableToolbar
        onAddClick={() => setToggleAddForm(dispatch, true)}
        onDeleteClick={onDeleteClick}
        isDeleteDisabled={state.selectedIds.length === 0}
        isExportDisabled={issues.length === 0}
        toolbarName="Issues"
      />
    );
  }

  const onDeleteClick = () => {
    if (state.selectedIds.length > 1) setToggleDeleteForm(dispatch, true);
    else {
      deleteIssueAction(reduxDispatch, state.selectedIds);
    }
  };

  const modiFyitemCallback = (item: any) => {
    item['user'] = {
      AssignedTo: item['AssignedTo'],
      AssignedToLookupId: item['AssignedToLookupId']
    };
    return {...item};
  }

  React.useEffect(() => {
    async function main() {
      await reduxDispatch(
        fetchListdata({
          ItemName: 'issues',
          type: 'issues',
          modiFyitemCallback: modiFyitemCallback,
          customId: "issueId",
          idenTityColumn: "Id"
        })
      ).unwrap();
    }
    main();
  }, [projectChangeCounter]);

  const handleSubmitUpdate = async (formData: any) => {
    const { user, ProjectGuid } = formData;
    let payLoad = {
      id: formData.Id,
      title: formData.Title,
      dueDate: formData.DueDate,
      priority: formData['Priority'],
      discussion: null,
      resolution: null,
      status: formData['Status'],
      assignedTo: user?.AssignedTo || null,
      assignedToLookupId: user?.AssignedToLookupId? (user?.AssignedToLookupId).toString() : null,
      projectId: ProjectGuid,
      groupId: null,
    };
    reduxDispatch(
      processFormData({
        payLoad,
        endpoint: 'Issues',
        hubname: 'Issues',
        ProjectGuid,
        clientId: '-updateissue-0',
        successEvent: 'updateIssueUserSuccessMessage',
        errorEvent: 'IssuesUserErrorMessage',
        apiMethod: apiCalls.update
      })
    );
  };

  const handleRowEditStart = (_params: GridRowParams, event: MuiEvent) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow.id !== 0) {
      handleSubmitUpdate(updatedRow);
    }

    return updatedRow;
  };

  return (
    <MUIDataGridFunctions.MUIDataGrid
      rows={issues}
      disableSelectionOnClick
      rowIdColum={'issueId'}
      columns={gridColumns}
      onSelectionModelChange={(itm: any) => setSelectedGridRowIds(dispatch, itm)}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(newModel: any) => setRowModesModel(newModel)}
      loading={isLoading}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      componentsProps={{
        toolbar: {}
      }}
      components={{
        Toolbar: EditToolbar
      }}
    />
  );
};

export default IssuesTable;
