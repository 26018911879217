import { TextField, CircularProgress, Autocomplete } from '@mui/material';
import React from 'react';
import _debounce from 'lodash/debounce'

interface CustomAsynchTypeHeadProps {
  required: boolean;
  errors: any;
  handleChange: (value: string) => void;
  data: any;
  isLoading: boolean;
  options: any[];
  handleSearch: (search: string) => void;
  label: string;
  placeholder: string;
  style?: any;
  isMultiple?: boolean;
  renderOption?: (props: any, option: any) => React.ReactElement<any>;
}

const CustomAsynchTypeHead: React.FC<CustomAsynchTypeHeadProps> = (props) => {
  const defaultVal = props?.data || props.isMultiple?[]:{};
  const [currentData, setCurrentData] = React.useState(defaultVal);
  const [open, setOpen] = React.useState(false);
  const { options, isLoading, handleSearch } = props;
  const debouncedRunSearchQuery = _debounce((e: any, query: string)=>{
    handleSearch(query);
  }, 0); // As we are not hitting api, setting thgis to 0

  React.useEffect(()=>{
    return ()=>{
      debouncedRunSearchQuery.cancel();
    }
  },[])

  return (
    <Autocomplete
      open={open}
      style={props.style ?? props.style}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.text || ""}
      multiple={props.isMultiple || false}
      options={options}
      loading={isLoading}
      onChange={(_event: any, newValue: any) => {
        let val = newValue || {}
        props.handleChange(val);
        setCurrentData(val);
      }}
      onInputChange={debouncedRunSearchQuery}
      value={currentData}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant='outlined'
            label={props.label}
            placeholder={props.placeholder}
            error={props.errors.length>0}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )
      }}
      renderOption={props?.renderOption}
    />
  );
};

export default CustomAsynchTypeHead;
