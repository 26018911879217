import React from 'react';
import './team.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import Card from '../../components/card/card';
import Button from '@mui/material/Button';
import MUIDataGridFunctions from '../../components/MUIGrid/MUIGrid';
import {
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridRowParams,
  MuiEvent
} from '@mui/x-data-grid-premium';
import { columns, validationSchema } from './constants';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import TableToolbar from 'components/TableToolbar';
import ConfirmModal from 'components/ConfirmModal';
import { RootState, useDispatch, useSelector } from 'store';
import { fetchListdata, processFormData } from 'store/reducers/common/actions';
import { getValidItemList } from 'utils/utils';
import { setListItems, setLoader } from 'store/reducers/common/CommonReducer';
import Transition from 'components/ModalTransition';

/* Top Team Function */
function TeamList() {
  const [selectdIds, setSelectedGridRowIds] = React.useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [show, setShow] = React.useState(false);
  const [isPGSelected, setIsPGSelected] = React.useState(false);

  const handleClose = () => {
    formik.values.email = '';
    formik.values.name = '';
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [showDelete, setShowDelete] = React.useState(false);

  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const users = getValidItemList(
    useSelector((state: RootState) => state.common['listItems']),
    columns
  );
  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const isClosed = useSelector((state: RootState) => state.common['isClosed']);
  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);

  const reduxdispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit(values) {
      handleTeamSubmit(values);
    }
  });

  React.useEffect(() => {
    main();
  }, [projectChangeCounter]);

  const modiFyitemCallback = (item: any) => {
    item['id'] = String(Math.floor(Math.random() * 10000));
    let roles = item.roles.map((r: { name: string }) => (r.name === 'Enterprise Resource' ? 'Resource' : 'Stakeholder'));
    item.roles = roles.length ? [...new Set(roles)].join() : 'Stakeholder';
    return item;
  };

  function main() {
    const isPg = localStorage.getItem('selectedType') === 'group';
    setIsPGSelected(isPg);
    if (isPg) {
      reduxdispatch(setLoader(true));
      apiCalls
        .getAll(Config.api.endPoint + 'ProjectGroupsUsers?groupId=' + localStorage.getItem('selectedTypeId'))
        .then(async (usersRes: any) => {
          var users = usersRes.ResponseData;
          var members = [] as any;
          users.forEach((user: any) => {
            var projectMembers = {
              name: user.name,
              emailAddress: user.userId,
              roles: 'Stakeholder',
              id: String(Math.floor(Math.random() * 10000))
            };
            members.push(projectMembers);
          });
          reduxdispatch(setLoader(false));
          reduxdispatch(setListItems(members));
        });
    } else {
      reduxdispatch(
        fetchListdata({
          ItemName: 'projectMembers',
          type: 'projectMembers',
          customId: 'id',
          modiFyitemCallback: modiFyitemCallback
        })
      );
    }
  }

  const handleTeamSubmit = (formDataObj: any) => {
    let email = formDataObj.email.toString().toLowerCase();
    let name = formDataObj.name;
    const projectId = localStorage.getItem('selectedTypeId');
    handleClose();

    if (isPGSelected) {
      reduxdispatch(
        processFormData({
          payLoad: {
            users: email,
            groupId: projectId,
            userName: name
          },
          endpoint: 'UpsertUsersGroups',
          ProjectGuid: '',
          apiMethod: apiCalls.update
        })
      );
    } else {
      reduxdispatch(
        processFormData({
          payLoad: {
            projectId: projectDetails.ProjectGuid,
            project: projectDetails.projectSiteName,
            name,
            email
          },
          endpoint: 'Users',
          hubname: 'userManagement',
          ProjectGuid: projectDetails.ProjectGuid,
          clientId: '-' + email.toString().toLowerCase(),
          successEvent: 'createProjectUserSuccessMessage',
          errorEvent: 'createUserErrorMessage',
          apiMethod: apiCalls.create
        })
      );
    }
  };

  const handleUpdateTeamSubmit = (userData: any) => {
    if (isPGSelected) {
      reduxdispatch(
        processFormData({
          payLoad: {
            users: userData['emailAddress'],
            groupId: localStorage.getItem('selectedTypeId'),
            userName: userData['name']
          },
          endpoint: 'UpsertUsersGroups',
          apiMethod: apiCalls.update
        })
      );
    } else {
      reduxdispatch(
        processFormData({
          payLoad: {
            name: userData['name'],
            email: userData['emailAddress']
          },
          endpoint: 'Users',
          hubname: 'updateUserRequest',
          clientId:  userData['emailAddress'].toString().toLowerCase(),
          successEvent: 'UpdateUserSpecificSuccessMessage',
          errorEvent: 'updateUserErrorMessage',
          apiMethod: apiCalls.update
        })
      );
    }
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (newRow.id !== 0) {
      handleUpdateTeamSubmit(newRow);
    }

    return updatedRow;
  };

  function EditToolbar(props: any) {
    return (
      <TableToolbar
        onAddClick={() => handleShow()}
        onDeleteClick={() => setShowDelete(true)}
        isDeleteDisabled={selectdIds.length === 0 || isClosed}
        isExportDisabled={users.length === 0}
        toolbarName="Users"
      />
    );
  }

  const handleDeleteUserFromGroup = async () => {
    setShowDelete(false);
    let emails = users
      .filter((user: { id: GridRowId }) => selectdIds.includes(user.id))
      .map((user: { emailAddress: string }) => user.emailAddress);
    let deleteBody: any = emails.map((email: string) => {
      return {
        userId: email,
        groupId: localStorage.getItem('selectedTypeId')
      };
    });
    reduxdispatch(
      processFormData({
        payLoad: deleteBody,
        endpoint: 'DeleteUsersFromProjectGroup',
        apiMethod: apiCalls.deleteAll
      })
    );
  };

  const handleDelete = async () => {
    const selectedType = localStorage.getItem('selectedType');
    if (selectedType === 'group') {
      handleDeleteUserFromGroup();
      return;
    }
    let emails = users
      .filter((user: { id: GridRowId }) => selectdIds.includes(user.id))
      .map((user: { emailAddress: string }) => user.emailAddress);
    let deleteBody: any = emails.map((email: string) => {
      return {
        id: email,
        siteId: projectDetails.projectSiteId,
        projectId: projectDetails.ProjectGuid
      };
    });
    setShowDelete(false);
    reduxdispatch(
      processFormData({
        payLoad: deleteBody,
        endpoint: 'Users',
        hubname: 'DeleteUsers',
        ProjectGuid: localStorage.getItem('selectedTypeId') || "",
        clientId: '-deleteusers-0',
        successEvent: 'DeleteUsersAllSuccessMessage',
        errorEvent: 'DeleteUserErrorMessage',
        apiMethod: apiCalls.deleteAll
      })
    );
  };

  // returns the users html
  return (
    <Card id="team-card" name={'Team'}>
      <MUIDataGridFunctions.MUIDataGrid
        type="team"
        rowIdColum={'id'}
        checkboxSelection={true}
        onSelectionModelChange={(itm: any) => setSelectedGridRowIds(itm)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel: React.SetStateAction<GridRowModesModel>) => setRowModesModel(newModel)}
        disableSelectionOnClick
        columns={columns}
        rows={users}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar
        }}
        componentsProps={{
          toolbar: {}
        }}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        // fullScreen
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Enter Team Informantion
              </Typography>
              <Button autoFocus color="inherit" type="submit">
                Save
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={4} style={{ padding: 50 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                label="Name"
                variant="outlined"
                name="name"
                type="text"
                placeholder="Full name"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Email"
                variant="outlined"
                name="email"
                type="email"
                placeholder="name@example.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
          </Grid>
        </form>
      </Dialog>
      <ConfirmModal
        toggleDeleteForm={showDelete}
        type="Team"
        handleConfirmation={handleDelete}
        handleCloseModal={() => setShowDelete(false)}
      />
    </Card>
  );
}
export default TeamList;
