import React from 'react';
import './content.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import { useParams } from 'react-router';
const { gtag } = require("ga-gtag");

function Content() {
  const [content, setContent] = React.useState({ __html: '' });
  const { projectSiteName } = useParams();
  const { title } = useParams();
 
  React.useEffect(() => {
    console.log('content', projectSiteName, title)
    main();
  }, []
  );
  function main() {

    var errorHTML = '<div style="margin: auto; margin-top:10%; margin-left:-10%; display: flex; align-items: center; align-self: center; justify-content: center; justify-self: center;">'
                  + '<h3>404 - Content not found</h3>'
                  + '</div>';
    
    apiCalls.getAll(Config.api.endPoint + `GetProjectBySiteName?projectSiteName=${projectSiteName?.toLowerCase()}`)
      .then(resp => {
        var projDetails: any = resp;
        projDetails = (projDetails?.ResponseData && projDetails?.ResponseData.length == 1) ? projDetails?.ResponseData[0] : null;
        // console.log(projDetails);
        if(projDetails.embeddedContent && projDetails.embeddedContent.length > 0){
          var embeddedContent = projDetails.embeddedContent.filter((x: any) => x.title.replace(/ /g, '').toLowerCase() == title?.replace(/ /g, '').toLowerCase());
          embeddedContent = (embeddedContent.length > 0) ? embeddedContent[0] : null;
          if(embeddedContent){
            document.title = embeddedContent.title +' - '+ document.title;
            var options = {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': window.location.pathname
            };
            gtag('config', Config.GoogleAnalytics.measurementId, options);
            apiCalls.getAll(Config.api.endPoint + `EmbeddedContent?projectId=${projDetails.id}&fileName=${embeddedContent.location}/${embeddedContent.fileName}`)
            .then(res => {
              var data: any = res;
              // console.log(data)
              setContent({__html: data});
            }).catch((err) => {
              console.log(err)
              setContent({__html: errorHTML});
            });
          }
          else{
            setContent({__html: errorHTML});
          }
        }
        else{
          setContent({__html: errorHTML});
        }
      }).catch((err) => {
        console.log(err)
        setContent({__html: errorHTML});
      });
  }
  
  return (
    <div data-cy="content" className="">
      <div className='m-5' id="contentData" dangerouslySetInnerHTML={content}></div>
    </div>
  );
}
export default Content;