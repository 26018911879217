import React from 'react';
import './embeddedContents.scss';
import { Config } from '../../config/config';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Container, useTheme } from '@mui/material';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

type embeddedContentsProps = {
    details: any
}

/* All Applications */
function EmbeddedContents(props: embeddedContentsProps) {
    const [expanded, setExpanded] = React.useState(false);
    const [embeddedContents, setEmbeddedContents] = React.useState([] as any);

    const theme = useTheme();
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    React.useEffect(() => {
        var embeddedContents = [] as any;
        props.details?.embeddedContent?.map((x: any) =>{
            var imageURL = '';
            var projectId = localStorage.getItem('selectedType') == 'group' ? x.projectId : props.details?.id;
            var projectSiteName = localStorage.getItem('selectedType') == 'group' ? x.projectSiteName : props.details?.projectSiteName;
            imageURL = x.thumbnail ? Config.api.endPoint + `GetImageUrl?projectId=${projectId}&content=${x.thumbnail}` : require(`../../assets/images/applications/defaultreportimage.jpeg`).default; 
            x['imageURL'] = imageURL;
            var url = `/${projectSiteName}/content/${x.location}/${x.title.replace(/ /g, '')}`;
            x['url'] = url.toLowerCase();
            embeddedContents.push(x);
        });

        setEmbeddedContents(embeddedContents)
    }, [props.details?.embeddedContent]
    );

    return (
        <Container maxWidth={false} className="p-0" >
            <div className='row mb-3'>
                {embeddedContents.map((x: any, index: any) =>
                    <div className='col-lg-3 mb-3'>
                        <Card sx={{ 
                            maxWidth: '100%', 
                            borderRadius: 5,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A200,
                            boxShadow: 'none'  
                            }} key={x.title + "-image"}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                <strong>{x.title}</strong>
                                </Typography>
                                    <Typography variant="body2"
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '3',
                                            WebkitBoxOrient: 'vertical',
                                            minHeight: 60
                                        }}>
                                        {x.info}
                                    </Typography>
                            </CardContent>
                            <CardActions>
                                <Button 
                                    className='embeddedOpenButton'
                                    href={x.url} 
                                    target={'_blank'}
                                    size="small" 
                                    variant="outlined" 
                                    color="secondary" 
                                    disableElevation 
                                    fullWidth
                                    style={{marginTop:-15, marginBottom: 5, borderRadius: 20, marginLeft: 10, marginRight: 10, color: 'black'}}
                                ><strong>Open</strong></Button>
                            </CardActions>
                        </Card>
                    </div>
                )}
            </div>
        </Container>
    );
}
export default EmbeddedContents;