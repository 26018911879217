import { GridRowModesModel, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Card from 'components/card/card';
import MUIDataGridFunctions from 'components/MUIGrid/MUIGrid';
import React from 'react';
import { columns } from './constants';
import moment from 'moment';
import { Config } from 'config/config';
import apiCalls from 'utils/api_calls';

const ManageProjects: React.FC = () => {
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [projects, setProjects] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsLoading(true);
        apiCalls
            .getAll(Config.api.endPoint + 'Users')
            .then((users: any) => {
                setIsLoading(false);
                if ((users.projects != null && users.projects.length > 0)) {
                    setProjects(users.projects);
                }
            }).catch(err => {
                setIsLoading(false);
            })
    }, [])

    function EditToolbar() {
        return (
            <GridToolbarContainer className="row">
                <div className="col-sm-9">
                    <GridToolbarExport
                        excelOptions={{
                            fileName: `Projects ` + moment().format('YYYY-MM-DD_HH-mm')
                        }}
                        csvOptions={{
                            fileName: `Projects ` + moment().format('YYYY-MM-DD_HH-mm')
                        }}
                        printOptions={{
                            fileName: `Projects ` + moment().format('YYYY-MM-DD_HH-mm')
                        }}
                    />
                </div>
                <GridToolbarQuickFilter className="col-sm-3" quickFilterProps={{ debounceMs: 500 }} />
            </GridToolbarContainer>
        );
    }

    return (<Card id="manageprojects-card" name={'Manage Projects'}>
        <MUIDataGridFunctions.MUIDataGrid
            rows={projects}
            rowIdColum={'id'}
            loading={isLoading}
            columns={columns}
            disableSelectionOnClick={true}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => setRowModesModel(newModel)}
            componentsProps={{}}
            components={{
                Toolbar: EditToolbar
            }}
        />
    </Card>
    );
}

export default ManageProjects;