import React from 'react';
import { 
  ActionItemTypes, 
  ActionItemContextStateType, 
  FormMode, 
  ActionItemContextType, 
  ActionItemActionType,
  ToastKind
 } from '../actionitemTypes';


export const initialState: ActionItemContextStateType = {
  isLoading: true,
  data: [],
  connection: null,
  projectDetails: {},
  selectedIds: [],
  projectGroups: [],
  showAdd: false,
  showUpdate: false,
  showDelete: false,
  showToast: false,
  toastKind: ToastKind.SUCCESS,
  toastMsg: '',
  projectSummary: {},
  formMode: FormMode.VIEW,
  refreshApiCounter: 0,
  actionitem: {},
  deleteActionItem: {},
  selectedUser: {},
};

export const ActionItemsContext =
  React.createContext<ActionItemContextType>({
    state: initialState,
    dispatch: () => console.warn('no state provider'),
  });

const defaultAction: ActionItemActionType = {
  type: ActionItemTypes.SET_ACTION_ITEMS,
  payload: '',
};

export function ActionItemsReducers(
  state: ActionItemContextStateType = initialState,
  action: ActionItemActionType = defaultAction
): ActionItemContextStateType {
  switch (action.type) {
    case ActionItemTypes.SET_ACTION_ITEM_TOAST: {
      return {
        ...state,
        toastMsg: action.payload.toastMsg,
        toastKind: action.payload.toastKind,
        isLoading: false,
      };
    }
    case ActionItemTypes.SET_LIST_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.payloadItems.map( (m: any) =>{
          return {
            ...m,
          }
        }),
        projectSummary: action.payload.projectSummary,
        projectGroups: action.payload.projectGroups,
        projectDetails: action.payload.projectDetails
      };
    }
    case ActionItemTypes.SET_ACTION_ITEMS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.map( (m: any) =>{
          return {
            ...m,
          }
        })
      };
    }
    case ActionItemTypes.SET_ACTION_ITEM_LOADING_STATUS: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionItemTypes.SET_SOCKET_CONNECTION: {
      return {
        ...state,
        connection: action.payload,
      };
    }
    case ActionItemTypes.SET_SELECTED_GRID_ROW_IDS: {
      return {
        ...state,
        selectedIds: action.payload
      }
    }
    case ActionItemTypes.SET_SHOW_ADD_FORM: {
      return {
        ...state,
        showAdd: action.payload
      }
    }
    case ActionItemTypes.SET_SHOW_UPDATE_FORM: {
      return {
        ...state,
        showUpdate: action.payload
      }
    }
    case ActionItemTypes.SET_SHOW_DELETE_FORM: {
      return {
        ...state,
        showDelete: action.payload
      }
    }
    case ActionItemTypes.SET_REFRESH_API_COUNTER: {
      return {
        ...state,
        refreshApiCounter: state.refreshApiCounter+1
      }
    }
    case ActionItemTypes.SET_UPDATE_ACTION_ITEM: {
      return {
        ...state,
        actionitem: action.payload
      }
    }
    case ActionItemTypes.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.payload
      }
    }
    case ActionItemTypes.SET_DELETE_ACTION_ITEM: {
      return {
        ...state,
        deleteActionItem: action.payload
      }
    }
    case ActionItemTypes.SET_TOAST_INFO: {
      return {
        ...state,
        toastKind: action.payload.toastKind,
        toastMsg: action.payload.toastMsg,
        showToast: action.payload.showToast,
      }
    }
    default: {
      return state;
    }
  }
}

export default ActionItemsContext;
