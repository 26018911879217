import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { Grid } from '@mui/material';
import React from 'react';
import CustomAsynchTypeHead from 'components/CustomAsynchTypeHead';

interface AsyncSelectRendererControlProps {
  isLoading: boolean;
  options: any[];
  handleSearch: (query: string) => void;
  renderOption?: (props: any, option: any) => React.ReactElement<any>;
}

interface ExtendedAsyncSelectRendererControlProps extends AsyncSelectRendererControlProps, ControlProps {}

export const AsyncSelectRendererControl: React.FC<ExtendedAsyncSelectRendererControlProps> = (props) => {
  return (
    <Grid
      className={'jsonform-custom-container'}
      item
      xs={12}
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      hidden={!props.visible}
    >
      <CustomAsynchTypeHead
        required={props.required || false}
        isLoading={props.isLoading}
        handleSearch={props.handleSearch}
        renderOption={props.renderOption}
        isMultiple={props.schema['type']==='array'}
        options={props.options}
        data={props.data}
        errors={props.errors}
        handleChange={(val) => props.handleChange(props.path, val)}
        label={props.label}
        placeholder={props.uischema?.options && props.uischema.options['placeholder']}
      />
    </Grid>
  );
};

export const AsyncSelectRendererControlTester: RankedTester = rankWith(
  1000,
  or(schemaMatches((schema) => schema['format'] === 'asyncSelect'))
);

export default AsyncSelectRendererControl;
