import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, TextField } from '@mui/material';

export const CustomInputDateRendererControl = (props: ControlProps) => {
  return (
    <Grid
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      className={'jsonform-custom-container'}
      item
      xs={12}
      hidden={!props.visible}
    >
      <TextField
        fullWidth
        id={props.path}
        label={props.label}
        variant="outlined"
        name={props.path}
        type="date"
        onChange={(e) => props.handleChange(props.path, e.target.value)}
        error={props.errors.length > 0}
        InputLabelProps={{ shrink: true }}
        value={props.data||''}
        required={props.required}
      />
    </Grid>
  );
};

export const CustomInputDateRendererControlTester: RankedTester = rankWith(
  1000,
  or(schemaMatches((schema) => schema['format'] === 'date'))
);
export default withJsonFormsControlProps(CustomInputDateRendererControl);
