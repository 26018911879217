import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, Input, Slider, Typography } from '@mui/material';
import React from 'react';



export const CustomInputSliderControl = (props: ControlProps) => {
  const [value, setValue] = React.useState(props.data || props.schema.default || 0);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    props.handleChange(props.path, newValue);
    setValue(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = isNaN(Number(event.target.value)) ? 0 : parseInt(event.target.value)
    setValue(val);
    props.handleChange(props.path, val);
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  React.useEffect(() => {
    props.handleChange(props.path, value);
  }, []);

  let typoGraphyBackgroundColor = props.errors.length>0?'red':'#262626'

  return (
    <Grid
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      className={'jsonform-custom-container'}
      item
      xs={12}
      hidden={!props.visible}
      spacing={2}
    >
      <Typography style={{ color: typoGraphyBackgroundColor }} id="input-slider" gutterBottom>
        {props.label} : {props.data || props.schema.default || 0} {props.uischema?.options && props.uischema.options['symbol']}
      </Typography>
      <Grid  container alignItems="center">
        <Grid style={{
          marginLeft: '5px'
        }} item xs>
          <Slider
            value={props.data || props.schema.default || 0}
            onChange={handleSliderChange}
            step={props.schema.multipleOf || 1}
            track="inverted"
            aria-labelledby="input-slider"
            min={props.schema.minimum}
            max={props.schema.maximum}
          />
        </Grid>
        <Grid ml={1.5} item>
          <Input
            style={{
              width: '50px'
            }}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CustomInputSliderControlTester: RankedTester = rankWith(
  1000,
  or(schemaMatches((schema) => schema['format'] === 'rangeSlider'))
);
export default withJsonFormsControlProps(CustomInputSliderControl);
