// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import DrawerFooterStyled from './DrawerFooterStyled';

// assets
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, IconButton, Grid } from '@mui/material';

// ==============================|| DRAWER FOOTER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const DrawerFooter = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const iconColorHover = 'text.primary';
  return (
    <DrawerFooterStyled style={{backgroundColor:'black'}} theme={theme} open={open}>
{open ? (
  <Grid container>
      <Button
      fullWidth
      aria-label="collapse menu"
      onClick={handleDrawerToggle}
      color="secondary"
      startIcon={<DoubleLeftOutlined />}
      sx={{color: 'text.secondary', textTransform: 'uppercase' }}
    >
      Collapse
    </Button>  
    </Grid>
      ) : (
      <IconButton
        aria-label="expand menu"
        onClick={handleDrawerToggle}
        color="secondary"
        title="expand menu"
        sx={{ color: 'text.secondary', ml: { xs: 0, lg: 0 }, "&:hover": { color: iconColorHover } }}
      >
        <DoubleRightOutlined />
      </IconButton>    
)}
    </DrawerFooterStyled>
  );
};

export default DrawerFooter;