import * as React from 'react';
import './dashboardRisks.scss';
import { Color } from '@progress/kendo-drawing';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT } from 'utils/utils';
import { Button } from '@mui/material';

type milestoneProps = {
  details: any;
};

function DashboardRisks(props: milestoneProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [projectRisks, setProjectRisks] = React.useState<any[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.details.id != null && props.details.id != '') {
      getProjectRisks();
    }
  }, [props.details]);

  function getProjectRisks() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'risks',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let groupRisks = res.length > 0 ? res : [];
          let projRisks: any = [];
          let risks: any = [];

          groupRisks.forEach((group: any) => {
            projRisks.push(...group.risks);
          });

          projRisks.slice(0, 4).forEach((risk: any) => {
            var temp = {
              RiskMode: getRiskMode(risk.Risk_x0020_Probability, risk.Risk_x0020_Impact),
              RiskTitle: risk.Title.length > 40 ? risk.Title.substring(0, 40) + '...' : risk.Title
            };
            risks.push(temp);
          });
          setIsLoading(false);

          setProjectRisks(risks);
        })
        .catch((err) => {
          setProjectRisks([]);
          setIsLoading(false);
        });
    } else {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projRisks: any = res.length > 0 ? res[0].risks : [];
          let risks: any = [];

          projRisks.slice(0, 4).forEach((risk: any) => {
            var temp = {
              RiskMode: getRiskMode(risk.Risk_x0020_Probability, risk.Risk_x0020_Impact),
              RiskTitle: risk.Title.length > 40 ? risk.Title.substring(0, 40) + '...' : risk.Title
            };
            risks.push(temp);
          });

          setProjectRisks(risks);
          setIsLoading(false);
        })
        .catch((err) => {
          setProjectRisks([]);
          setIsLoading(false);
        });
    }
  }

  function getRiskMode(probability: string, impact: string) {
    if (probability.toUpperCase().includes('LOW') && impact.toUpperCase().includes('LOW')) {
      return 'Low_Low';
    } else if (probability.toUpperCase().includes('LOW') && impact.toUpperCase().includes('MEDIUM')) {
      return 'Low_Medium';
    } else if (probability.toUpperCase().includes('LOW') && impact.toUpperCase().includes('HIGH')) {
      return 'Low_High';
    } else if (probability.toUpperCase().includes('MEDIUM') && impact.toUpperCase().includes('LOW')) {
      return 'Medium_Low';
    } else if (probability.toUpperCase().includes('MEDIUM') && impact.toUpperCase().includes('MEDIUM')) {
      return 'Medium_Medium';
    } else if (probability.toUpperCase().includes('MEDIUM') && impact.toUpperCase().includes('HIGH')) {
      return 'Medium_High';
    } else if (probability.toUpperCase().includes('HIGH') && impact.toUpperCase().includes('LOW')) {
      return 'High_Low';
    } else if (probability.toUpperCase().includes('HIGH') && impact.toUpperCase().includes('MEDIUM')) {
      return 'High_Medium';
    } else if (probability.toUpperCase().includes('HIGH') && impact.toUpperCase().includes('HIGH')) {
      return 'High_High';
    }
  }

  return (
    <div data-cy={'dashboard-risks-card'} className="card module dashboard-risks-card">
      <div data-cy="card-title" className="regionRisksHeader">
        <div style={{ alignItems: 'center' }} className="row">
          <span className="actions-main-header col-md-8">Risks</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/risks')} color="primary" >
              View All
            </Button>
          </span>
        </div>
      </div>
      <div className={`dashboard-risks-div ${projectRisks.length === 0 && "dashboard-risks-empty"}`}>
        <BackDrop isLoading={isLoading} />
        {projectRisks.length > 0 && (
          <div className="dashboard-risks-items">
            {projectRisks.map((risk: any, index: number) => {
              return (
                <div key={'Risk' + index} className="row">
                  <div className="col-2">
                    <span className={risk.RiskMode + ' ' + 'indicator risks-main-header'}></span>
                  </div>
                  <div className="col-9">
                    <span className="risks-sub-header" style={{ fontSize: '12px' }}>
                      {risk.RiskTitle}
                    </span>
                    <hr className="line-separator" hidden={index + 1 === projectRisks?.length} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {projectRisks.length === 0 && <span style={{ marginLeft: '30px' }}>No risks available</span>}
      </div>

      <div data-cy="viewall" hidden>
        <input hidden={projectRisks.length === 0} type="button" className="btn btn-sm btn-view-all-risks" value="View all"></input>
      </div>
    </div>
  );
}

export default DashboardRisks;
