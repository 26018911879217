import { GridColumns } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";

export const columns: GridColumns = [
    {
      field: 'Title',
      headerName: 'Title',
      flex: 5,
      editable: true,
      sortable: true,
      valueParser(value, params?) {
        return value.length > 255 ? value.slice(0, 255) : value;
      },
      preProcessEditCellProps(params) {
        const invalid = params.props.value ? false : true;
        if (invalid) {
          params.props.error = invalid;
          params.props.className = 'error';
          return { ...params.props };
        } else {
          params.props.error = invalid;
          params.props.className = '';
          return { ...params.props };
        }
      }
    },
    {
      field: 'Date',
      headerName: 'Date',
      flex: 2,
      type: 'date',
      editable: true,
      sortable: true,
      valueGetter: (params) => {
        const dateVal = dayjs(params.value);
        return dateVal.isValid()? dateVal.toDate(): new Date();
      },
      valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
      preProcessEditCellProps(params) {
        const invalid = params.props.value ? false : true;
        if (invalid) {
          params.props.error = invalid;
          params.props.className = 'error';
          return { ...params.props };
        } else {
          params.props.error = invalid;
          params.props.className = '';
          return { ...params.props };
        }
      }
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 8,
      editable: true,
      sortable: true,
      valueParser(value, params?) {
        return value.length > 255 ? value.slice(0, 255) : value;
      },
      preProcessEditCellProps(params) {
        const invalid = params.props.value ? false : true;
        if (invalid) {
          params.props.error = invalid;
          params.props.className = 'error';
          return { ...params.props };
        } else {
          params.props.error = invalid;
          params.props.className = '';
          return { ...params.props };
        }
      }
    },
    {
      field: 'ProjectId',
      headerName: 'Project ID',
      flex: 2,
      editable: false,
      sortable: true
    },
    {
      field: 'ProjectName',
      headerName: 'Project Name',
      flex: 3,
      editable: false,
      sortable: true
    }
  ];

  export const winsFormSchema = {
    type: 'object',
    properties: {
      title: {
        type: 'string',
        minLength: 1,
        format: 'input'
      },
      date: {
        type: 'string',
        format: 'date'
      },
      description: {
        type: 'string',
        minLength: 1,
        format: 'textarea',
        maxLength: 255
      },
      project: {
        type: 'string',
        format: 'projectDropdown'
      }
    },
    required: ['title', 'date', 'description']
  };
  
  export const winsFormUischema = {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/title',
        label: 'Title',
        options: {
          placeholder: 'Title'
        }
      },
      {
        type: 'Control',
        scope: '#/properties/date'
      },
      {
        type: 'Control',
        scope: '#/properties/description'
      },
      {
        type: 'Control',
        scope: '#/properties/project',
        label: 'Project:',
        rule: {
          effect: 'HIDE',
          condition: {}
        }
      }
    ]
  };
  