import { Snackbar } from '@mui/material';
import { ToastKind } from '../issueItemTypes';
import { createContext, useReducer, useContext } from 'react';
import { IssueItemContextType } from '../issueItemTypes';
import { setToastInfo } from './actions';
import { initialState, IssuesItemsReducers } from './reducer';
import Alert from 'components/Alert';


const IssuesItemsContext = createContext<IssueItemContextType>({
  state: initialState,
  dispatch: () => console.warn('no state provider')
});

export const IssueProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(IssuesItemsReducers, initialState);
  const closeAlert = () => {
    setToastInfo(dispatch, ToastKind.INFO, '', false);
  };
  return (
    <IssuesItemsContext.Provider value={{ state, dispatch }}>
      <Snackbar open={state.showToast} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={5000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity={state.toastKind} sx={{ width: '100%' }}>
          {state.toastMsg}
        </Alert>
      </Snackbar>
      {children}
    </IssuesItemsContext.Provider>
  );
};

const useIssue = () => {
  const context = useContext(IssuesItemsContext);

  if (context === undefined) {
    throw new Error('useIssue must be used within IssueContext');
  }

  return context;
};

export default useIssue;
