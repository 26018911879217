import { JsonSchema } from '@jsonforms/core';
import { InputAdornment } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import EditPercentageCell from 'containers/actionitems/actionItemTable/EditPercentageCell';
import dayjs from 'dayjs';
import { USDollar } from 'utils/utils';

export const columns: GridColumns = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 4,
    editable: true,
    sortable: true,
    valueParser(value, params?) {
      return value.length > 255 ? value.slice(0, 255) : value;
    },
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 4,
    editable: true,
    sortable: true,
    valueParser(value, params?) {
      return value.length > 255 ? value.slice(0, 255) : value;
    }
  },
  {
    field: 'finishDate',
    headerName: 'Finish Date',
    flex: 2,
    type: 'date',
    editable: true,
    sortable: true,
    valueGetter: (params) => {
      const dateVal = dayjs(params.value);
      return dateVal.isValid() ? dateVal.toDate() : new Date();
    },
    valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
    preProcessEditCellProps(params) {
      const invalid = params.props.value ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'percentageCompleted',
    headerName: '% Complete',
    flex: 2,
    type: 'number',
    editable: true,
    sortable: true,
    renderEditCell: (params: GridRenderEditCellParams) => <EditPercentageCell {...params} />,
    valueParser(value, params?) {
      if (value > 100) {
        return 100;
      } else if (value < 0) {
        return 0;
      } else {
        return value;
      }
    },
    preProcessEditCellProps(params) {
      const invalid = Number(params.props.value) >= 0 && Number(params.props.value) <= 100 ? false : true;
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  },
  {
    field: 'ProjectId',
    headerName: 'Project ID',
    flex: 2,
    editable: false,
    sortable: true
  },
  {
    field: 'ProjectName',
    headerName: 'Project Name',
    flex: 3,
    editable: false,
    sortable: true
  },
  {
    field: 'customValue',
    headerName: 'Value',
    type: 'number',
    flex: 4,
    editable: true,
    sortable: true,
    valueParser(value, params?) {
      return isNaN(value) ? 0 : Number(value);
    },
    renderCell: (params: GridRenderCellParams<any>) => {
      return USDollar.format(params.value);
    },
    preProcessEditCellProps(params) {
      const invalid = isNaN(params.props.value);
      if (invalid) {
        params.props.error = invalid;
        params.props.className = 'error';
        return { ...params.props };
      } else {
        params.props.error = invalid;
        params.props.className = '';
        return { ...params.props };
      }
    }
  }
];

export const schema: JsonSchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      format: 'input',
      maxLength: 100
    },
    percentComplete: {
      type: 'number',
      minimum: 0,
      maximum: 100,
      default: 0,
      format: 'rangeSlider'
    },
    finishDate: {
      type: 'string',
      format: 'date'
    },
    description: {
      type: 'string',
      minLength: 1,
      format: 'textarea',
      maxLength: 255
    },
    customValue: {
      type: 'number',
      format: 'input',
      default: 0
    },
    approvers: {
      type: 'array',
      format: 'asyncSelect',
      uniqueItems: true,
      items: {
        type: 'object'
      }
    },
    project: {
      type: 'string',
      format: 'projectDropdown'
    }
  },
  required: ['title', 'percentComplete', 'finishDate', 'description']
};

export const uischema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/title',
      label: 'Title',
      options: {
        placeholder: 'Title'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/percentComplete',
      label: 'Percent complete'
    },
    {
      type: 'Control',
      scope: '#/properties/finishDate',
      label: 'Finish Date'
    },
    {
      type: 'Control',
      scope: '#/properties/description',
      label: 'Description',
      options: {
        placeholder: 'Description'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/customValue',
      label: 'Value',
      options: {
        placeholder: 'Value',
        inputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }
      }
    },
    {
      type: 'Control',
      scope: '#/properties/approvers',
      label: 'Approver Emails',
      options: {
        placeholder: 'Emails'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/project',
      label: 'Project:',
      rule: {
        effect: 'HIDE',
        condition: {}
      }
    }
  ]
};
