import React from 'react';
import './risks.scss';
import apiCalls from '../../utils/api_calls';
import Card from '../../components/card/card';
import MUIDataGridFunctions from '../../components/MUIGrid/MUIGrid';
import { GridRowModesModel, GridRowParams, MuiEvent, GridEventListener, GridRowModel } from '@mui/x-data-grid-pro';
import {  useNavigate } from 'react-router-dom';
import { columns, schema, uischema } from './constants';
import FormBuilderComponent from 'components/formBuilder/formBuilder';
import TableToolbar from 'components/TableToolbar';
import { useDispatch, useSelector } from 'store';
import { RootState } from 'types/CommonReducerType';
import { JsonSchema } from '@jsonforms/core';
import { fetchListdata, processFormData } from 'store/reducers/common/actions';
import ConfirmModal from 'components/ConfirmModal';
import { getValidItemList, groupColumnIds } from 'utils/utils';
import moment from 'moment';

/* Top Risks Function */
function Risks() {
  const [risksChecked, setRisksChecked] = React.useState([] as any);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [showDelete, setShowDelete] = React.useState(false);

  const navigate = useNavigate();
  const [formSchema, setFormSchema] = React.useState<JsonSchema>(schema);
  const [formUiSchema, setFormUiSchema] = React.useState<any>(uischema);
  const [gridColumns, setGridColumns] = React.useState(columns);

  const [show, setShow] = React.useState(false);

  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);
  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const isClosed = useSelector((state: RootState) => state.common['isClosed']);
  const risks = getValidItemList(useSelector((state: RootState) => state.common['listItems']), columns);
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const reduxdispatch = useDispatch();

  const closeDialog = () => {
    setShow(false);
  };

  const addRisk = async (formData: any) => {
    setShow(false);
    const selectedUser = formData['selectedUser'];
    const projectId = localStorage.getItem('selectedType') === 'group' ? formData.project : projectDetails.ProjectGuid;
    const payLoad = {
      title: formData.title,
      dueDate: formData.dueDate,
      risk_x0020_Probability: formData['riskProbability'],
      risk_x0020_Impact: formData['riskImpact'],
      status: formData['status'],
      assignedToLookupId: selectedUser?.Id ? String(selectedUser?.Id) : null,
      assignedTo: selectedUser?.Name || null,
      projectId,
      groupId: null
    };

    await reduxdispatch(
      processFormData({
        payLoad,
        endpoint: 'Risks',
        hubname: 'Risks',
        ProjectGuid: projectId,
        clientId: '-createrisk-0',
        successEvent: 'CreatesRiskUserSuccessMessage',
        errorEvent: 'RisksUserErrorMessage',
        apiMethod: apiCalls.create
      })
    ).unwrap();
  };

  function EditToolbar() {
    return (
      <TableToolbar
        onAddClick={() => setShow(true)}
        onDeleteClick={onDeleteClick}
        isDeleteDisabled={risksChecked.length === 0}
        isExportDisabled={risks.length === 0}
        toolbarName="Risks"
        isHidden={isClosed}
      />
    );
  }

  React.useEffect(() => {
    const isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let requredFields = formSchema['required']?.filter((item) => item !== 'project');
    let element = formUiSchema['elements']?.find((elm: any) => elm.scope === '#/properties/project');
    let projectFldIndex = formUiSchema['elements']?.findIndex((elm: any) => elm.scope === '#/properties/project');
    if (element) {
      element['rule']['effect'] = isprojectGroup ? 'SHOW' : 'HIDE';
      formUiSchema[projectFldIndex] = element;
      setFormUiSchema(formUiSchema);
    }

    if (isprojectGroup) {
      requredFields?.push('project');
    }
    formSchema['required'] = requredFields;
    setFormSchema(formSchema);
  }, [projectChangeCounter]);

  // Exclude project group columns
  React.useEffect(() => {
    let isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let modifiedColumns = columns.filter((column) => {
      if (!isprojectGroup) {
        return !groupColumnIds.includes(column.field);
      }
      return true;
    });
    setGridColumns(modifiedColumns);
  }, [projectChangeCounter]);

  React.useEffect(() => {
    main();
  }, [projectChangeCounter]);

  const modiFyitemCallback = (item: any) => {
    item['user'] = {
      AssignedTo: item['AssignedTo'],
      AssignedToLookupId: item['AssignedToLookupId']
    };
    try{
      let riskProbability = item['Risk_x0020_Probability'];
      let riskImpact = item['Risk_x0020_Impact'];

      if(riskProbability != null && riskProbability.toLowerCase().includes("high")){
        item['Risk_x0020_Probability'] = "(1) High";
      }
      if(riskProbability != null && riskProbability.toLowerCase().includes("medium")){
        item['Risk_x0020_Probability'] = "(2) Medium";
      }
      if(riskProbability != null && riskProbability.toLowerCase().includes("low")){
        item['Risk_x0020_Probability'] = "(3) Low";
      }

      if(riskImpact != null && riskImpact.toLowerCase().includes("high")){
        item['Risk_x0020_Impact'] = "(1) High";
      }
      if(riskImpact != null && riskImpact.toLowerCase().includes("medium")){
        item['Risk_x0020_Impact'] = "(2) Medium";
      }
      if(riskImpact != null && riskImpact.toLowerCase().includes("low")){
        item['Risk_x0020_Impact'] = "(3) Low";
      }
      
      return {...item};
    }
    catch(exp) {
      console.log("Error:", exp);
      return {...item};
    }
  }

  async function main() {
    reduxdispatch(
      fetchListdata({
        ItemName: 'risks',
        type: 'risks',
        customId: 'riskId',
        modiFyitemCallback: modiFyitemCallback
      })
    )
  }

  const handleSubmitUpdate = async (formData: any) => {
    const selectedUser = formData['user'];
    const projectId = formData['ProjectGuid'];
    let dueDate = formData['DueDate'];
    let dueDateFld = dueDate!== '' && dueDate != null ? dueDate : moment().utc();
    
    const payLoad = {
      id: formData['id'],
      title: formData.Title,
      dueDate: dueDateFld,
      risk_x0020_Probability: formData['Risk_x0020_Probability'],
      risk_x0020_Impact: formData['Risk_x0020_Impact'],
      status: formData['Status'],
      assignedToLookupId: selectedUser?.AssignedToLookupId ? String(selectedUser?.AssignedToLookupId) : null,
      assignedTo: selectedUser?.AssignedTo || null,
      projectId,
      groupId: null
    };

    await reduxdispatch(
      processFormData({
        payLoad,
        endpoint: 'Risks',
        hubname: 'Risks',
        ProjectGuid: formData['ProjectGuid'],
        clientId: '-updaterisk-0',
        successEvent: 'UpdateRisksUserSuccessMessage',
        errorEvent: 'RisksUserErrorMessage',
        apiMethod: apiCalls.update
      })
    ).unwrap();
  };

  const onDeleteClick = () => {
    if (risksChecked.length === 1) {
      confirmDelete();
    } else {
      setShowDelete(true);
    }
  };

  const confirmDelete = async () => {
    let ProjectGuid = '';
    let deleteBody = risksChecked.map((ID: String) => {
      let splitId = ID.split('--');
      ProjectGuid = splitId[1];
      return {
        id: splitId[0],
        siteId: splitId[2],
        projectId: splitId[1]
      };
    });
    setShowDelete(false);

    reduxdispatch(
      processFormData({
        payLoad: deleteBody,
        endpoint: 'Risks',
        hubname: 'Risks',
        ProjectGuid,
        clientId: '-deleterisk-0',
        successEvent: 'DeleteRisksUserSuccessMessage',
        errorEvent: 'RisksUserErrorMessage',
        apiMethod: apiCalls.deleteAll
      })
    )
  };

  const handleRowEditStart = (_params: GridRowParams, event: MuiEvent) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow.id !== 0) {
      handleSubmitUpdate(updatedRow);
    }
    return updatedRow;
  };

  // returns the risks html
  return (
    <Card id="risks-card" name={'Risks'}>
      <MUIDataGridFunctions.MUIDataGrid
        rows={risks}
        rowIdColum={'riskId'}
        disableSelectionOnClick
        columns={gridColumns}
        onSelectionModelChange={(itm: any) => setRisksChecked(itm)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel: any) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
        componentsProps={{
          toolbar: {}
        }}
        components={{
          Toolbar: EditToolbar
        }}
      />
      <FormBuilderComponent
        formTitle={'Add Risk'}
        schemaObj={formSchema}
        initialData={{}}
        uiSchema={formUiSchema}
        openDialog={show}
        onClose={closeDialog}
        onHandleSubmit={addRisk}
      />
      <ConfirmModal
        handleConfirmation={confirmDelete}
        toggleDeleteForm={showDelete}
        type={'Decisions'}
        handleCloseModal={() => setShowDelete(false)}
      />
    </Card>
  );
}
export default Risks;
