import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export const CustomProjectSelectRendererControl = (props: ControlProps) => {
  const projectGroups = useSelector((state: any) => state.common['projectGroups'])
  const [inputValue, setInputValue] = React.useState('');
  return (
    <Grid
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      className={'jsonform-custom-container'}
      item
      xs={12}
      hidden={!props.visible}
    >
      <Autocomplete
        freeSolo
        style={{
          width: '100%'
        }}
        onChange={(event: any, newValue: any) => {
          props.handleChange(props.path, newValue['id']);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => `${option.projectId} - ${option.name}`}
        id={props.path}
        options={projectGroups}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField required={props.required} error={props.errors.length > 0} {...params} label={props.label} />}
      />
    </Grid>
  );
};

export const CustomProjectSelectRendererControlTester: RankedTester = rankWith(
  1000,
  or(schemaMatches((schema) => schema['format'] === 'projectDropdown'))
);
export default withJsonFormsControlProps(CustomProjectSelectRendererControl);
