import { ControlProps, RankedTester, rankWith, schemaMatches, or } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, TextField } from '@mui/material';

export const CustomTextareaRendererControl = (props: ControlProps) => {
  return (
    <Grid
      style={{
        paddingLeft: '32px',
        paddingTop: '32px'
      }}
      className={'jsonform-custom-container'}
      item
      xs={12}
      hidden={!props.visible}
    >
      <TextField
        fullWidth
        id={props.path}
        label={props.label}
        variant="outlined"
        name={props.path}
        rows={3}
        multiline
        placeholder={props.uischema?.options && props.uischema.options['placeholder']}
        onChange={(e) => props.handleChange(props.path, e.target.value)}
        error={props.errors.length > 0}
        value={props.data||''}
        required={props.required}
      />
    </Grid>
  );
};

export const CustomTextareaRendererControlTester: RankedTester = rankWith(1000, or(schemaMatches((schema) => schema['format'] === 'textarea')));
export default withJsonFormsControlProps(CustomTextareaRendererControl);
