import { Input } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';

const EditPercentageCell: React.FC<GridRenderEditCellParams> = (props) => {
  const { id, value, field, error } = props;
  const apiRef = useGridApiContext();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = isNaN(Number(event.target.value)) ? 0 : parseInt(event.target.value);
    apiRef.current.setEditCellValue({ id, field, value });
  };

  const handleBlur = () => {
    if (value < 0) {
      apiRef.current.setEditCellValue({ id, field, value: 0 });
    } else if (value > 100) {
      apiRef.current.setEditCellValue({ id, field, value: 100 });
    }
  };
  return (
    <Input
      style={{
        width: '100%',
        margin: '5px'
      }}
      value={value}
      margin="none"
      onChange={handleInputChange}
      onBlur={handleBlur}
      inputProps={{
        step: 1,
        min: 0,
        max: 100,
        type: 'number',
        'aria-labelledby': 'input-slider'
      }}
    />
  );
};

export default EditPercentageCell;
