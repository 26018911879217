import React, { useState } from 'react';
import {
  materialRenderers,
  materialCells
} from '@jsonforms/material-renderers';
import { JsonFormsCore } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { AsyncSelectRendererControlTester } from './CustomRenderers/AsyncSelectRenderer';
import CustomInputRendererControl, { CustomInputRendererControlTester } from './CustomRenderers/CustomInputRenderer';
import CustomSelectRendererControl, { CustomSelectRendererControlTester } from './CustomRenderers/CustomSelectRenderer';
import CustomInputDateRendererControl, {
  CustomInputDateRendererControlTester
} from './CustomRenderers/CustomInputDateRenderer';
import CustomProjectSelectRendererControl, {
  CustomProjectSelectRendererControlTester
} from './CustomRenderers/CustomProjectSelectRenderer';
import CustomTextareaRendererControl, {
  CustomTextareaRendererControlTester
} from './CustomRenderers/CustomTextareaRenderer';
import CustomInputSliderControl, {
  CustomInputSliderControlTester
} from './CustomRenderers/CustomInputSlider';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Transition from 'components/ModalTransition';
import UserSelectControl from './CustomRenderers/UserSelectControl';


type formBuilderProps = {
  schemaObj: any,
  initialData: any,
  uiSchema: any,
  formTitle: string,
  openDialog: boolean,
  onClose: any,
  onHandleSubmit: any
}


function FormBuilderComponent(props: formBuilderProps) {  
  const [schema, setSchema] = useState(props.schemaObj);
  const [uischema, setUiSchema] = useState(props.uiSchema);
  const [customrenderers, setcustomrenderers] = useState([
    ...materialRenderers,
    // register custom renderers
    { renderer: CustomInputSliderControl, tester: CustomInputSliderControlTester },
    { renderer: CustomTextareaRendererControl, tester: CustomTextareaRendererControlTester },
    { renderer: UserSelectControl, tester: AsyncSelectRendererControlTester },
    { renderer: CustomInputRendererControl, tester: CustomInputRendererControlTester },
    { renderer: CustomSelectRendererControl, tester: CustomSelectRendererControlTester },
    { renderer: CustomInputDateRendererControl, tester: CustomInputDateRendererControlTester },
    { renderer: CustomProjectSelectRendererControl, tester: CustomProjectSelectRendererControlTester }
  ]);
  const [formData, setFormData] = React.useState<any>(props.initialData);
  const [errors, setFormErrors] = React.useState<any>([]);
  const [validationMode, setValidationMode] = React.useState<any>('ValidateAndHide');

  const handleSubmit = (e: any) => {
    setValidationMode('ValidateAndShow');
    e.preventDefault();
    if (errors.length > 0) {
      return;
    }
    
    props.onHandleSubmit(formData);

    setFormData({});
    setValidationMode('ValidateAndHide');
  };

  const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    setFormData(data);
    setFormErrors(errors);
  };

  const handleFormClose = () => {
    setFormData({});
    setValidationMode('ValidateAndHide');
    props.onClose();
  };

  return (
          <Dialog
            open={props.openDialog}
            onClose={handleFormClose}
            TransitionComponent={Transition}
            maxWidth={'sm'}
            fullWidth={true}
          >
        <form noValidate onSubmit={handleSubmit}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleFormClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {props.formTitle}
              </Typography>
              <Button autoFocus color="inherit" type="submit">
                save
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={4} style={{ padding: 50 }}>
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={formData}
            renderers={customrenderers}
            cells={materialCells}
            onChange={handleFormChange}
            validationMode={validationMode}
          />
          </Grid>
        </form>
      </Dialog>
  );
}
export default FormBuilderComponent;