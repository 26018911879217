import { Box, Grid, Typography } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import CustomAsynchTypeHead from 'components/CustomAsynchTypeHead';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'store';
import { RootState } from 'types/CommonReducerType';

const TableCellEditUserDropdown: React.FC<GridRenderEditCellParams> = (props) => {
  const { id, value, field, error } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const apiRef = useGridApiContext();
  const currentUserList = useSelector((state: RootState) => state.common['currentUserList']);

  const handleSearch = (query: string) => {
    setIsLoading(true);

    var users = [] as any
    users = currentUserList.filter((user) => {
      return user?.EmailAddress.toLowerCase().startsWith(query.toLowerCase());
    });
    setOptions(users);

    setIsLoading(false);
  };

  const handleChange = (selectedOption: any) => {
    if (selectedOption?.text) {
      let userValue = {
        AssignedTo: selectedOption['Name'],
        AssignedToLookupId: selectedOption['Id'],
        EmailAddress: selectedOption['EmailAddress'],
        Type: selectedOption['Type']
      };
      apiRef.current.setEditCellValue({ id, field, value: userValue });
    } else {
      apiRef.current.setEditCellValue({ id, field, value: '' });
    }
  };

  return (
    <CustomAsynchTypeHead
      data={{
        text: value['AssignedTo']
      }}
      handleChange={handleChange}
      handleSearch={handleSearch}
      isLoading={isLoading}
      label="Email"
      options={options}
      placeholder="Email"
      required={true}
      style={{
        width: '100%',
        padding: '5px'
      }}
      errors={error ? ['error'] : []}
      renderOption={(props: any, option: any) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <PersonIcon />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body2" color="text.secondary">
                  {option['Name']}
                </Typography>
                <Box>{option.text}</Box>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default TableCellEditUserDropdown;
