import React from 'react';
import './card.scss';

type cardProps = {
    id: string,
    name: string,
    children: React.ReactNode
}

/* Card Function */
function Card(props: cardProps) {
    return (
        <div style={{
            minHeight: '100vh'
        }} data-cy={props.id+'-div'} className="card bg-light">
            <div data-cy={props.id + '-body'} className="card-body">
                <h6 data-cy={props.id + '-title'} className="card-title mt-3 mb-4">{props.name}</h6>
                <>{props.children}</>
            </div>
        </div>
    );
}

export default Card;