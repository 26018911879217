/* Utility methods for calling APIs */

/* Function to call GetAll items API */
function getAll(apiUrl: string) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        }
      })
        .then((response) => response.json())
        .then((response) => {
          //Returning success response.
          resolve(response);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Get item by Id API*/
function getById(apiUrl: string, id: string) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        }
      })
        .then((response) => response.json())
        .then((response) => {
          //Returning success response.
          resolve(response);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Create item API*/
function create(apiUrl: string, reqBody: Object) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        },
        body: JSON.stringify(reqBody)
      })
        .then(async (response) => {
          if (!response.ok) {
            return response.text().then(text => {
              throw new Error(text || "Something went wrong")
            })
          } else if (response.ok && response.status === 200) {
            return response.json();
          } else if (response.ok && response.status === 201) {
            return response.body;
          }
        })
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

function createByFormData(apiUrl: string, reqBody: FormData) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        },
        body: reqBody
      })
        .then((response) => {
          return response.status === 200 ? response.json() : '';
        })
        .then((response) => {
          //Returning success response.
          resolve(response);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Update item API*/
function update(apiUrl: string, reqBody: Object) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        },
        body: JSON.stringify(reqBody)
      })
        .then((response) => {
          if (response.ok && response.status == 200) {
            return response.json();
          } else if (response.ok && (response.status == 201 || response.status == 202)) {
            return response.body;
          }
          throw new Error('Something went wrong');
        })
        .then((responseJson) => {
          //Returning success response.
          resolve(responseJson);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Delete item by Id API*/
function deleteById(apiUrl: string, id: string) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl + id, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        }
      })
        .then((response) => response.json())
        .then((response) => {
          //Returning success response.
          resolve(response);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Delete item by Id API*/
function deleteAll(apiUrl: string, reqBody: Object) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        },
        body: JSON.stringify(reqBody)
      })
        .then((response) => {
          if (response.ok && response.status === 200) {
            return response.json();
          } else if (response.ok && (response.status === 201 || response.status === 202)) {
            return response.body;
          }
          return response
            .text()
            .then((text) => {
              throw new Error(text);
            })
            .catch((e) => {
              throw e;
            });
        })
        .then((response) => {
          //Returning success response.
          resolve(response);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Download file from blob API*/
function downloadBlob(apiUrl: string, reqBody: JSON) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        },
        body: JSON.stringify(reqBody)
      })
        .then((response) => {
          if (response.ok && response.status == 200) {
            resolve(response.blob());
          } else {
            console.log(response);
            throw new Error('Something went wrong');
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/*Function to call Patch item API*/
function patch(apiUrl: string, reqBody: JSON) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('UserAccessToken')
        },
        body: JSON.stringify(reqBody)
      })
        .then((response) => {
          if (response.ok && response.status == 200) {
            return response.json();
          } else if (response.ok && (response.status == 201 || response.status == 202)) {
            return response.body;
          }
          throw new Error('Something went wrong');
        })
        .then((responseJson) => {
          //Returning success response.
          resolve(responseJson);
        })
        .catch((err) => {
          //Returning error response.
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export default { getAll, getById, create, createByFormData, update, deleteById, deleteAll, downloadBlob, patch };
