import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import config from 'config';
import { useContext } from 'react';
import ApplicationContext from 'contexts/ApplicationContext';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { homeUrl, title } = useContext(ApplicationContext);
  return (
    <>
  <ButtonBase disableRipple component={Link} to={config.defaultPath} sx={sx}>
    {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
  </ButtonBase>
  {!isIcon &&
  <ButtonBase disableRipple component={Link} to={!homeUrl ? config.defaultPath : homeUrl} sx={sx}>
       <h5 className='app-pinnacle-text' style={{marginLeft:10, marginTop:7}}>{title}</h5>
  </ButtonBase> }
  </>);
};

export default LogoSection;
