// third-party
import { FormattedMessage } from 'react-intl';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import { Config } from "config/config";
import apiCalls from 'utils/api_calls';
// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
  DashboardOutlined,
  CrownOutlined,
  ThunderboltOutlined,
  CopyOutlined,
  ProjectOutlined,
  FormOutlined,
  HourglassOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
  GroupOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';
import { ConsoleView } from 'react-device-detect';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
  DashboardOutlined,
  CrownOutlined,
  ThunderboltOutlined,
  CopyOutlined,
  ProjectOutlined,
  FormOutlined,
  HourglassOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
  GroupOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

export const NavItems = () => {

  let [options, setOptions] = React.useState<NavItemType>({
    type: 'group',
    children: new Array()
  });

  React.useEffect(() => {
    main();
  }, []
  );

  function main() {
    var compnents = new Array();
    apiCalls.getAll(Config.api.endPoint + 'GetComponents')
      .then((res: any) => {
        var data: any = res;
        data.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
        data.filter((x: any) => x.showInNavbar).map((parent: any, index: any) => {
          var component = {
            id: parent.id + index,
            title: `${parent.displayName} ${parent.trademark ? '™' : ''}`,
            type: 'collapse',
            icon: (props: Partial<CustomIconComponentProps>) => (
              <img alt="edit" src={require(`../assets/images/${parent.displayName}ComponentGroup.png`).default} style={{ width: 25, height: 'auto', borderRadius: 8 }} />
            ),
            breadcrumbs: false,
            children: new Array()
          };
          parent.children.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
          parent.children.filter((x: any) => x.showInNavbar).map((child1: any, childIndex: any) => {
            var isSubParent = child1.children.length > 0 ? true : false;
            var child1Component = {
              id: child1.name + childIndex,
              title: `${child1.name} ${child1.trademark ? '™' : ''}`,
              type: isSubParent ? 'collapse' : 'item',
              url: child1.url,
              external: child1.openInNewTab ? true : false,
              target: child1.openInNewTab ? true : false,
              breadcrumbs: false,
              children: new Array()
            };

            if (isSubParent) {
              child1.children.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
              child1.children.filter((x: any) => x.showInNavbar).map((child2: any, childIndex: any) => {
                var child2Component = {
                  id: child2.name + childIndex,
                  title: `${child2.name} ${child2.trademark ? '™' : ''}`,
                  type: 'item',
                  url: child2.url,
                  external: child2.openInNewTab ? true : false,
                  target: child2.openInNewTab ? true : false,
                  breadcrumbs: false,
                };
                child1Component?.children?.push(child2Component)
              });
            }

            component?.children?.push(child1Component)
          });
          compnents.push(component)
        });
        setOptions({
          type: 'group',
          children: compnents
        });
      });
  }
  return options;
}
export default NavItems;
