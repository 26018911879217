import React from 'react';
import './documents.scss';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import PubSub from 'pubsub-js';
import Card from '../../components/card/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Grid,
    GridColumn,
    GridToolbar,
    GridColumnMenuSort,
    GridColumnMenuFilter,
    GridDataStateChangeEvent,
    GridColumnMenuProps,
} from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { Button, Modal, Form, Row, Col, Toast } from "react-bootstrap";
import { v4 as uuid } from 'uuid';
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useParams, useNavigate } from 'react-router-dom';
import { RootState, useSelector } from 'store';


/* Top Documents Function */
function Documents() {
    var _export = React.useRef<ExcelExport | null>(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const [documents, setDocuments] = React.useState([] as any);
    const [uploadedDocuments, setUploadedDocuments] = React.useState([] as any);
    const [validated, setValidated] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);
    const [currentFileInfo, setCurrentfileInfo] = React.useState({} as any);
    const [connection, setConnection] = React.useState<null | HubConnection>(null);
    const [userConnection, setUserConnection] = React.useState<null | HubConnection>(null);
    const [toastShowCreating, setToastShowCreating] = React.useState(false);
    const [toastShowDownloading, setToastShowDownloading] = React.useState(false);
    const [toastShowSuccess, setToastShowSuccess] = React.useState(false);
    const [toastSccessMsg, setToastMsgSuccess] = React.useState('');
    const [toastShowErr, setToastShowErr] = React.useState(false);
    const [toastErrMsg, setToastErrMsg] = React.useState('');
    const [toastShowDownloadErr, setToastShowDownloadErr] = React.useState(false);
    const [isDuplicateDocument, setIsDuplicateDocument] = React.useState(null as any);
    const [docType, setDocType] = React.useState("");
    const [projectDetails, setProjectDetails] = React.useState({} as any);
    const [showUpdate, setShowUpdate] = React.useState(false);
    const [updatedFileName, setUpdatedFileName] = React.useState("");
    const [updatedFileType, setUpdatedFileType] = React.useState("");
    const [updatedFileId, setUpdatedFileId] = React.useState("");
    const [toastShowUpdating, setToastShowUpdating] = React.useState(false);
    const [toastShowUpdatingSuccess, setToastShowUpdatingSuccess] = React.useState(false);
    const [isClosed, setClosed] = React.useState(false);
    const [groupProjects, setGroupProjects] = React.useState([] as any);
    const [selectedGroupProject, setSelectedGroupProject] = React.useState({} as any);
    const [isLoading, setIsloading] = React.useState(false);
    const urlParams = useParams();
    const navigate = useNavigate();
    const [newlySelectedProj, setNewlySelectedProj] = React.useState("");

    const isNewFileUiEnabled = useSelector((state: RootState) => state.common['isNewFileUiEnabled']);


    var button = 'save';

    type Props = {
        children: React.ReactElement;
        waitBeforeShow?: number;
    };

    const Delayed = ({ children, waitBeforeShow = 2000 }: Props) => {
        const [isShown, setIsShown] = React.useState(false);
        React.useEffect(() => {
            setTimeout(() => {
                setIsShown(true);
            }, waitBeforeShow);
        }, [waitBeforeShow]);
        return isShown ? children : null;
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuSort {...props} />
                <GridColumnMenuFilter {...props} />
            </div>
        );
    };

    const createDataState = (documents: any, dataState: State) => {
        return {
            result: process(documents.slice(0), dataState),
            dataState: dataState,
        };
    };

    let initialState = createDataState([], {
        take: 15,
        skip: 0,
    });

    const [result, setResult] = React.useState<DataResult>(initialState.result);
    const [dataState, setDataState] = React.useState<State>(
        initialState.dataState
    );

    const handleClose = () => setShow(false);
    const handleUpdateDoctypeClose = () => setShowUpdate(false);

    const handleShow = () => {
        setValidated(false); setShow(true)
        setUploadedDocuments([]);
        setIsDuplicateDocument(false);
        setDocType("");
        setSelectedGroupProject({} as any);
        button = "save";
    };

    const setDocumentType = (e: any) => {
        setDocType(e.target.value);
    }

    const setSelectedGroupsProject = (e: any) => {
        if (e.target.value) {
            groupProjects.forEach((option: any) => {
                if (option.id == e.target.value) {
                    setSelectedGroupProject(option);
                }
            });
        }
        else {
            setSelectedGroupProject({} as any);
        }
    }

    const setUpdatedDocumentType = (e: any) => {
        setUpdatedFileType(e.target.value);
    }

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(documents, event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const columnProps = (field: string, title: string) => {
        return {
            field: field,
            title: title,
            headerClassName: isColumnActive(field, dataState) ? "active" : "",
        };
    };

    const isColumnActive = (field: string, dataState: State) => {
        return (
            GridColumnMenuFilter.active(field, dataState.filter) ||
            GridColumnMenuSort.active(field, dataState.sort)
        );
    };

    React.useEffect(() => {
        if (isNewFileUiEnabled) {
            navigate("/projects/files");
        }
    }, [isNewFileUiEnabled])

    React.useEffect(() => {
        main();
        var token = PubSub.subscribe('setDocuments', main);
        return () => { PubSub.unsubscribe(token); };
    }, []
    );

    React.useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(Config.api.endPoint, {
                headers: { "x-ms-hubname": "documentUpload", "x-ms-client-principal-id": "" },
                accessTokenFactory: () => Config.signalR.accessKey
            })
            .withAutomaticReconnect()
            .build();
        setConnection(connection);
    }, []
    );

    React.useEffect(() => {
        if (connection && connection.state == "Disconnected") {
            connection
                .start()
                .then(() => {
                    if (connection) {
                        connection.on("UploadDocumentSuccessMsg", async (message) => {
                            console.log("UploadDocumentSuccessMsg", message)
                            main();
                        });

                        connection.on("UpdateDocumentTypeSuccessMsg", async (message) => {
                            console.log("UpdateDocumentTypeSuccessMsg", message)
                            main();
                        });

                        connection.on("deleteDocumentUserSuccessMessage", (message) => {
                            console.log("deleteDocumentUserSuccessMessage", message)
                            setToastShowSuccess(true);
                            setToastMsgSuccess('Document deleted successfully');
                            setIsloading(false);
                        });

                        connection.on("deleteDocumentAllSuccessMessage", (message) => {
                            console.log("deleteDocumentAllSuccessMessage", message)
                            setToastShowSuccess(true);
                            setToastMsgSuccess('Document deleted successfully');
                            setIsloading(false);
                        });

                        connection.on("deleteDocumentUserErrorMessage", (message) => {
                            console.log("deleteDocumentUserErrorMessage", message)
                            setToastShowSuccess(true);
                            setToastErrMsg("Failed to delete document.");
                            setToastShowErr(true);
                            setIsloading(false);
                            main();
                        });
                    }
                })
                .catch((error) => {
                    setIsloading(false);
                });
        }
    }, [connection]);

    React.useEffect(() => {
        if (userConnection && userConnection.state === "Disconnected") {
            userConnection
                .start()
                .then(() => {
                    if (userConnection) {
                        userConnection.on("UserUploadDocumentSuccessMsg", async (message) => {
                            console.log("UserUploadDocumentSuccessMsg", message)
                            setToastShowCreating(false)
                            setToastShowSuccess(true);
                            setIsloading(false);
                            setToastMsgSuccess('Document uploaded successfully');
                        });
                        userConnection.on("UserUpdateDocumentTypeSuccessMsg", async (message) => {
                            console.log("UserUpdateDocumentTypeSuccessMsg", message)
                            setToastShowUpdating(false)
                            setToastShowUpdatingSuccess(true);
                            setIsloading(false);
                        });
                        userConnection.on("UserUploadDocumentErrorMsg", (message) => {
                            console.log("UserUploadDocumentErrorMsg", message)
                            setToastShowCreating(false)
                            setToastErrMsg("Error occured while uploading the document.");
                            setToastShowErr(true);
                            setIsloading(false);
                        });
                        userConnection.on("UserUploadDocumentDuplicateErrorMsg", (message) => {
                            console.log("UserUploadDocumentDuplicateErrorMsg", message)
                            setToastShowCreating(false)
                            setToastErrMsg("A file with this name is already associated with this project, but is not currently visible on this list. Please change the name of the file or add it to a different folder and upload it again.");
                            setToastShowErr(true);
                            setIsloading(false);
                        });

                        userConnection.on("UserUpdateDocumentTypeErrorMsg", (message) => {
                            console.log("UserUpdateDocumentTypeErrorMsg", message)
                            setToastShowCreating(false)
                            setToastErrMsg("Failed to update document group type.");
                            setToastShowErr(true);
                            setIsloading(false);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsloading(false);
                });
        }
    }, [userConnection]);

    const handleDeleteActionItemClose = () => setShowDelete(false);

    const deleteFileFile = (item: any) => {
        setShowDelete(true)
        setCurrentfileInfo(item);
    }

    const handleSubmitDelete = async (event: any) => {
        setShowDelete(false)
        let reqBody: any = [{
            "id": currentFileInfo?.id,
            "projectId": currentFileInfo?.ProjectUUId,
            "siteId": projectDetails?.projectSiteId
        }]
        setIsloading(true);
        await apiCalls.deleteAll(Config.api.endPoint + "DeleteFiles", reqBody);
        removeCurrentRowFromGrid();
    }

    const removeCurrentRowFromGrid = () => {
        const remainingData = result.data.filter(row => row.id !== currentFileInfo.id);
        const remainingTotal = result.total - 1;
        const remainingDocuments = documents.filter((doc: any) => doc.id !== currentFileInfo.id);
        setResult({
            data: remainingData,
            total: remainingTotal
        })
        setDocuments(remainingDocuments);
    }

    function main() {
        if (localStorage.getItem('selectedTypeId')) {
            setIsloading(true);
            if (localStorage.getItem('selectedType') == 'project') {
                var getProjectDetailsRequestBody: any = {
                    "ProjectId": localStorage.getItem('selectedTypeId'),
                    "GroupId": "",
                    "ItemName": "documents",
                    IsHeaderRequired: true
                }

                apiCalls.create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
                    .then(async res => {
                        var data: any = await res;
                        data = data[0];
                        setProjectDetails(data);
                        const isClosed = data.projectSummary.ProjectStatus === "Closed" ? true : false;
                        setClosed(isClosed);

                        data.documents.forEach((e: any) => {

                            var isUserDownloaded = "No";
                            var userName = localStorage.getItem("UserEmail");

                            if (e.downloads?.length > 0) {
                                if (e.downloads.some((x: any) => x.userId.toLowerCase() == userName?.toLowerCase())) {
                                    isUserDownloaded = "Yes";
                                }
                            }

                            e["filePath"] = (data.projectSiteName != null && data.projectSiteName != '') ? e.relativePath.split(data.projectSiteName + "/")[1] : e.relativePath;
                            e["isDownloaded"] = isUserDownloaded;
                            e["size"] = Number(e.size);
                            e["author"] = getDocAuthor(e.title, e.author, e.platformAuthor, e.timeCreated, e.platformTimeCreated);
                            e["modifiedBy"] = getDocModifiedBy(e.title, e.modifiedBy, e.platformModifiedBy, e.timeLastModified, e.platformTimeLastModified);
                            e["timeCreated"] = getDocCreatedOn(e.title, e.author, e.platformAuthor, e.timeCreated, e.platformTimeCreated);
                            e["timeLastModified"] = getDocModifiedOn(e.title, e.modifiedBy, e.platformModifiedBy, e.timeLastModified, e.platformTimeLastModified);
                            e["ProjectName"] = data.ProjectName;
                            e["ProjectUUId"] = data.ProjectGuid;
                            e["ProjectSiteName"] = data.projectSiteName;
                        });
                        setDocuments(data.documents);
                        let updatedState = createDataState(data.documents, dataState);
                        setResult(updatedState.result);
                        setDataState(updatedState.dataState);
                        setIsloading(false);
                    }).catch(e => {
                        setIsloading(false);
                    });
            }
            else if (localStorage.getItem('selectedType') === 'group') {
                let getProjectDetailsRequestBody: any = {
                    "ProjectId": "",
                    "GroupId": localStorage.getItem('selectedTypeId'),
                    "ItemName": "documents",
                    IsHeaderRequired: true
                }

                apiCalls.create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
                    .then(async res => {
                        var group: any = await res;
                        var documentsArr: any[] = [];
                        var projectsArr: any[] = [];
                        group.forEach((data: any) => {
                            var project = {
                                id: data.id,
                                projectId: data.projectId,
                                name: data.name,
                                projectSiteName: data.projectSiteName
                            };
                            projectsArr.push(project);

                            data.documents = data.documents ? data.documents : [];
                            data.documents.forEach((e: any) => {

                                var isUserDownloaded = "No";
                                var userName = localStorage.getItem("UserEmail");

                                if (e.downloads?.length > 0) {
                                    if (e.downloads.some((x: any) => x.userId.toLowerCase() == userName?.toLowerCase())) {
                                        isUserDownloaded = "Yes";
                                    }
                                }

                                e["filePath"] = (data.projectSiteName != null && data.projectSiteName != '') ? e.relativePath.split(data.projectSiteName + "/")[1] : e.relativePath;
                                e["isDownloaded"] = isUserDownloaded;
                                e["size"] = Number(e.size);
                                e["author"] = getDocAuthor(e.title, e.author, e.platformAuthor, e.timeCreated, e.platformTimeCreated);
                                e["modifiedBy"] = getDocModifiedBy(e.title, e.modifiedBy, e.platformModifiedBy, e.timeLastModified, e.platformTimeLastModified);
                                e["timeCreated"] = getDocCreatedOn(e.title, e.author, e.platformAuthor, e.timeCreated, e.platformTimeCreated);
                                e["timeLastModified"] = getDocModifiedOn(e.title, e.modifiedBy, e.platformModifiedBy, e.timeLastModified, e.platformTimeLastModified);
                                e["ProjectId"] = data.projectId;
                                e["ProjectName"] = data.ProjectName;
                                e["ProjectUUId"] = data.ProjectGuid;
                                e["ProjectSiteName"] = data.projectSiteName;
                            });
                            documentsArr.push(...data.documents);
                        });
                        setGroupProjects(projectsArr);
                        setDocuments(documentsArr);
                        let updatedState = createDataState(documentsArr, dataState);
                        setResult(updatedState.result);
                        setDataState(updatedState.dataState);
                        setIsloading(false);
                    }).catch((err) => {
                        setDocuments([]);
                        setGroupProjects([]);
                        setIsloading(false);
                    });
            }
        }
    }

    function getDocAuthor(title: string, author: string, platformAuthor: string, timeCreatedOn: string, platformTimeCreatedOn: string) {
        var createdBy = null;
        var timeCreated = timeCreatedOn != null ? new Date(timeCreatedOn) : null;
        var platformTimeCreated = platformTimeCreatedOn != null ? new Date(platformTimeCreatedOn) : null;

        if (author != null && platformAuthor == null) {
            createdBy = author;
        }
        else if (author == null && platformAuthor != null) {
            createdBy = platformAuthor;
        }
        else if (author == null && platformAuthor == null) {
            createdBy = null;
        }
        else {
            if (timeCreated != null && platformTimeCreated != null) {
                if (timeCreated > platformTimeCreated) {
                    createdBy = author;
                }
                else {
                    createdBy = platformAuthor;
                }
            }
        }
        return (createdBy);
    }

    function getDocCreatedOn(title: string, author: string, platformAuthor: string, timeCreatedOn: string, platformTimeCreatedOn: string) {
        var createdOn = null;
        var timeCreated = timeCreatedOn ? new Date(timeCreatedOn) : null;
        var platformTimeCreated = platformTimeCreatedOn ? new Date(platformTimeCreatedOn) : null;

        if (author != null && platformAuthor == null) {
            createdOn = timeCreated;
        }
        else if (author == null && platformAuthor != null) {
            createdOn = platformTimeCreated;
        }
        else if (author == null && platformAuthor == null) {
            createdOn = null;
        }
        else {
            if (timeCreated != null && platformTimeCreated != null) {
                if (timeCreated > platformTimeCreated) {
                    createdOn = timeCreated;
                }
                else {
                    createdOn = platformTimeCreated;
                }
            }
        }
        return (createdOn);
    }

    function getDocModifiedBy(title: string, modifiedBy: string, platformModifiedBy: string, timeLastModifiedOn: string, platformTimeLastModifiedOn: string) {
        var lastModifiedBy = null;
        var timeLastModified = timeLastModifiedOn ? new Date(timeLastModifiedOn) : null;
        var platformTimeLastModified = platformTimeLastModifiedOn ? new Date(platformTimeLastModifiedOn) : null;

        if (modifiedBy != null && platformModifiedBy == null) {
            lastModifiedBy = modifiedBy;
        }
        else if (modifiedBy == null && platformModifiedBy != null) {
            lastModifiedBy = platformModifiedBy;
        }
        else if (modifiedBy == null && platformModifiedBy == null) {
            lastModifiedBy = null;
        }
        else {
            if (timeLastModified != null && platformTimeLastModified != null) {
                if (timeLastModified > platformTimeLastModified) {
                    lastModifiedBy = modifiedBy;
                }
                else {
                    lastModifiedBy = platformModifiedBy;
                }
            }
        }
        return (lastModifiedBy);
    }

    function getDocModifiedOn(title: string, modifiedBy: string, platformModifiedBy: string, timeLastModifiedOn: string, platformTimeLastModifiedOn: string) {
        var lastModifiedOn = null;
        var timeLastModified = timeLastModifiedOn ? new Date(timeLastModifiedOn) : null;
        var platformTimeLastModified = platformTimeLastModifiedOn ? new Date(platformTimeLastModifiedOn) : null;

        if (modifiedBy != null && platformModifiedBy == null) {
            lastModifiedOn = timeLastModified;
        }
        else if (modifiedBy == null && platformModifiedBy != null) {
            lastModifiedOn = platformTimeLastModified;
        }
        else if (modifiedBy == null && platformModifiedBy == null) {
            lastModifiedOn = null;
        }
        else {
            if (timeLastModified != null && platformTimeLastModified != null) {
                if (timeLastModified > platformTimeLastModified) {
                    lastModifiedOn = timeLastModified;
                }
                else {
                    lastModifiedOn = platformTimeLastModified;
                }
            }
        }

        return (lastModifiedOn);
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        const loggedInUser = localStorage.getItem("UserName");
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity()) {

            const formData = new FormData(event.target);
            const formDataObj = Object.fromEntries(formData.entries());

            let reqBody = new FormData();

            var uploadedFileName = uploadedDocuments[0].name;

            var docExist = documents.some((x: any) => x.name === uploadedFileName && x.relativePath.includes("Shared Documents"));
            setIsDuplicateDocument(docExist);

            if (!docExist || button === 'replace') {
                setIsloading(true);
                handleClose();
                const lastDot = uploadedFileName.lastIndexOf('.');
                const ext = uploadedFileName.substring(lastDot + 1);

                reqBody.append('file', uploadedDocuments[0]);
                reqBody.append('filename', uploadedDocuments[0].name);
                reqBody.append('documentType', formDataObj.uploadFileType);
                if (localStorage.getItem('selectedType') == 'group' && Object.keys(selectedGroupProject).length !== 0) {
                    reqBody.append('projectId', selectedGroupProject.ProjectGuid);
                    reqBody.append('projectName', selectedGroupProject.projectSiteName);
                    reqBody.append('storageLocation', "Projects/" + selectedGroupProject.ProjectGuid + "/" + uuid() + "." + ext);
                }
                else {
                    reqBody.append('projectId', projectDetails.ProjectGuid);
                    reqBody.append('projectName', projectDetails.projectSiteName);
                    reqBody.append('storageLocation', "Projects/" + projectDetails.ProjectGuid + "/" + uuid() + "." + ext);
                }

                reqBody.append('uploadedBy', loggedInUser != null ? loggedInUser : "");
                reqBody.append('uploadedOn', new Date().toUTCString());

                setToastShowCreating(true);
                const userConnection = new HubConnectionBuilder()
                    .withUrl(Config.api.endPoint, {
                        headers: { "x-ms-hubname": "documentUpload", "x-ms-client-principal-id": (localStorage.getItem('selectedType') == 'group' ? selectedGroupProject.ProjectGuid : projectDetails.ProjectGuid) + "-" + uploadedDocuments[0].name },
                        accessTokenFactory: () => Config.signalR.accessKey
                    })
                    .withAutomaticReconnect()
                    .build();

                setUserConnection(userConnection);

                apiCalls.createByFormData(Config.api.endPoint + 'UploadFileToBlob', reqBody)
                    .then(_ => {
                        console.log("Upload file.");
                    })
                    .catch(err => {
                        console.log("Failed to upload file.", err);
                        setIsloading(false);
                    });
            }
            else {
                setValidated(false);
            }

        }
        event.preventDefault();
    };

    function EditFileType(fileId: any, fileName: any, fileType: any) {
        setUpdatedFileName(fileName);
        setUpdatedFileType(fileType);
        setUpdatedFileId(fileId);
        setShowUpdate(true);
    }

    function handleUpdateDocTypeSubmit() {

        let reqBody = new FormData();
        const loggedInUser = localStorage.getItem("UserName");

        reqBody.append('projectId', projectDetails.ProjectGuid);
        reqBody.append('projectName', projectDetails.projectSiteName);
        reqBody.append('filename', updatedFileName);
        reqBody.append('fileId', updatedFileId);
        reqBody.append('documentType', updatedFileType);
        reqBody.append('updatedBy', loggedInUser != null ? loggedInUser : "");
        reqBody.append('updatedOn', new Date().toUTCString());

        handleUpdateDoctypeClose();
        const userConnection = new HubConnectionBuilder()
            .withUrl(Config.api.endPoint, {
                headers: { "x-ms-hubname": "documentUpload", "x-ms-client-principal-id": projectDetails.ProjectGuid + "-" + updatedFileName },
                accessTokenFactory: () => Config.signalR.accessKey
            })
            .withAutomaticReconnect()
            .build();

        setUserConnection(userConnection);
        setToastShowUpdating(true);
        apiCalls.createByFormData(Config.api.endPoint + 'UpdateDocGroupType', reqBody)
            .then(res => {
                console.log("update group type success.");
            })
            .catch(err => {
                console.log("Failed to update group type.");
            });
    }

    function DownloadFile(docDetails: any) {
        var body = JSON.parse(JSON.stringify({
            "projectId": docDetails.ProjectUUId,
            "fileId": docDetails.id,
            "fileName": docDetails.name,
            "projectName": docDetails.ProjectSiteName
        }));
        setToastShowDownloading(true)
        apiCalls.downloadBlob(Config.api.endPoint + 'FileDownload', body)
            .then(async (res: any) => {
                var blob: any = res;
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    docDetails.name,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link?.parentNode?.removeChild(link);
                main();
            })
            .catch(err => {
                setToastShowDownloadErr(true);
            });
    }

    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    // returns the documents html
    return (
        <Card id="documents-card" name={'Documents'}>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: "60px",
                    backgroundColor: '#3498db'
                }}
                onClose={() => setToastShowDownloading(false)} show={toastShowDownloading} delay={3000} autohide>
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='downloading-info-icon' size='1x' icon={faInfoCircle} /> &nbsp; Downloading the file...</Toast.Body>
            </Toast>
            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: "60px",
                    backgroundColor: '#e74c3c'
                }}
                onClose={() => setToastShowDownloadErr(false)} show={toastShowDownloadErr} delay={3000} autohide>
                {/* <Toast.Header closeButton={false}>
                        <strong className="me-auto">Create User</strong>
                        <small>Just Now</small> &nbsp; &nbsp;
                    </Toast.Header> */}
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='users-create-info-icon' size='1x' icon={faExclamationCircle} /> &nbsp; Error occured while downloading the file.!</Toast.Body>
            </Toast>
            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: "60px",
                    backgroundColor: '#3498db'
                }}
                onClose={() => setToastShowCreating(false)} show={toastShowCreating} delay={3000} autohide >
                {/* <Toast.Header closeButton={false}>
                    <strong className="me-auto small">Create User</strong>
                    <small>Just Now</small> &nbsp; &nbsp;
                </Toast.Header> */}
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='users-create-info-icon' size='1x' icon={faInfoCircle} /> &nbsp; Uploading</Toast.Body>
            </Toast>
            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: "60px",
                    backgroundColor: '#3498db'
                }}
                onClose={() => setToastShowUpdating(false)} show={toastShowUpdating} delay={3000} autohide >
                {/* <Toast.Header closeButton={false}>
                    <strong className="me-auto small">Create User</strong>
                    <small>Just Now</small> &nbsp; &nbsp;
                </Toast.Header> */}
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='users-create-info-icon' size='1x' icon={faInfoCircle} /> &nbsp; Updating</Toast.Body>
            </Toast>

            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: "60px",
                    backgroundColor: '#07bc0c'
                }}
                onClose={() => setToastShowUpdatingSuccess(false)} show={toastShowUpdatingSuccess} delay={3000} autohide>
                {/* <Toast.Header closeButton={false}>
                    <strong className="me-auto">Create User</strong>
                    <small>Just Now</small> &nbsp; &nbsp;
                </Toast.Header> */}
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='users-create-info-icon' size='1x' icon={faCheckCircle} /> &nbsp; Updated successfully</Toast.Body>
            </Toast>

            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: "60px",
                    backgroundColor: '#07bc0c'
                }}
                onClose={() => setToastShowSuccess(false)} show={toastShowSuccess} delay={3000} autohide>
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='users-create-info-icon' size='1x' icon={faCheckCircle} /> &nbsp; {toastSccessMsg}</Toast.Body>
            </Toast>

            <Toast
                style={{
                    position: "fixed",
                    top: '70px',
                    zIndex: 9,
                    right: 0,
                    marginRight: '15px',
                    height: (toastErrMsg.length) > 50 ? "120px" : "60px",
                    backgroundColor: '#e74c3c'
                }}
                onClose={() => setToastShowErr(false)} show={toastShowErr} delay={10000} autohide>
                {/* <Toast.Header closeButton={false}>
                    <strong className="me-auto">Create User</strong>
                    <small>Just Now</small> &nbsp; &nbsp;
                </Toast.Header> */}
                <Toast.Body className='text-white mt-1'><FontAwesomeIcon data-cy='users-create-info-icon' size='1x' icon={faExclamationCircle} /> &nbsp; {toastErrMsg}</Toast.Body>
            </Toast>
            <div data-cy="documents-card-row" className="row">
                <div data-cy="documents-card-col" className="col-sm-12">
                    <ExcelExport fileName={'Documents ' + moment().format('YYYY-MM-DD_HH-mm')} data={documents} ref={_export}>
                        <Grid
                            resizable={true}
                            style={{ height: "500px", fontSize: 12 }}
                            data={result}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            pageable={true}
                            pageSize={10}
                        >
                            <GridToolbar>
                                <button
                                    disabled={documents.length == 0}
                                    title="Export Excel"
                                    className="k-float-right k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={excelExport}
                                >
                                    <FontAwesomeIcon data-cy='documents-download-icon' icon={faDownload} />
                                </button>
                                {/* <button
                                    title="Uploading a Document"
                                    hidden={isClosed || !isUploading}
                                    className="mr-2 k-float-right k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"
                                    style={{ marginRight: 5 }}
                                >
                                    <FontAwesomeIcon data-cy='document-uploading-icon' spin={isUploading} icon={faSpinner} />
                                </button> */}
                                <button
                                    title="Upload Document"
                                    hidden={isClosed} // || isUploading
                                    className="mr-2 k-float-right k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={handleShow}
                                    style={{ marginRight: 5 }}
                                >
                                    <FontAwesomeIcon data-cy='document-upload-icon' icon={faUpload} />
                                </button>
                                <span className="k-float-right" style={{ marginRight: '10px', marginTop: '5px' }} hidden={!isClosed}><strong>Project Closed</strong> </span>
                            </GridToolbar>
                            <GridColumn width="200px" minResizableWidth={30} columnMenu={ColumnMenu} title="File Name"
                                cell={props => (
                                    <td className="k-command-cell">
                                        <a className='text-primary' href="#" onClick={() => DownloadFile(props.dataItem)}> {props.dataItem.name}</a>
                                    </td>
                                )} />

                            <GridColumn width="150px" minResizableWidth={30} columnMenu={ColumnMenu} {...columnProps("title", "Title")} />
                            <GridColumn width="150px" minResizableWidth={30} columnMenu={ColumnMenu} {...columnProps("filePath", "File Path")} />
                            <GridColumn columnMenu={ColumnMenu}{...columnProps("publishtoPlatform", "Document group")} />
                            <GridColumn columnMenu={ColumnMenu}{...columnProps("author", "Uploaded by")} />
                            <GridColumn columnMenu={ColumnMenu}
                                title="Uploaded on"
                                field="timeCreated"
                                format='{0:G}'
                                filter='date' />
                            <GridColumn columnMenu={ColumnMenu}{...columnProps("modifiedBy", "Last modified by")} />
                            <GridColumn columnMenu={ColumnMenu}
                                title="Last modified on"
                                field="timeLastModified"
                                format='{0:G}'
                                filter='date' />
                            <GridColumn columnMenu={ColumnMenu}{...columnProps("isDownloaded", "Downloaded?")} />
                            {localStorage.getItem('selectedType') == 'group' && (
                                <GridColumn columnMenu={ColumnMenu} {...columnProps("ProjectId", "Project Id")} />
                            )}
                            {localStorage.getItem('selectedType') == 'group' && (
                                <GridColumn columnMenu={ColumnMenu} {...columnProps("ProjectName", "Project Name")} />
                            )}
                            <GridColumn width="100px" minResizableWidth={30} filterable={false}
                                title="File size"
                                field="size"
                                cell={props => (
                                    <td>
                                        {props.dataItem.size ? formatBytes(Number(props.dataItem.size), 2) : ""}
                                    </td>
                                )} />
                            {localStorage.getItem('selectedType') !== 'group' && (
                                <GridColumn cell={props => (
                                    <td className="k-command-cell">
                                        <button
                                            disabled={isClosed}
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                                            onClick={() => EditFileType(props.dataItem.id, props.dataItem.name, props.dataItem.publishtoPlatform)}
                                        >
                                            <FontAwesomeIcon data-cy='documents-download-icon' icon={faEdit} />
                                        </button>

                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                                            onClick={() => DownloadFile(props.dataItem)}
                                        >
                                            <FontAwesomeIcon data-cy='documents-download-icon' icon={faDownload} />
                                        </button>
                                        <button
                                            disabled={isClosed}
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                                            onClick={() => deleteFileFile(props.dataItem)}
                                        >
                                            <FontAwesomeIcon data-cy='documents-download-icon' icon={faTrash} />
                                        </button>
                                    </td>
                                )} width="150px" />
                            )}
                        </Grid>
                    </ExcelExport>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} style={{ fontSize: 11 }}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: 15 }}>Upload Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group as={Row} className="mb-3" controlId="file.name">
                            <Form.Label column sm={2}>File:</Form.Label>
                            <Col sm={10}>
                                <Form.Control style={{ fontSize: 11 }}
                                    type="file"
                                    name="uploadFile"
                                    placeholder="file"
                                    autoFocus
                                    required
                                    isInvalid={isDuplicateDocument}
                                    onChange={(e: any) => setUploadedDocuments(e.target.files)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {isDuplicateDocument ? "File with same name is already exist, do you want to replace it?" : "Please select a file to uplaod"}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="file.group">
                            <Form.Label column sm={2}>File group:</Form.Label>
                            <Col sm={10}>
                                <Form.Control className='DocGroups' as="select" style={{ fontSize: 11 }} name="uploadFileType" onChange={(e: any) => setDocumentType(e)}>
                                    <option value="">-- select --</option>
                                    <option value="Presentation">Presentation</option>
                                    <option value="Project Document">Project Document</option>
                                    <option value="Proposal">Proposal</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="file.project" hidden={localStorage.getItem('selectedType') == 'project'}>
                            <Form.Label column sm={2}>Project:</Form.Label>
                            <Col sm={10}>
                                <Form.Control className='DocGroups' as="select" style={{ fontSize: 11 }} name="project" onChange={(e: any) => setSelectedGroupsProject(e)}>
                                    <option value=''>-- select --</option>
                                    {groupProjects.map((option: any, i: number) => (
                                        <option value={option.id}>{option.projectId} - {option.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="white" hidden={isDuplicateDocument} name="save" type="submit" disabled={uploadedDocuments.length == 0 || docType == "" || ((localStorage.getItem('selectedType') == 'group') ? (Object.keys(selectedGroupProject).length === 0 ? true : false) : false)} style={{ color: "white", backgroundColor: "#70c8b6", fontSize: 12 }}>
                            Save
                        </Button>
                        <Delayed>
                            <Button variant="white" hidden={!isDuplicateDocument} name="yes" onClick={() => button = 'replace'} type="submit" style={{ color: "white", backgroundColor: "#70c8b6", fontSize: 12 }} disabled={uploadedDocuments.length == 0 || docType == ""}>
                                Replace
                            </Button>
                        </Delayed>
                        <Button variant="outline-secondary" style={{ fontSize: 12 }} onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showUpdate} onHide={handleUpdateDoctypeClose} style={{ fontSize: 11 }}>
                <Form noValidate validated={validated}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: 15 }}>Update Document Group Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group as={Row} className="mb-3" controlId="file.name">
                            <Form.Label column sm={2}>File Name:</Form.Label>
                            <Col sm={10}>
                                <Form.Control style={{ fontSize: 11 }}
                                    type="text"
                                    name="fileName"
                                    placeholder="File name"
                                    autoFocus
                                    readOnly
                                    value={updatedFileName}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="file.group">
                            <Form.Label column sm={2}>Group:</Form.Label>
                            <Col sm={10}>
                                <Form.Control className='DocGroups' as="select" defaultValue={updatedFileType} style={{ fontSize: 11 }} name="uploadFileType" onChange={(e: any) => setUpdatedDocumentType(e)}>
                                    <option value="Presentation">Presentation</option>
                                    <option value="Project Document">Project Document</option>
                                    <option value="Proposal">Proposal</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="white" hidden={isDuplicateDocument} name="save" onClick={handleUpdateDocTypeSubmit} style={{ color: "white", backgroundColor: "#70c8b6", fontSize: 12 }}>
                            Save
                        </Button>
                        <Button variant="outline-secondary" style={{ fontSize: 12 }} onClick={handleUpdateDoctypeClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDelete} onHide={handleDeleteActionItemClose} style={{ fontSize: 11 }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: 15 }}>File Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the file with title {currentFileInfo?.title}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="white" onClick={handleSubmitDelete} style={{ color: "white", backgroundColor: '#e74c3c', fontSize: 12 }}>
                        Delete
                    </Button>
                    <Button variant="outline-secondary" style={{ fontSize: 12 }} onClick={handleDeleteActionItemClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

        </Card>
    );
}
export default Documents;