import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonReducerStateType, ToastKind } from 'types/CommonReducerType';
import { processFormData, fetchListdata, fetchCurrentUsers, fetchFeatureFlagForFile, fetchFeatureFlagForProjectGroup } from './actions';

export const initialState: CommonReducerStateType = {
  isLoading: true,
  projectDetails: {},
  projectGroups: [],
  listItems: [],
  showToast: false,
  isClosed: false,
  toastKind: ToastKind.SUCCESS,
  toastMsg: '',
  projectSummary: {},
  projectChangeCounter: 0,
  currentProjectName: '',
  currentUserList: [],
  isProjectGroupEnabled: false,
  isNewFileUiEnabled: true,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setProjectDetails: (state, action: PayloadAction<any>) => {
      state['isLoading'] = false;
      state['projectSummary'] = action.payload.projectSummary;
      state['projectDetails'] = action.payload.projectDetails;
      state['projectGroups'] = action.payload.projectGroups;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state['isLoading'] = action.payload;
    },
    setListItems: (state, action: PayloadAction<any>) => {
      state['listItems'] = action.payload;
    },
    setCurrentProjectName: (state, action: PayloadAction<string>) => {
      state['currentProjectName'] = action.payload;
    },
    setToastInfo: (state, action: PayloadAction<any>) => {
      state['toastKind'] = action.payload.toastKind;
      state['toastMsg'] = action.payload.toastMsg;
      state['showToast'] = action.payload.showToast;
    },
    setProjectChangeCounter: (state) => {
      state['projectChangeCounter'] = state['projectChangeCounter'] + 1;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(processFormData.pending, (state, action) => {
        state['isLoading'] = true
      })
      .addCase(processFormData.fulfilled, (state, action) => {
        state['isLoading'] = false
        state['showToast'] = true;
        state['toastKind'] = ToastKind.SUCCESS;
        state['toastMsg'] = action.payload;
        state['projectChangeCounter'] = state['projectChangeCounter'] + 1;
      })
      .addCase(processFormData.rejected, (state, action) => {
        state['isLoading'] = false
        state['showToast'] = true;
        state['toastKind'] = ToastKind.ERROR;
        state['toastMsg'] = typeof action.payload === 'string' ? action.payload : "Error occurred";

      }).addCase(fetchListdata.pending, (state, action) => {
        state['isLoading'] = true
      })
      .addCase(fetchListdata.fulfilled, (state, action) => {
        state['isLoading'] = false
        if (action.payload) {
          state['projectSummary'] = action.payload.projectSummary;
          state['projectDetails'] = action.payload.projectDetails;
          state['projectGroups'] = action.payload.projectGroups;
          state['listItems'] = action.payload.payloadItems;
          state['isClosed'] = action.payload.isClosed;
        }
      })
      .addCase(fetchListdata.rejected, (state, action) => {
        state['isLoading'] = false
        state['showToast'] = true;
        state['toastKind'] = ToastKind.ERROR;
        state['toastMsg'] = typeof action.payload === 'string' ? action.payload : "Error occurred";
      })

      .addCase(fetchCurrentUsers.pending, (state, action) => {
        state['isLoading'] = true
      })
      .addCase(fetchCurrentUsers.fulfilled, (state, action) => {
        state['isLoading'] = false
        state['currentUserList'] = action.payload;
      })
      .addCase(fetchCurrentUsers.rejected, (state, action) => {
        state['isLoading'] = false
        state['showToast'] = true;
        state['toastKind'] = ToastKind.ERROR;
        state['toastMsg'] = typeof action.payload === 'string' ? action.payload : "Error occurred";
      }).addCase(fetchFeatureFlagForProjectGroup.fulfilled, (state, action) => {
        state['isProjectGroupEnabled'] = action.payload;
      }).addCase(fetchFeatureFlagForFile.fulfilled, (state, action) => {
        state['isNewFileUiEnabled'] = action.payload;
      })
  },
});

export const { setProjectDetails, setListItems, setCurrentProjectName, setLoader, setToastInfo, setProjectChangeCounter } = commonSlice.actions;

export default commonSlice.reducer;
