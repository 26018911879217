import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import { Config } from "config/config";
import apiCalls from 'utils/api_calls';
import CommonLayout from 'layout/CommonLayout';
// import AuthGuard from 'utils/route-guard/AuthGuard';

//Pages
import Banner from "containers/banner/banner";
import Main from "containers/main/main";
import ProjectDashboard from "containers/projectDashboard/projectDashboard";
import Wins from "containers/wins/wins";
import ActionItems from "containers/actionitems/actionitems";
import Issues from "containers/issues/issues";
import Risks from "containers/risks/risks";
import Milestones from "containers/milestones/milestones";
import Decisions from "containers/decisions/decisions";
import Documents from "containers/documents/documents";
import Team from "containers/team/TeamList";
import Content from "containers/content/content";
import ProjectGroups from "containers/projectGroups/projectGroups";
import { Container } from '@mui/material';
import ApplicationContext from 'contexts/ApplicationContext';
import { useParams } from 'react-router-dom';
import { Auth0Callback } from 'pages/callback/Auth0Callback';
import ManageProjects from 'containers/manageprojects/manageprojects';
import { RootState, useSelector } from 'store';
// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const MaintenanceError = Loadable(lazy(() => import('../pages/maintenance/404')));
const UnauthorizedError = Loadable(lazy(() => import('../pages/maintenance/401')));
const MaintenanceError500 = Loadable(lazy(() => import('../pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('../pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('../pages/maintenance/coming-soon')));
const FileManager = Loadable(lazy(() => import('containers/fileManager/filemanager')));


export const MainRoutes = () => {
  let proj: any[] = [] ;
  let [projects, setProjects] = React.useState(proj);
  const isProjectGroupEnabled = useSelector((state: RootState) => state.common['isProjectGroupEnabled']);
  React.useEffect(() => {
    main();
  }, []
  );

  function main() {
      apiCalls.getAll(Config.api.endPoint + 'Users')
        .then((res: any) => {
          var data: any = res;
          var userProjects: any[] = [];
          if (data.projects != null && data.projects.length > 0) {
            data.projects.forEach((project: any) => {
              var prj = {
                ID: project.id,
                NAME: project.name
              }
              userProjects.push(prj);
            });
            setProjects(userProjects);
          }
            
        });
    }

  return {
    path: '/',
    children: [
      {
        path: '/',
        element: <ApplicationContext.Provider value={{title: "Platform", homeUrl: "./"}}><CommonLayout layout="simple" /></ApplicationContext.Provider>,
        children: [
          {
            path: '/',
            element: 
            <>
              <Banner />
              <Container maxWidth={false} sx={{marginTop: 0, position: 'relative', backgroundColor: 'transparent'}}>
                <Main  userProjectList={projects}  />
              </Container>
            </>            
          },
          {
            path: '/callback',
            element: <Auth0Callback />
          }
        ]
      },      
      {
        path: '/',
        element: (
          <ApplicationContext.Provider value={{title: "Project", homeUrl: `/project/projectdashboard`}}><MainLayout /></ApplicationContext.Provider>
        ),
        children: [
          {
            path: '/:projName/projectdashboard',
            element: 
            <>
              <ProjectDashboard />
            </>
          },
          
          {
            path: '/:projName/wins',
            element: 
            <>
              <Wins />
            </>
          },
          
          {
            path: '/:projName/actionitems',
            element: 
            <>
              <ActionItems />
            </>
          },
          {
            path: '/:projName/issues',
            element: 
            <>
              <Issues />
            </>
          },
          
          {
            path: '/:projName/risks',
            element: 
            <>
              <Risks />
            </>
          },
          
          {
            path: '/:projName/milestones',
            element: 
            <>
              <Milestones />
            </>
          },
          
          {
            path: '/:projName/decisions',
            element: 
            <>
              <Decisions />
            </>
          },
          
          {
            path: '/:projName/documents',
            element: 
            <>
              <Documents />
            </>
          },
          {
            path: '/:projName/files',
            element: 
            <>
              <FileManager />
            </>
          },
          {
            path: '/:projName/team',
            element: <Team />
          },
          {
            path: '/projectgroups',
            element: 
            <>
              {isProjectGroupEnabled && <ProjectGroups />}
            </>
          },
          {
            path: '/:projectSiteName/content/:location/:title',
            element: <Content />
          },
          {
            path: '/manageprojects',
            element: (<ManageProjects/>)
          },
          {
            path: '404',
            element: <MaintenanceError />
          },
          {
            path: '401',
            element: <UnauthorizedError />
          },
          {
            path: 'projectGraphic',
            element: <MaintenanceComingSoon />
          },
          {
            path: '*',
            element: <MaintenanceError />
          }
        ]
      },
      {
        path: '/maintenance',
        element: <CommonLayout />,
        children: [
          {
            path: '500',
            element: <MaintenanceError500 />
          },
          {
            path: 'under-construction',
            element: <MaintenanceUnderConstruction />
          },
          {
            path: 'coming-soon',
            element: <MaintenanceComingSoon />
          }
        ]
      }
    ]
  }
};

export default MainRoutes;
