import React from 'react';
import './milestones.scss';
import apiCalls from 'utils/api_calls';
import Card from 'components/card/card';
import MUIDataGridFunctions from 'components/MUIGrid/MUIGrid';
import { GridRowModesModel, GridRowParams, MuiEvent, GridEventListener, GridRowModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

import { columns, schema, uischema } from './constants';
import { JsonSchema } from '@jsonforms/core';
import FormBuilderComponent from 'components/formBuilder/formBuilder';
import ConfirmModal from 'components/ConfirmModal';
import TableToolbar from 'components/TableToolbar';
import { RootState, useDispatch, useSelector } from 'store';
import { getValidItemList, groupColumnIds } from 'utils/utils';
import { fetchListdata, processFormData } from 'store/reducers/common/actions';

/* Top Milestones Function */
function Milestones() {
  const [milestonesChecked, setMilestonesChecked] = React.useState([] as any);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const navigate = useNavigate();

  const [formSchema, setFormSchema] = React.useState<JsonSchema>(schema);
  const [formUiSchema, setFormUiSchema] = React.useState<any>(uischema);
  const [gridColumns, setGridColumns] = React.useState(columns);

  const [showAdd, setShowAdd] = React.useState(false);
  const [showDeleteSelected, setShowDeleteSelected] = React.useState(false);

  const handleAddMilestoneClose = () => setShowAdd(false);
  const handleDeleteSelectedMilestonesClose = () => setShowDeleteSelected(false);

  function handleDeleteSelectedMilestoneShow() {
    milestonesChecked.length > 1 ? setShowDeleteSelected(true) : handleSubmitMultiSelectDelete();
  }

  const projectDetails = useSelector((state: RootState) => state.common['projectDetails']);
  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const isClosed = useSelector((state: RootState) => state.common['isClosed']);
  const milestones = getValidItemList(
    useSelector((state: RootState) => state.common['listItems']),
    columns
  );
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const reduxdispatch = useDispatch();

  const handleAddMilestoneShow = () => {
    setShowAdd(true);
  };

  React.useEffect(() => {
    const isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let requredFields = formSchema['required']?.filter((item) => item !== 'project');
    let element = formUiSchema['elements']?.find((elm: any) => elm.scope === '#/properties/project');
    let projectFldIndex = formUiSchema['elements']?.findIndex((elm: any) => elm.scope === '#/properties/project');
    if (element) {
      element['rule']['effect'] = isprojectGroup ? 'SHOW' : 'HIDE';
      formUiSchema[projectFldIndex] = element;
      setFormUiSchema(formUiSchema);
    }

    if (isprojectGroup) {
      requredFields?.push('project');
    }
    formSchema['required'] = requredFields;
    setFormSchema(formSchema);
  }, [projectChangeCounter]);

  // Exclude project group columns
  React.useEffect(() => {
    let isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let modifiedColumns = columns.filter((column) => {
      if (!isprojectGroup) {
        return !groupColumnIds.includes(column.field);
      }
      return true;
    });
    setGridColumns(modifiedColumns);
  }, [projectChangeCounter]);

  React.useEffect(() => {
    main();
  }, [projectChangeCounter]);

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (newRow.id !== 0) {
      handleSaveUpdate(updatedRow);
    }
    return updatedRow;
  };

  function EditToolbar() {
    return (
      <TableToolbar
        onAddClick={handleAddMilestoneShow}
        onDeleteClick={handleDeleteSelectedMilestoneShow}
        isDeleteDisabled={milestonesChecked.length === 0}
        isExportDisabled={milestones.length === 0}
        toolbarName="Milestones"
        isHidden={isClosed}
      />
    );
  }

  const modiFyitemCallback = (item: any) => {
    if(!item?.customValue){
      item['customValue'] = 0;
    }
    return item;
  };

  function main() {
    reduxdispatch(
      fetchListdata({
        ItemName: 'milestones',
        type: 'milestones',
        customId: 'mileStoneId',
        modiFyitemCallback: modiFyitemCallback
      })
    );
  }

  const handleSubmitAdd = (formData: any) => {
    handleAddMilestoneClose();
    const projectId = localStorage.getItem('selectedType') === 'group' ? formData.project : projectDetails.ProjectGuid;
    const payLoad = {
      title: formData.title,
      finishDate: formData.finishDate,
      percentageCompleted: formData.percentComplete,
      description: formData.description,
      customValue: formData['customValue'],
      approvers: formData?.approvers || [],
      projectId
    }
    reduxdispatch(
      processFormData({
        payLoad,
        endpoint: 'Milestones',
        hubname: 'Milestones',
        ProjectGuid: projectId,
        clientId: '-addmilestones-0',
        successEvent: 'createMilestonesUserSuccessMessage',
        errorEvent: 'MilestonesUserErrorMessage',
        apiMethod: apiCalls.create
      })
    );
  };

  const handleSaveUpdate = (formData: any) => {
    const projectId = formData['ProjectGuid'];
    const payLoad = {
      id: formData.id,
      title: formData.title,
      finishDate: formData.finishDate,
      percentageCompleted: formData.percentageCompleted,
      description: formData.description,
      customValue: formData['customValue'],
      approvers: formData?.approvers || [],
      projectId
    }
    reduxdispatch(
      processFormData({
        payLoad,
        endpoint: 'Milestones',
        hubname: 'Milestones',
        ProjectGuid: projectId,
        clientId: '-updatemilestones-0',
        successEvent: 'updateMilestonesUserSuccessMessage',
        errorEvent: 'MilestonesUserErrorMessage',
        apiMethod: apiCalls.update
      })
    );
  };

  const handleSubmitMultiSelectDelete = () => {
    handleDeleteSelectedMilestonesClose();
    let ProjectGuid = '';
    let deleteBody = milestonesChecked.map((ID: String) => {
      let splitId = ID.split('--');
      ProjectGuid = splitId[1];
      return {
        id: splitId[0],
        siteId: splitId[2],
        projectId: splitId[1]
      };
    });
    reduxdispatch(
      processFormData({
        payLoad: deleteBody,
        endpoint: 'Milestones',
        hubname: 'Milestones',
        ProjectGuid,
        clientId: '-deletemilestones-0',
        successEvent: 'deleteMilestonesUserSuccessMessage',
        errorEvent: 'MilestonesUserErrorMessage',
        apiMethod: apiCalls.deleteAll
      })
    );
  };

  // returns the milestones html
  return (
    <Card id="milestones-card" name={'Key Milestones'}>
      <MUIDataGridFunctions.MUIDataGrid
        rows={milestones}
        rowIdColum={'mileStoneId'}
        columns={gridColumns}
        disableSelectionOnClick={true}
        onSelectionModelChange={(itm: any) => setMilestonesChecked(itm)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel: any) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
        componentsProps={{
          toolbar: {}
        }}
        components={{
          Toolbar: EditToolbar
        }}
      />
      <FormBuilderComponent
        formTitle={'Add Milestone'}
        schemaObj={formSchema}
        initialData={{}}
        uiSchema={formUiSchema}
        openDialog={showAdd}
        onClose={handleAddMilestoneClose}
        onHandleSubmit={handleSubmitAdd}
      />
      <ConfirmModal
        handleConfirmation={handleSubmitMultiSelectDelete}
        toggleDeleteForm={showDeleteSelected}
        type={'Milestones'}
        handleCloseModal={handleDeleteSelectedMilestonesClose}
      />
    </Card>
  );
}
export default Milestones;
