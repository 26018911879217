import { useAuth0, User } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Config } from 'config/config';
import apiCalls from 'utils/api_calls';

export const Auth0Callback = () => {
    const { isLoading, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const getAccessToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: Config.auth0.authorizationParams
            });
            
            localStorage.setItem('UserAccessToken', accessToken);
            localStorage.setItem('UserName', user?.name!);
            localStorage.setItem('UserEmail', user?.email!);

            return accessToken;
        } 
        catch (err: any) {
            console.log('Error while getting access token: ' + err.message);
        }
    };

    const createActivityLog = async (user?: User) => {
        await apiCalls.create(
            Config.api.endPoint + 'ActivityLog',
            JSON.parse(
                JSON.stringify({
                    email: user?.email,
                    source: 'Platform',
                    route: 'Login'
                })
            )
        );
    };

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                if (await getAccessToken()) {
                    await createActivityLog(user);
                }

                navigate('/');
            }
        })();
      }, [isLoading, getAccessTokenSilently]);

    return (
        <></>
    );
};