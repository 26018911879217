import {
  GridCellEditStartParams,
  GridEventListener,
  GridRowModel,
  GridRowModesModel,
  GridRowParams,
  GridSelectionModel,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { columns } from '../constants';
import { getValidItemList, groupColumnIds } from 'utils/utils';
import ActionItemsContext from '../context/reducer';
import {
  deleteActionItem,
  setSelectedGridRowIds,
  setSelectedUser,
  setToggleAddForm,
  setToggleDeleteForm
} from '../context/actions';
import MUIDataGridFunctions from 'components/MUIGrid/MUIGrid';
import apiCalls from 'utils/api_calls';
import { useDispatch, useSelector } from 'store';
import { RootState } from 'types/CommonReducerType';
import { fetchListdata, processFormData } from 'store/reducers/common/actions';
import TableToolbar from 'components/TableToolbar';

const ActionitemTable = (): JSX.Element => {
  const { state, dispatch } = React.useContext<any>(ActionItemsContext);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [gridColumns, setGridColumns] = React.useState(columns);

  const isLoading = useSelector((state: RootState) => state.common['isLoading']);
  const projectChangeCounter = useSelector((state: RootState) => state.common['projectChangeCounter']);
  const actionItems = getValidItemList(useSelector((state: RootState) => state.common['listItems']), columns);
  const currentUserList = useSelector((state: RootState) => state.common['currentUserList']);

  const reduxDispatch = useDispatch();

  // Exclude project group columns
  React.useEffect(() => {
    let isprojectGroup = localStorage.getItem('selectedType') === 'group';
    let modifiedColumns = columns.filter((column) => {
      if (!isprojectGroup) {
        return !groupColumnIds.includes(column.field);
      }
      return true;
    });
    setGridColumns(modifiedColumns);
  }, [projectChangeCounter]);

  const { selectedUser } = state;

  const modiFyitemCallback = (item: any) => {
    let percent = Number(item['_x0025__x0020_Complete']) * 100;
    item['percentCompleted'] = percent ? Math.round(percent * 100) / 100 : 0;
    item['user'] = {
      AssignedTo: item['AssignedTo_User_Name'],
      AssignedToLookupId: item['Assigned_x0020_ToLookupId']
    };
    return {...item};
  }

  React.useEffect(() => {
    async function main() {
      await reduxDispatch(
        fetchListdata({
          ItemName: 'actionitems',
          type: 'actionitems',
          modiFyitemCallback: modiFyitemCallback,
          customId: "actionId"
        })
      ).unwrap();
    }
    main();
  }, [projectChangeCounter]);

  const handleUpdateAction = (actionitem: any) => {
    let user = currentUserList.find(u => u.Id === actionitem.Assigned_x0020_ToLookupId);
    setSelectedUser(dispatch, user);
  };

  const handleSubmitUpdate = async (formDataObj: any) => {
    const { user } = formDataObj;
    let payLoad = {
      id: formDataObj.id,
      title: formDataObj.Title,
      dueDate: formDataObj.Due_x0020_Date,
      assignedUserId: user?.AssignedToLookupId? String(user?.AssignedToLookupId) : selectedUser?.Id? String(selectedUser?.Id):'',
      assignedUserName: user?.AssignedTo || selectedUser?.Name || '',
      assignedUserEmail: user?.EmailAddress || selectedUser?.EmailAddress || '',
      percentComplete: (Number(formDataObj.percentCompleted) / 100).toString(),
      Type: user?.Type || selectedUser?.Type || 'Internal',
      siteId: formDataObj['ProjectSiteId'],
      projectId: formDataObj['ProjectGuid']
    }
    reduxDispatch(
      processFormData({
        payLoad,
        endpoint: 'ActionItems',
        hubname: 'actionItemsManagement',
        ProjectGuid: formDataObj['ProjectGuid'],
        clientId: '-updateactions-' + formDataObj.id,
        successEvent: 'updateActionItemsUserSuccessMessage',
        errorEvent: 'updateActionItemsUserErrorMessage',
        apiMethod: apiCalls.update
      })
    )
  };

  const handleCellEditStart = (params: GridCellEditStartParams, _event: MuiEvent<React.SyntheticEvent>) => {
    handleUpdateAction(params.row);
  };

  const handleRowEditStart = (_params: GridRowParams, event: MuiEvent) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow.id !== 0) {
      handleSubmitUpdate(updatedRow);
    }

    return updatedRow;
  };

  const handleSubmitDelete = () => {
    if (state.selectedIds.length === 1) {
      deleteActionItem(reduxDispatch, state.selectedIds);
    } else {
      setToggleDeleteForm(dispatch, true);
    }
  };

  function EditToolbar() {
    return (
      <TableToolbar
        onAddClick={() => setToggleAddForm(dispatch, true)}
        onDeleteClick={handleSubmitDelete}
        isDeleteDisabled={state.selectedIds.length === 0}
        isExportDisabled={actionItems.length === 0}
        toolbarName="action-items"
      />
    );
  }

  return (
    <MUIDataGridFunctions.MUIDataGrid
      onSelectionModelChange={(itm: GridSelectionModel) => setSelectedGridRowIds(dispatch, itm)}
      loading={isLoading}
      disableSelectionOnClick
      columns={gridColumns}
      rows={actionItems}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(newModel: React.SetStateAction<GridRowModesModel>) => setRowModesModel(newModel)}
      onCellEditStart={handleCellEditStart}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      rowIdColum="actionId"
      components={{
        Toolbar: EditToolbar
      }}
      componentsProps={{
        toolbar: {}
      }}
      experimentalFeatures={{ newEditingApi: true }}
    />
  );
};

export default ActionitemTable;
