import { GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import moment from 'moment';
import { useSelector, RootState } from 'store';

interface TableToolbarProps {
  onAddClick: () => void;
  onDeleteClick: () => void;
  isDeleteDisabled: boolean;
  isExportDisabled: boolean;
  toolbarName: string;
  isHidden?: boolean;
}

const TableToolbar: React.FC<TableToolbarProps> = (props) => {
  const isClosed = useSelector((state: RootState) => state.common['isClosed']);

  if (isClosed) {
    return <></>;
  }
  return (
    <GridToolbarContainer className="row">
      <div className="col-sm-9">
        <Button onClick={props.onAddClick} color="primary" startIcon={<AddIcon />}>
          Add
        </Button>
        <Button color="primary" disabled={props.isDeleteDisabled} startIcon={<DeleteIcon />} onClick={props.onDeleteClick}>
          Delete
        </Button>
        <GridToolbarExport
          disabled={props.isExportDisabled}
          excelOptions={{
            fileName: `${props.toolbarName} ` + moment().format('YYYY-MM-DD_HH-mm')
          }}
          csvOptions={{
            fileName: `${props.toolbarName} ` + moment().format('YYYY-MM-DD_HH-mm')
          }}
          printOptions={{
            fileName: `${props.toolbarName} ` + moment().format('YYYY-MM-DD_HH-mm')
          }}
        />
      </div>
      <GridToolbarQuickFilter className="col-sm-3" quickFilterProps={{ debounceMs: 500 }} />
    </GridToolbarContainer>
  );
};

export default TableToolbar;
