import { HubConnection } from "@microsoft/signalr";
import { GridRowId, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid-pro";

export enum ActionItemTypes {
    SET_LIST_RESPONSE = 'SET_LIST_RESPONSE',
    SET_ACTION_ITEMS = 'SET_ACTION_ITEMS',
    SET_SOCKET_CONNECTION = 'SET_SOCKET_CONNECTION',
    SET_ACTION_ITEM_TOAST = 'SET_ACTION_ITEM_TOAST',
    SET_ACTION_ITEM_LOADING_STATUS = 'SET_ACTION_ITEM_LOADING_STATUS',
    SET_ACTION_ITEM_FORM_MODE = 'SET_ACTION_ITEM_FORM_MODE',
    SET_SELECTED_GRID_ROW_IDS = 'SET_SELECTED_GRID_ROW_IDS',
    SET_SHOW_ADD_FORM = 'SET_SHOW_ADD_FORM',
    SET_REFRESH_API_COUNTER = 'SET_REFRESH_API_COUNTER',
    SET_SHOW_UPDATE_FORM = 'SET_SHOW_UPDATE_FORM',
    SET_SHOW_DELETE_FORM = 'SET_SHOW_DELETE_FORM',
    SET_UPDATE_ACTION_ITEM = 'SET_UPDATE_ACTION_ITEM',
    SET_DELETE_ACTION_ITEM = 'SET_DELETE_ACTION_ITEM',
    SET_SELECTED_USER = 'SET_SELECTED_USER',
    SET_TOAST_INFO = 'SET_TOAST_INFO',
    UPDATE_ACTION_ITEM = 'UPDATE_ACTION_ITEM',
}

export const enum FormMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    ADD = 'ADD',
}

export const enum ToastKind {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

export interface ActionItemDataType {
    _id: string;
    username: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    primaryPhone: string;
    role: string;
    groupId: string;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface ActionItemContextStateType {
    isLoading: boolean;
    connection: HubConnection | null;
    data: ActionItemDataType[];
    selectedIds: GridRowId[];
    projectGroups: any[];
    showAdd: boolean;
    showUpdate: boolean;
    showDelete: boolean;
    showToast: boolean;
    toastMsg: string;
    toastKind: ToastKind;
    formMode: FormMode;
    projectSummary: any;
    projectDetails: any;
    refreshApiCounter: number;
    actionitem: any;
    selectedUser: any,
    deleteActionItem: any;
}

export type ActionItemActionType = {
    type: ActionItemTypes;
    payload: any;
};

export type ActionItemContextType = {
    state: ActionItemContextStateType;
    dispatch: (params: ActionItemActionType) => void;
};

export interface EditToolbarProps {
    setWins: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

export interface Item {
    Id: string;
    Name: string;
    EmailAddress: string;
    Type: string
}

export interface ToastInfo {
    toastKind: ToastKind,
    toastMsg: String,
    showToast: Boolean,
}